import { Field, Image, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type GICHeaderProps = {
	fields: {
		logo: ImageField;
		text: Field<string>;
	};
};

const GICHeader = (props: GICHeaderProps): JSX.Element => {
	return (
		<nav className="gic-header " data-module="GicHeader">
			<div className="navigation-container">
				{props.fields.logo && (
					<a className="home-link track-nav" aria-label="OSF Health Care Home Page" href="/gic">
						<svg className="icon icon-osf-logo-long">
							<use href="#osf-logo-long" />
						</svg>
						<Image field={props.fields.logo} className="icon icon-osf-logo" alt="OSF Innovation" />
					</a>
				)}

				<Text
					tag="a"
					className="site-link track-nav"
					href="https://osfhealthcare.org"
					field={props.fields.text}
				/>
				<button className="open-mobile-nav track-nav" aria-label="Open Menu">
					<svg className="icon icon-menu-dark">
						<use href="#menu-dark" />
					</svg>
					<svg className="icon icon-menu">
						<use href="#menu" />
					</svg>
				</button>
			</div>
			<div className="mobile-nav-menu">
				<div className="mobile-nav-menu-container">
					<div className="mobile-nav-header">
						<a className="home-link track-nav" href="/c/gic-mam" aria-label="OSF Health Care">
							<svg className="icon icon-osf-logo-long">
								<use href="#osf-logo-long" />
							</svg>
						</a>
						<button className="close-mobile-nav track-nav" aria-label="Close Menu">
							<svg className="icon icon-menu-close">
								<use href="#menu-close" />
							</svg>
						</button>
					</div>
					<ul className="mobile-nav-list">
						<li>
							<a
								className="track-nav"
								aria-label="OSF Health Care Home"
								href="/c/gic-mam"
								target=""
							>
								<div className="list-block ">
									Overview
									<img
										className="icon list-block-icon"
										src="/assets/images/icons/arrow-right.svg"
										alt=""
									/>
								</div>
							</a>
						</li>

						<li>
							<a
								className="track-nav"
								aria-label="Mammograms"
								href="/c/gic-mam/mammograms"
								target=""
							>
								<div className="list-block ">
									Mammograms
									<img
										className="icon list-block-icon"
										src="/assets/images/icons/arrow-right.svg"
										alt=""
									/>
								</div>
							</a>
						</li>

						<li>
							<a
								className="track-nav"
								aria-label="Satefy Protocols"
								href="/c/gic-mam/safety-protocols"
								target=""
							>
								<div className="list-block ">
									Safety Protocols
									<img
										className="icon list-block-icon"
										src="/assets/images/icons/arrow-right.svg"
										alt=""
									/>
								</div>
							</a>
						</li>

						<li>
							<a
								className="track-nav"
								aria-label="Patient Stories"
								href="/c/gic-mam/patient-stories"
								target=""
							>
								<div className="list-block ">
									Patient Stories
									<img
										className="icon list-block-icon"
										src="/assets/images/icons/arrow-right.svg"
										alt=""
									/>
								</div>
							</a>
						</li>

						<li>
							<a
								className="track-nav"
								aria-label="Make an Appointment"
								href="https://www.osfmychart.org/osfmychart/Authentication/Login?mode=stdfile&amp;option=mammolanding"
								target="_blank"
								rel="noreferrer"
							>
								<div className="list-block ">
									Make an Appointment
									<img
										className="icon list-block-icon"
										src="/assets/images/icons/launch.svg"
										alt=""
									/>
								</div>
							</a>
						</li>
					</ul>
					<a className="mobile-nav-footer track-nav" href="https://www.osfhealthcare.org">
						<Text tag="span" field={props.fields.text} />
						<svg className="icon icon-launch">
							<use href="#launch" />
						</svg>
					</a>
				</div>
			</div>
		</nav>
	);
};

GICHeader.propTypes = {};

export default GICHeader;
