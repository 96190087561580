import { Text, Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { useState } from 'react';
import { ItemValue } from 'components/types/ListItem';

type TabGroupProps = StyleguideComponentProps & {
	fields: {
		heading: Field<string>;
		title: Field<string>;
		content: Field<string>;
		description: Field<string>;
		link: LinkField;
		children: Array<ItemValue>;
	};
};

const TabGroup = (props: TabGroupProps): JSX.Element => {
	if (!props.fields || !props.fields.children) {
		return <div></div>;
	}
	const [activeID, setActiveID] = useState<string | number>(-1);
	const [activeContent, setActiveContent] = useState<Field<string>>(
		props.fields.children[0].fields.content
	);

	const handleActiveTab = (itemId: string) => {
		const activeChild = props.fields.children.filter((item) => item.id === itemId);

		setActiveContent(activeChild[0].fields.content);
		setActiveID(itemId);
		if (activeID === itemId) {
			setActiveID(-1);
		}
	};

	return (
		<div className="overhauled">
			<div className="jss-inner-container outer-vertical-spacing">
				<div>
					<Text tag="h1" field={props.fields.title} />
				</div>

				<Text field={props.fields.description} />

				<div className="tab-group osf-tab-group">
					<div className="nav-tabs">
						{props.fields.children.map((item) => (
							<button
								className={`nav-link bg-secondary ${item.id === activeID ? 'active' : ''}`}
								aria-label="OSF Inspire"
								data-target={item.id}
								key={item.id}
								onClick={() => handleActiveTab(item.id)}
							>
								{item.fields.link.value.text}
							</button>
						))}
					</div>
					{activeID && activeID != -1 && (
						<div className="nav-content">
							<div className="nav-pane">
								<RichText field={activeContent} />
							</div>
						</div>
					)}
					<div>
						<div className="nav-tabs-mobile">
							{props.fields.children.map((item) => (
								<div key={item.id}>
									<button
										className={`nav-link bg-secondary ${item.id === activeID ? 'active' : ''}`}
										aria-label="OSF Inspire"
										data-target={item.id}
										key={item.id}
										onClick={() => handleActiveTab(item.id)}
									>
										{item.fields.link.value.text}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="8.121"
											height="14.122"
											viewBox="0 0 8.121 14.122"
											className={`icon icon-chevron-right ${
												activeID === item.id ? 'icon-up' : 'icon-down'
											}`}
										>
											<g
												id="Icon_Arrow_Right"
												data-name="Icon Arrow Right"
												transform="translate(1.061 1.061)"
											>
												<path
													id="Path_747"
													data-name="Path 747"
													d="M4918.513-354.946l6,6-6,6"
													transform="translate(-4918.513 354.946)"
													fill="none"
												/>
											</g>
										</svg>
									</button>
									{activeID === item.id && (
										<div className="nav-content-mobile">
											<div className="nav-pane-mobile">
												<RichText field={item.fields.content} key={item.id} />
											</div>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabGroup;
