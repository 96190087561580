import React, { createRef, RefObject } from 'react';
import { Field, LinkField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import ContentCarouselCard from './ContentCarouselCard';
import { ItemValue } from 'components/types/ListItem';
import ContentCarouselControls from './ContentCarouselControls';

type ContentCarouselItem = {
	fields: {
		BaseImage: ImageField;
		BaseHeading: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
	};
};

type ContentCarouselProps = {
	fields: {
		BaseHeadline: Field<string>;
		children: Array<ContentCarouselItem>;
	};
};

type ContentCarouselState = {
	cardCount: number;
};

class ContentCarousel extends React.Component<ContentCarouselProps, ContentCarouselState> {
	myRef: RefObject<HTMLDivElement>;
	cardWrapper: RefObject<HTMLDivElement>;

	constructor(props: ContentCarouselProps) {
		super(props);
		this.myRef = this.cardWrapper = createRef<HTMLDivElement>();
	}

	state: ContentCarouselState = {
		cardCount: this.props.fields?.children?.length,
	};

	slideLeft = (): void => {
		if (this.cardWrapper && this.cardWrapper.current) {
			const offsetY = this.cardWrapper.current.offsetWidth + 32,
				currentY = this.cardWrapper.current.scrollLeft;

			this.cardWrapper.current.scrollTo({
				left: currentY === 0 ? offsetY * (this.state.cardCount - 1) : currentY - offsetY,
				behavior: 'smooth',
			});
		}
	};

	slideRight = (): void => {
		if (this.cardWrapper && this.cardWrapper.current) {
			const offsetY = this.cardWrapper.current.offsetWidth + 32,
				currentY = this.cardWrapper.current.scrollLeft;

			this.cardWrapper.current.scrollTo({
				left: currentY === offsetY * (this.state.cardCount - 1) ? 0 : currentY + offsetY,
				behavior: 'smooth',
			});
		}
	};

	render(): JSX.Element {
		if (this.props == undefined || !this.props) {
			return (
				<div className="overhauled">
					<div className="container jss-inner-container"></div>
					<div className="content-carousel">
						<div className="content-container">
							<div className="card-wrapper"></div>
						</div>
					</div>
				</div>
			);
		}
		const { fields } = this.props;

		return (
			<div className="overhauled">
				<div className="content-carousel-wrapper bg-secondary inner-vertical-spacing">
					<div className="jss-inner-container">
						{fields?.BaseHeadline && <h2>{fields.BaseHeadline?.value}</h2>}

						<div className="content-carousel">
							<div className="card-wrapper" ref={this.cardWrapper}>
								{fields &&
									fields.children &&
									fields.children.map((tab, index) => (
										<ContentCarouselCard
											key={index}
											index={index}
											heading={tab.fields.BaseHeading}
											image={tab.fields.BaseImage}
											description={tab.fields.BaseDescription}
											link={tab.fields.BaseLink}
											linkType={tab.fields.BaseLinkType?.name}
										/>
									))}
							</div>
						</div>
					</div>
					<ContentCarouselControls
						slideLeft={this.slideLeft}
						slideRight={this.slideRight}
					></ContentCarouselControls>
				</div>
			</div>
		);
	}
}

export default ContentCarousel;
