import { classNames } from 'assets/utils/helper';
import React from 'react';

type ButtonProps = {
	className?: string;
	disabled?: boolean;
	label: string;
	// eslint-disable-next-line
	handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Button = (props: ButtonProps): JSX.Element => {
	const { label, handleClick, className, ...otherProps } = props;
	return (
		<button
			className={classNames(className || '')}
			onClick={(e) => handleClick && handleClick(e)}
			{...otherProps}
		>
			{label}
		</button>
	);
};

export default Button;
