import React, { createRef, RefObject } from 'react';
import { Field, LinkField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import FiveUpControls from './FiveUpControls';
import FiveUpCard from './FiveUpCard';
import ContentBlock from '../shared/SharedContentBlock';

type FiveUpItem = {
	fields: {
		image: ImageField;
		heading: Field<string>;
		link: LinkField;
		description: Field<string>;
		content: Field<string>;
	};
};

type FiveUpProps = {
	fields: {
		image: ImageField;
		title: Field<string>;
		heading: Field<string>;
		content: Field<string>;
		description: Field<string>;
		link: LinkField;
		children: Array<FiveUpItem>;
	};
};

type FiveUpState = {
	activeCardIndex: number;
	cardCount: number;
	expanded: boolean;
};

class FiveUp extends React.Component<FiveUpProps, FiveUpState> {
	myRef: RefObject<HTMLDivElement>;
	cardWrapper: RefObject<HTMLDivElement>;

	constructor(props: FiveUpProps) {
		super(props);
		this.myRef = this.cardWrapper = createRef<HTMLDivElement>();
	}

	state: FiveUpState = {
		activeCardIndex: -1,
		cardCount: this.props.fields.children?.length,
		expanded: false,
	};

	slideLeft = (): void => {
		if (this.cardWrapper && this.cardWrapper.current) {
			const offsetY = this.cardWrapper.current.offsetWidth + 30,
				currentY = this.cardWrapper.current.scrollLeft;
			console.log('left', offsetY, currentY);

			this.cardWrapper.current.scrollTo({
				left: currentY === 0 ? offsetY * (this.state.cardCount - 1) : currentY - offsetY,
				behavior: 'smooth',
			});
		}
	};

	slideRight = (): void => {
		if (this.cardWrapper && this.cardWrapper.current) {
			const offsetY = this.cardWrapper.current.offsetWidth + 30,
				currentY = this.cardWrapper.current.scrollLeft;

			console.log('right', offsetY, currentY);
			this.cardWrapper.current.scrollTo({
				left: currentY === offsetY * (this.state.cardCount - 1) ? 0 : currentY + offsetY,
				behavior: 'smooth',
			});
		}
	};

	toggleActiveCard = (index: number): void => {
		if (this.state.activeCardIndex === index) {
			this.setState(() => ({
				activeCardIndex: -1,
				expanded: false,
			}));
		} else {
			this.setState(() => ({
				activeCardIndex: index,
				expanded: true,
			}));
		}
	};

	render(): JSX.Element {
		if (!this.props) {
			return (
				<div>
					<div className="container jss-inner-container"></div>
					<div className="five-up">
						<div className="content-container">
							<div className="card-wrapper"></div>
						</div>
					</div>
				</div>
			);
		}
		const { fields } = this.props;
		const { activeCardIndex, expanded } = this.state;
		return (
			<div>
				<div className="jss-inner-container">
					{fields.title && fields.content && (
						<ContentBlock title={fields.title} description={fields.content} />
					)}
				</div>
				<div className="five-up">
					<div className={`content-container ${expanded ? 'expanded' : ''}`}>
						<div className="card-wrapper" ref={this.cardWrapper}>
							{fields.children &&
								fields.children.map((tab, index) => (
									<FiveUpCard
										active={index === activeCardIndex}
										key={index}
										index={index}
										heading={tab.fields.heading}
										image={tab.fields.image}
										description={tab.fields.description}
										link={tab.fields.link}
										toggleActiveCard={this.toggleActiveCard}
									/>
								))}
						</div>
						<FiveUpControls slideLeft={this.slideLeft} slideRight={this.slideRight} />
					</div>
				</div>
			</div>
		);
	}
}

export default FiveUp;
