import React from 'react';
import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type SpecialtiesItem = {
	id: string;
	displayName: string;
};

type ProviderItem = {
	id: string;
	name: string;
	fields: {
		url: string;
		FullName: Field<string>;
		Degrees: Field<string>;
		ExternalId: Field<string>;
		Specialties: Array<SpecialtiesItem>;
		Link: LinkField;
		ProviderImage: ImageField;
		Gender: Field<string>;
	};
};

type FeaturedProvidersProps = {
	fields: {
		Title: Field<string>;
		Providers: Array<ProviderItem>;
		link: LinkField;
		BaseViewMoreLink: LinkField;
	};
};

const FeaturedProviders = (props: FeaturedProvidersProps): JSX.Element => {
	if (props == undefined) {
		return <div>Data source is not defined</div>;
	}

	const { fields } = props;

	if (fields == undefined || fields.Providers == undefined) {
		return <div>Data source is not defined</div>;
	}

	return (
		<div className="overhauled">
			<div className="jss-inner-container outer-vertical-spacing">
				<div className="featured-list providers">
					{fields?.Title?.value && <h2 className="title">{fields?.Title?.value}</h2>}
					<div className="list-inner">
						{fields?.Providers?.map(({ id, fields, name }, index) => {
							return (
								<a
									key={id}
									href={`https://www2.osfhealthcare.org/providers/${String(name)
										?.toLowerCase()
										?.split(' ')
										?.join('-')}-${String(fields?.ExternalId?.value)}`}
									className="featured-item bg-secondary"
								>
									<div className="item-image">
										{fields?.ProviderImage?.value?.src ? (
											<Image alt="image_providers" field={fields?.ProviderImage} />
										) : (
											<img
												alt={`image-${index}`}
												src={`https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-${
													fields.Gender.value === 'Male' ? 'male' : 'female'
												}-provider-transparent`}
											/>
										)}
									</div>
									<div
										className="content-container inner-side-spacing"
										itemScope
										itemType="http://schema.org/Physician"
									>
										<span className="flex">
											{(fields?.FullName?.value || fields?.Degrees?.value) && (
												<Text
													className="item-name"
													itemProp="name"
													tag="h4"
													field={{
														value: `${fields?.FullName.value || ''},		${fields?.Degrees.value || ''}`,
													}}
												/>
											)}
										</span>
										<div className="specialties">
											{fields?.Specialties?.length > 0 &&
												fields?.Specialties.map((specialty: SpecialtiesItem, index: number) => (
													<p
														key={specialty.id}
														className="item-subtitle"
														itemType="https://schema.org/medicalSpecialty"
													>
														{specialty.displayName}
														{index < fields?.Specialties?.length - 1 ? ', ' : ''}
													</p>
												))}
										</div>
									</div>
								</a>
							);
						})}
					</div>
					{fields?.BaseViewMoreLink?.value?.href && (
						<div className="view-more">
							<Link className="btn-primary" field={props.fields?.BaseViewMoreLink} />
							<div className="divider"></div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FeaturedProviders;
