import { classNames } from 'assets/utils/helper';
import Icons from 'components/atoms/Icons';
import React from 'react';

type OptionItem = {
	label: string;
	value: string;
};

type FormSelectProps = {
	label?: string;
	options?: Array<OptionItem>;
	value: string;
	name?: string;
	className: string;
	isRequired?: boolean;
	// eslint-disable-next-line
	handleChange: (e: any) => void;
};

const FormSelect = (props: FormSelectProps): JSX.Element => {
	const { className, name, value, options, handleChange, label, isRequired, ...prop } = props;
	return (
		<>
			<label className="form-label" htmlFor="field-textarea">
				{isRequired ? `${label} *` : label || ''}
			</label>
			<div className={classNames('form-select-wrapper', className)}>
				<select {...prop} {...{ name, value }} onChange={(e) => handleChange(e)}>
					<option hidden>Select an options</option>
					{options?.map((item, index) => (
						<option key={index} value={item?.value}>
							{item?.label}
						</option>
					))}
				</select>
				<span className="icon icon-chevron-select-down">
					<Icons id="chevron-down" />
				</span>
			</div>
		</>
	);
};

export default FormSelect;
