import React from 'react';
import {
	Field,
	Image,
	ImageField,
	Link,
	LinkField,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';

type FiveUpCardProps = {
	active: boolean;
	link: LinkField;
	description: Field<string>;
	heading: Field<string>;
	image: ImageField;
	index: number;
	// eslint-disable-next-line no-unused-vars
	toggleActiveCard: (index: number) => void;
};

const FiveUpCard = ({
	active,
	link,
	description,
	heading,
	image,
	index,
	toggleActiveCard,
}: FiveUpCardProps): JSX.Element => {
	return (
		<div
			className={`five-up-card ${active ? 'active' : ''}`}
			onClick={() => toggleActiveCard(index)}
		>
			<div className="five-up-img">{image && <Image field={image} alt="OSF Innovation" />}</div>
			<div className="five-up-content">
				{heading && <RichText className="five-up-card-heading" tag="h4" field={heading} />}
				<span className="five-up-card-cta">Read more</span>
				{description && <RichText className="five-up-card-description osf-body" field={description} />}
				{link && (
					<Link
						className="five-up-card-link"
						aria-label="Link Learn More"
						data-target=""
						rel="noreferrer"
						field={link}
						onClick={(e) => e.stopPropagation()}
					/>
				)}
			</div>
		</div>
	);
};

export default FiveUpCard;
