import React from 'react';
import {
	Field,
	Image,
	ImageField,
	Link,
	LinkField,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';

type ContentCarouselCardProps = {
	link: LinkField;
	linkType: string;
	description: Field<string>;
	heading: Field<string>;
	image: ImageField;
	index: number;
};

const ContentCarouselCard = ({
	link,
	linkType,
	description,
	heading,
	image,
}: ContentCarouselCardProps): JSX.Element => {
	return (
		<div className="content-carousel-card">
			<div className="content-carousel-img">
				{image && <Image field={image} alt="OSF Healthcare" />}
			</div>
			<div className="content-carousel-content">
				{heading?.value && (
					<h3 className="content-carousel-card-heading osf-inner-h3">{heading?.value}</h3>
				)}
				{description?.value && (
					<RichText
						className="content-carousel-card-description osf-body"
						field={description}
						tag=""
					/>
				)}
				{link?.value?.href && (
					<Link
						className={linkType ? `btn-${linkType.toLowerCase()}` : ''}
						aria-label="Link Learn More"
						data-target=""
						rel="noreferrer"
						field={link}
					/>
				)}
			</div>
		</div>
	);
};

export default ContentCarouselCard;
