import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import ImageSelection from 'components/molecules/ImageSelection';
import React, { useState } from 'react';
import useWindowSize from 'src/hooks/useWindowSize';

type ImageItem = {
	id: string;
	fields: {
		image: ImageField;
	};
};

type ImageSelectionProps = {
	images: Array<ImageItem>;
};

const EcardImageSelection = ({ images }: ImageSelectionProps): JSX.Element => {
	const [page, setPage] = useState(1);
	const resize = useWindowSize();

	const handlePagination = (key: string) => setPage(key === 'next' ? page + 1 : page - 1);

	return (
		<div className="image-selection-container">
			<div className="form-input-wrapper">
				<label className="form-label" htmlFor="field-textarea">
					Select an image:*
				</label>
				<ImageSelection
					{...{ page, handlePagination, images }}
					type={Number(resize.width) < 785 ? '2*2' : '3*3'}
				/>
			</div>
		</div>
	);
};

export default EcardImageSelection;
