import { classNames } from 'assets/utils/helper';
import Icons from 'components/atoms/Icons';
import React, { useEffect, useState } from 'react';

type BreadcrumbFields = {
	id: string;
	link: {
		linktype: string;
		target: string;
		text: string;
		url: string;
	};
	svg: string;
};

type Items = {
	id: string;
	fields: {
		'Page Title': string;
		breadcrumbtitle: {
			value: string;
		};
	};
	url: string;
};

type WindowSize = {
	width: number;
	height: number;
};

const getWindowDimensions = (): WindowSize => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

type BreadcrumbPrintShareProps = {
	fields: {
		fields: Array<BreadcrumbFields>;
		items: Array<Items>;
	};
};

const BreadcrumbPrintShare = (props: BreadcrumbPrintShareProps): JSX.Element => {
	const { fields } = props;
	const [windowSize, setWindowSize] = useState<WindowSize>({ width: 0, height: 0 });

	const lastItem = fields?.items.length - 1;
	const lastLink = props.fields?.items[lastItem];
	const breadcrumbStack = props.fields?.items.slice(1);
	const backtohome = props.fields?.items.slice(0, 1);

	const handleClick = (id: string) => {
		switch (id) {
			case 'icon-print':
				return window.print();
			default:
				return null;
		}
	};

	useEffect(() => {
		setWindowSize(getWindowDimensions());
	}, []);

	return (
		<div className="breadcrumb">
			<div className="breadcrumb-inner">
				<ul>
					{backtohome?.map(({ ...data }, index) => (
						<li key={index}>
							<a href={data.url || '/'} className="circle previous">
								<span aria-hidden="true" className="icon icon-arrow-go">
									<Icons isDefault id="icon-arrow-go" />
								</span>
							</a>
						</li>
					))}
					{breadcrumbStack?.length > 2 && windowSize.width < 600 ? (
						<>
							<li>/ ...</li>
							<li>
								{lastLink.id != breadcrumbStack[breadcrumbStack.length - 2].id ? (
									<a href={breadcrumbStack[breadcrumbStack.length - 2].url}>
										{breadcrumbStack[breadcrumbStack.length - 2].fields.breadcrumbtitle.value}
									</a>
								) : (
									breadcrumbStack[breadcrumbStack.length - 2].fields.breadcrumbtitle.value
								)}
							</li>
						</>
					) : (
						<>
							{breadcrumbStack?.length > 0 && ' /  '}
							{breadcrumbStack?.map((link, index) => {
								return (
									<li key={index}>
										{lastLink.id != link.id ? (
											<a href={link.url}>{link.fields.breadcrumbtitle.value}</a>
										) : (
											link.fields.breadcrumbtitle.value
										)}
									</li>
								);
							})}
						</>
					)}
				</ul>
				<div className="breadcrumb-actions">
					{fields?.fields?.map(({ ...data }, index) => (
						<button key={index} onClick={() => handleClick(data.svg)}>
							<span className={classNames('icon', data.svg)}>
								<Icons id={data.svg} />
							</span>
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default BreadcrumbPrintShare;
