import React, { useEffect, useState } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-nextjs';
import AlertProvider from './AlertProvider';
import { AlertFilterGroup } from 'components/types/AlertTypes';

const SystemAlertBanner = (props: AlertFilterGroup): JSX.Element => {
	const [open, setOpen] = useState({});

	const handleAlerts = (alertData: any) => {
		const alertObj: any = {};
		if (!alertData?.length) return {};
		alertData?.forEach((val: any) => {
			alertObj[val?.id] = !!val?.id;
		});
		return alertObj;
	};

	useEffect((): void => {
		const element = document.querySelector('.subnav-container');
		if (element) {
			element.classList.add('top-banner-layout');
		}
	}, []);

	useEffect((): void => {
		if (typeof window !== 'undefined') {
			const alertClose = localStorage.getItem('alertClose');
			setOpen(alertClose ? JSON.parse(alertClose) : handleAlerts(props?.fields?.alerts));
		}
	}, []);

	return (
		<>
			{props?.fields?.alerts?.length ? (
				<div className="alert-container">
					{[...props?.fields?.alerts]?.splice(0, 3)?.map(({ id, ...data }) => (
						<AlertProvider key={id} {...{ id, open, setOpen }} {...data} />
					))}
					{props?.fields?.alerts?.length > 3 && props?.fields?.ViewAll?.value?.href && (
						<div className="view-more-alerts">
							<Link className="alert-link" field={props?.fields?.ViewAll} />
						</div>
					)}
				</div>
			) : (
				<div />
			)}
		</>
	);
};

export default SystemAlertBanner;
