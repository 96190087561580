import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { classNames } from 'assets/utils/helper';
import Icons from '../atoms/Icons';
import ExternalLinkIcon from '../../assets/images/icons/external-link-icon.svg';
import ArrowCollapse from '../../assets/images/icons/arrow-collapse.svg';
import React, { useState } from 'react';

type DescriptionItem = {
	id: string;
	fields: {
		Title: Field<string>;
		link: LinkField;
	};
};

type SocialLinkItem = {
	id: string;
	fields: {
		BaseLink: {
			value: {
				href: string;
			};
		};
		BaseSvg: {
			value: string;
		};
	};
};

type LinkItem = {
	id: string;
	fields: {
		Title: Field<string>;
		items: Array<DescriptionItem>;
	};
};

type R2FooterProps = {
	fields: {
		Logo: ImageField;
		DescriptionLinks: Array<DescriptionItem>;
		FooterDescription: Field<string>;
		SocialLinks: Array<SocialLinkItem>;
		MissionStatement: Field<string>;
		items: Array<LinkItem>;
	};
};

const isExternalLink = (url: string): boolean => {
	return /^(https?:)?\/\/|^www\./i.test(url);
};

const R2Footer = (props: R2FooterProps): JSX.Element => {
	const { fields } = props;
	const [collapsedItems, setCollapsedItems] = useState<{ [key: string]: boolean }>({});

	const toggleCollapse = (id: string) => {
		setCollapsedItems((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	return (
		<div className="overhauled">
			<div className="r2-footer">
				<div className="footer-inner">
					<>
						<div className="footer-container">
							<div className="footer-logo-container">
								<div className="osf-logo">
									<a href="#" className="logo-link">
										<Image alt="osf_logo" field={fields?.Logo || ''} />
									</a>
								</div>

								<div className="mission-text">
									<em>{fields?.MissionStatement?.value}</em>
								</div>
							</div>
							<div className="social-links">
								{fields?.SocialLinks?.map(({ id, fields }) => {
									return (
										<a
											key={id}
											href={fields?.BaseLink?.value?.href || '#'}
											rel="noreferrer"
											target="_blank"
											className="social-link"
										>
											<span className={classNames('icon', `icon-${fields?.BaseSvg?.value}` || '')}>
												<Icons id={`${fields?.BaseSvg?.value}-round` || ''} />
											</span>
										</a>
									);
								})}
							</div>
							<div className="link-blocks-container">
								{fields?.items?.map(({ id, fields }) => {
									const isCollapsed = collapsedItems[id];

									return (
										<div key={id} className="footer-link-block">
											<div className="footer-link-header">
												<Text
													className="footer-link-title osf-nav-section-titles"
													tag="h5"
													field={fields?.Title || ''}
												/>
												<ArrowCollapse
													className={`arrow-collapse ${isCollapsed ? 'rotated' : ''}`}
													onClick={() => toggleCollapse(id)}
												/>
											</div>
											<ul className={`footer-link-list ${collapsedItems[id] ? 'expanded' : ''}`}>
												{fields?.items?.map(({ id, fields }) => (
													<li key={id}>
														{fields?.link.value?.href && (
															<Link
																className="osf-nav-body-copy footer-link"
																aria-label="Link Learn More"
																rel="noreferrer"
																field={fields?.link}
																onClick={(e) => e?.stopPropagation()}
															>
																<span className="link-content">
																	{fields?.link.value.text}
																	{isExternalLink(fields.link.value.href) && (
																		<span className="externalLink-icon">
																			<ExternalLinkIcon />
																		</span>
																	)}
																</span>
															</Link>
														)}
													</li>
												))}
											</ul>
										</div>
									);
								})}
							</div>
						</div>

						<div className="footer-legal">
							<Text className="copyright-text" field={fields?.FooterDescription || ''} />
							<div className="legal-links">
								{fields?.DescriptionLinks?.map(({ id, fields }) => (
									<div className="legal-link" key={id}>
										{fields?.link?.value?.href && (
											<Link
												aria-label="Link Learn More"
												rel="noreferrer"
												field={fields?.link}
												onClick={(e) => e?.stopPropagation()}
											/>
										)}
									</div>
								))}
							</div>
						</div>
					</>
				</div>
			</div>
		</div>
	);
};

export default R2Footer;
