import { classNames } from 'assets/utils/helper';
import React from 'react';
import Icons from 'components/atoms/Icons';

type ActionButtonProps = {
	label?: string;
	paragraph?: string;
	phone?: string;
	link?: string;
	isLinkExternal?: string;
	additionalClasses?: string;
	capacity?: string;
	iconClassName?: string;
	icon?: string;
	rotateIcon?: number;
};

const ActionButton = (props: ActionButtonProps): JSX.Element => (
	<a
		href={props?.phone ? `tel:${props?.phone}` : props?.link || '#'}
		target={props?.isLinkExternal && '_blank'}
		rel={props?.isLinkExternal && 'noreferrer'}
		className={`action-btn`}
	>
		<div className="action-btn-content">
			<span
				className={classNames(
					'icon section-icon',
					props?.icon ? `icon-${props.icon}` : '',
					props?.rotateIcon ? `rotate-svg-${props.rotateIcon}` : ''
				)}
			>
				<Icons id={props?.icon || ''} />
			</span>
			<div className="action-btn-text">
				<p className="action-text osf-intro-copy">{props?.label || ''}</p>
			</div>
		</div>
	</a>
);

export default ActionButton;
