import React, { useState } from 'react';
import { Image, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';

type MediaItem = {
	id: string;
	fields: {
		image: ImageField;
	};
};

type MediaCarouselProps = {
	fields: {
		media: Array<MediaItem>;
	};
};

const MediaCarousel = (props: MediaCarouselProps): JSX.Element => {
	const { fields } = props;
	const [activeSlide, setActiveSlide] = useState(0);

	const handleNext = () => {
		const maxSlide = fields?.media?.length - 1;
		setActiveSlide(activeSlide === maxSlide ? 0 : activeSlide + 1);
	};

	const handlePrevious = () => {
		const maxSlide = fields?.media?.length - 1;
		setActiveSlide(activeSlide === 0 ? maxSlide : activeSlide - 1);
	};

	return (
		<div className="media-carousel">
			<div className="image-container">
				{fields?.media?.map(({ id, ...data }, index) => (
					<div
						key={id}
						className="img-wrapper"
						style={{
							transform: activeSlide
								? `translateX(${100 * (index - activeSlide)}%)`
								: `translateX(${index * 100}%)`,
						}}
					>
						<Image field={{ ...data?.fields?.image }} />
					</div>
				))}
			</div>
			<div className="control disabled">
				<div className="control-prev" onClick={handlePrevious}>
					<Icons id="chevron-next" />
				</div>
				<div className="control-next" onClick={handleNext}>
					<Icons id="chevron-next" />
				</div>
			</div>
		</div>
	);
};

export default MediaCarousel;
