import React from 'react';
import { Image, ImageField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type ArticleSummaryProps = {
	summary: string;
	image: ImageField;
	imageRight: boolean;
	date: string;
	location: string;
	heading: string;
	link: LinkField;
	linkType?: string;
	imgAlt?: string;
};

const ArticleSummary = (props: ArticleSummaryProps): JSX.Element => {
	const { summary, image, imageRight, date, location, heading, link, linkType, imgAlt } = props;

	return (
		<div className={`article-summary ${imageRight == true ? 'img-right' : ''}`}>
			{image && (
				<div className="summary-img">
					<Image field={image} alt={imgAlt} />
				</div>
			)}
			<div className="summary-content">
				<div className="summary-inner">
					<div className="date-and-location osf-label">
						{date && <Text className="summary-date" tag="span" field={{ value: date }} />}
						{location && (
							<Text className="summary-location" tag="span" field={{ value: location }} />
						)}
					</div>

					<Text className="summary-title osf-inner-h3" tag="h3" field={{ value: heading }} />

					{summary && <Text tag="p" className="summary-description" field={{ value: summary }} />}
				</div>
				{link && link?.value?.text && (
					<a
						className={
							linkType == 'Primary'
								? 'btn-primary'
								: linkType == 'Secondary'
								? 'btn-secondary'
								: 'btn-tertiary'
						}
						href={link?.value?.href}
					>
						{link?.value?.text}
					</a>
				)}
			</div>
		</div>
	);
};

export default ArticleSummary;
