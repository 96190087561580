import { Field, LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type MammogramCardProps = {
	fields: {
		link: LinkField;
		title: Field<string>;
		text: Field<string>;
		footerText: Field<string>;
	};
};

const MammogramCard = (props: MammogramCardProps): JSX.Element => (
	<a
		className="content-card mammo-card track-cta"
		href={props.fields?.link?.value?.href}
		target="_blank"
		rel="noreferrer"
	>
		<Text tag="h1" className="card-title" field={props.fields.title} />
		<RichText tag="div" className="card-text" field={props.fields.text} />
		<div className="card-footer">
			<Text tag="p" className="link-text" field={props.fields.footerText} />

			<svg
				className="icon icon-launch"
				id="Icon_Launch"
				data-name="Icon Launch"
				xmlns="http://www.w3.org/2000/svg"
				width="17"
				height="17"
				viewBox="0 0 17 17"
			>
				<defs>
					<clipPath id="clip-path">
						<rect
							id="Rectangle_2398"
							data-name="Rectangle 2398"
							width="17"
							height="17"
							fill="none"
							stroke="#333230"
							strokeWidth="1"
						/>
					</clipPath>
				</defs>
				<g id="Group_11928" data-name="Group 11928">
					<g id="Group_11926" data-name="Group 11926" clipPath="url(#clip-path)">
						<line
							id="Line_3"
							data-name="Line 3"
							y1="15.725"
							x2="15.725"
							transform="translate(0.638 0.638)"
							fill="none"
							stroke="#333230"
						/>
						<path
							id="Path_12313"
							data-name="Path 12313"
							d="M.75.75H16.475V16.475"
							transform="translate(-0.112 -0.112)"
							fill="none"
							stroke="#333230"
						/>
					</g>
				</g>
			</svg>
		</div>
	</a>
);

export default MammogramCard;
