import React, { useState } from 'react';
import ImageGrid from 'components/atoms/ImageGrid';
import { classNames, paginate } from 'assets/utils/helper';
import Icons from 'components/atoms/Icons';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

type ImageItem = {
	id: string;
	fields: {
		image: ImageField;
	};
};

type ImageSelectionProps = {
	type: string;
	page: number;
	// eslint-disable-next-line
	handlePagination: (key: string) => void;
	images: Array<ImageItem>;
};

const ImageSelection = ({
	type,
	page,
	handlePagination,
	images,
}: ImageSelectionProps): JSX.Element => {
	const [selected, setSelected] = useState('');

	const handleSelected = (id: string) => setSelected(id);

	switch (type) {
		case '3*3':
			return (
				<div className="image-selector">
					<div className="pagination-left-icon">
						{page !== 1 ? (
							<span onClick={() => handlePagination('previous')}>
								<Icons id="chevron-left-icon" />
							</span>
						) : null}
					</div>
					<div className="image-selections-large">
						{paginate(images, 9, page)?.map((data: any, index: number) => (
							<span
								className={classNames(data.id === selected ? 'active' : '')}
								onClick={() => handleSelected(data.id)}
								key={index}
							>
								<ImageGrid {...data} />
							</span>
						))}
					</div>
					<div className="pagination-right-icon">
						{Math.ceil(images?.length / 9) - page ? (
							<span onClick={() => handlePagination('next')}>
								<Icons id="chevron-right-icon" />
							</span>
						) : null}
					</div>
				</div>
			);
		case '2*2':
			return (
				<div className="image-selector">
					<div className="pagination-left-icon">
						{page !== 1 ? (
							<span onClick={() => handlePagination('previous')}>
								<Icons id="chevron-left-icon" />
							</span>
						) : null}
					</div>
					<div className="image-selections-medium">
						{paginate(images, 4, page)?.map((data: any, index: number) => (
							<span
								className={classNames(data.id === selected ? 'active' : '')}
								onClick={() => handleSelected(data.id)}
								key={index}
							>
								<ImageGrid type="medium" {...data} />
							</span>
						))}
					</div>
					<div className="pagination-right-icon">
						{Math.ceil(images?.length / 4) - page ? (
							<span onClick={() => handlePagination('next')}>
								<Icons id="chevron-right-icon" />
							</span>
						) : null}
					</div>
				</div>
			);
		default:
			return <></>;
	}
};

export default ImageSelection;
