import React from 'react';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import Modal from 'assets/shared/Modal';

type ButtonModalProps = {
	fields: {
		BaseHeadline: Field<string>;
		BaseHeading: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
		BaseLinkType: Field<string>;
		fullScreen: Field<boolean>;
	};
};

const ButtonModal = (props: ButtonModalProps): JSX.Element => {
	const { fields } = props;

	if (!fields) {
		return <p>No datasource set</p>;
	}

	return (
		<div>
			<div id="modal-root"></div>
			<Modal
				customClass={fields?.fullScreen?.value ? 'full-page' : ''}
				openBtnLabel="Open modal"
				openBtnIcon="play-filled"
				portalId="modal-root"
				{...fields}
			/>
		</div>
	);
};

export default ButtonModal;
