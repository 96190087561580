import constants from './constant';

export default (): string => {
	const width = window.innerWidth;

	if (width < constants.breakpoints.sm) {
		return 'xs';
	} else if (width < constants.breakpoints.md) {
		return 'sm';
	} else if (width < constants.breakpoints.lg) {
		return 'md';
	} else if (width < constants.breakpoints.xl) {
		return 'lg';
	} else if (width < constants.breakpoints.xxl) {
		return 'xl';
	} else {
		return 'xxl';
	}
};
