import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../assets/shared/Modal';
import { Field, LinkField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Link, RichText, Image } from '@sitecore-jss/sitecore-jss-react';

type InfoMessageProps = {
	fields: {
		MessageDesc: Field<string>;
		MessageSvg: ImageField;
		PrimaryLink: LinkField;
		SecondaryButton: LinkField;
	};
};

const InfoMessage = (props: InfoMessageProps): JSX.Element => {
	const { fields } = props;
	return (
		<>
			<Modal
				customClass="innovation-modal in-page-message"
				hideDefaultCloseBtn={false}
				openBtnLabel="Open Info Message"
				modalOnly={false}
				openOnLoad={false}
				portalId="message-modal-root"
				shouldCloseOnOverlayClick={true}
			>
				<div className="icon-container">
					<span className="icon icon-info-circle">
						<Image field={fields?.MessageSvg} />
					</span>
				</div>
				<RichText className="in-page-message-description" field={fields?.MessageDesc || ''} />
				<div className="action-btns-container">
					<Link
						className="action-btn"
						rel="noreferrer"
						field={fields?.PrimaryLink}
						onClick={(e: { stopPropagation: () => void }) => e?.stopPropagation()}
					/>

					<Link
						className="action-btn action-btn-secondary custom-modal-close-btn"
						rel="noreferrer"
						field={fields?.SecondaryButton}
						onClick={(e: { stopPropagation: () => void }) => e?.stopPropagation()}
					/>
				</div>
			</Modal>
			<div id="message-modal-root"></div>
		</>
	);
};

InfoMessage.propTypes = {
	hasCloseButton: PropTypes.bool,
};

export default InfoMessage;
