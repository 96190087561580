import React from 'react';
import BlogSubscribe from 'components/organisms/BlogSubscribe';

type SubScribeProps = {
	// eslint-disable-next-line
	fields: any;
};

const SubScribe = (props: SubScribeProps): JSX.Element => {
	return (
		<div>
			<BlogSubscribe fields={{ ...props?.fields }} />
		</div>
	);
};

export default SubScribe;
