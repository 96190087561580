import { classNames } from 'assets/utils/helper';
import React from 'react';

type TextInputProps = {
	inputClass?: string;
	disabled?: boolean;
	field: any;
};

const CustomInput = ({ inputClass, disabled, field, ...props }: TextInputProps): JSX.Element => {
	const { model } = field;

	return (
		<div className={classNames('form-input-wrapper', inputClass || '')} {...props}>
			<label className="form-label" htmlFor="field-textarea">
				{model?.title || ''}
			</label>
			<div className="form-text-field form-text-input">
				<input
					className="input-field"
					id="field-text"
					type="text"
					{...{ disabled, placeholder: model?.placeholderText || '', required: true }}
				></input>
			</div>
		</div>
	);
};

export default CustomInput;
