import React, { useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import GetViewportSize from '../../assets/utils/getViewportSize';
import Icons from 'components/atoms/Icons';

type CategoryItem = {
	title: string;
	filterCategory: string;
};

type CategoriesItem = {
	name: string;
};

type ResultItem = {
	title: string;
	url: string;
	urlDescription: string;
	urlTarget: string;
	sortKey: string;
	categories: Array<CategoriesItem>;
};

type AllServicesProps = {
	fields: {
		pageTitle: string;
		browseTitle: string;
		viewTitle: string;
		filterHeader: string;
		backToPageTitle: string;
		filterTitle: string;
		searchData: {
			name: string;
			svgIcon: string;
			placeholder: string;
		};
		categoryFilters: Array<CategoryItem>;
		allResults: Array<ResultItem>;
	};
};

const AllServices = ({ fields }: AllServicesProps): JSX.Element => {
	const {
		categoryFilters,
		allResults,
		filterTitle,
		pageTitle,
		browseTitle,
		viewTitle,
		filterHeader,
		backToPageTitle,
		searchData,
	} = fields;
	const ref = useRef<HTMLDivElement>(null);
	const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
	const [sortedData, setSortedData] = useState<any[]>([]);
	const [results, setResults] = useState<any[]>([]);
	const [searchText, setSearchText] = useState('');
	const [activeCategoryFilters, setActiveCategoryFilters] = useState<any[]>([]);
	const [activeStartingLetters, setActiveStartingLetters] = useState<any[]>([]);

	useEffect(() => {
		window.addEventListener('resize', resize);
		sortData(
			allResults?.map((data) => {
				return {
					...data,
					categories: data?.categories?.map((val) => {
						return val?.name;
					}),
				};
			}),
			'title'
		);
	}, []);

	useEffect(() => {
		sortData(
			allResults?.map((data) => {
				return {
					...data,
					categories: data?.categories?.map((val) => {
						return val?.name;
					}),
				};
			}),
			'title'
		);
	}, [allResults]);

	useEffect(() => {
		setResults(sortedData);
	}, [sortedData]);

	useEffect(() => {
		filterResults();
	}, [searchText, activeCategoryFilters]);

	const highlightText = (text: string, highlight: string) => {
		// Split on highlight term and include term into parts, ignore case
		const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
		return parts.map((part, i: number) =>
			part.toLowerCase() === highlight.toLowerCase() ? (
				<mark key={i}>{part}</mark>
			) : (
				<React.Fragment key={i}>{part}</React.Fragment>
			)
		);
	};

	const sortData = (unsortedData: any[], sortKey: string) => {
		const sortedByLetter: any[] = [];
		unsortedData.map((item) => {
			const value = sortKey ? item[sortKey] : item,
				firstLetter = value.charAt(0).toUpperCase();

			if (sortedByLetter.some((dataSet) => dataSet.letter === firstLetter)) {
				sortedByLetter.find((dataSet) => dataSet.letter === firstLetter).results.push(item);

				sortedByLetter.map((dataSet) => {
					dataSet.results.sort((a: any, b: any) => a.title.localeCompare(b.title));
				});
			} else {
				sortedByLetter.push({ letter: firstLetter, results: [item] });
			}
		});
		sortedByLetter.sort((a, b) => a.letter.localeCompare(b.letter));
		setSortedData(sortedByLetter);
	};

	const openMobileFilters = () => {
		const topOffset = `-${window.pageYOffset}px`;
		document.body.classList.add('body-fixed');
		document.body.style.top = topOffset;
		setMobileFiltersOpen(true);
	};

	const closeMobileFilters = () => {
		if (!!ref.current) {
			const filtersEl = ref.current.querySelector('.mobile-filters-open');
			filtersEl && filtersEl.classList.add('closing');
			setTimeout(() => {
				setMobileFiltersOpen(false);
				filtersEl && filtersEl.classList.remove('closing');

				const topOffset = parseInt(document.body.style.top);
				document.body.classList.remove('body-fixed');
				document.body.style.removeProperty('top');
				window.scroll(0, -topOffset);
			}, 400);
		}
	};

	const addActiveCategoryFilter = (categoryFilter: any) => {
		setActiveCategoryFilters([...activeCategoryFilters, categoryFilter]);
	};

	const removeActiveCategoryFilter = (categoryFilter: any) => {
		setActiveCategoryFilters(
			activeCategoryFilters.filter((e) => e.filterCategory !== categoryFilter.filterCategory)
		);
	};

	const toggleActiveStartingLetter = (startingLetter: any) => {
		activeStartingLetters.includes(startingLetter)
			? setActiveStartingLetters(activeStartingLetters.filter((e) => e !== startingLetter))
			: setActiveStartingLetters([startingLetter]);
	};

	const filterResults = () => {
		let filteredResults = sortedData;

		if (searchText) {
			filteredResults = filteredResults
				.filter((letterSection) =>
					letterSection.results.some((result: any) =>
						result.title.toLowerCase().includes(searchText.toLowerCase())
					)
				)
				.map((letterSection) => {
					const letterSectionWithFilteredResults = {
						...letterSection,
						results: letterSection.results.filter((result: any) =>
							result.title.toLowerCase().includes(searchText.toLowerCase())
						),
					};

					return letterSectionWithFilteredResults;
				});
		}

		if (activeCategoryFilters.length) {
			filteredResults = filteredResults
				.filter((letterSection) =>
					letterSection.results.some((result: any) =>
						activeCategoryFilters.some((filter) =>
							result.categories?.includes(filter.filterCategory)
						)
					)
				)
				.map((letterSection) => {
					const letterSectionWithFilteredResults = {
						...letterSection,
						results: letterSection.results.filter((result: any) =>
							activeCategoryFilters.some((filter) =>
								result.categories.includes(filter.filterCategory)
							)
						),
					};

					return letterSectionWithFilteredResults;
				});
		}

		activeStartingLetters &&
			!filteredResults.some((letterSection) =>
				activeStartingLetters.includes(letterSection.letter)
			) &&
			setActiveStartingLetters([]);

		setResults(filteredResults);
	};

	const resize = () => {
		const viewportSize = GetViewportSize();

		if (viewportSize === 'md' || viewportSize === 'lg' || viewportSize === 'xl') {
			closeMobileFilters();
		}
	};

	return (
		<div className="all-services" ref={ref}>
			<button className="mobile-filters-toggle" onClick={openMobileFilters}>
				<span className="mobile-filters-title">{filterTitle}</span>
				<span className="icon icon-chevron-down">
					<Icons id="chevron-down" />
				</span>
			</button>
			<div className="services-container">
				<div className="services-row">
					<FocusTrap
						active={mobileFiltersOpen}
						focusTrapOptions={{
							escapeDeactivates: false,
						}}
					>
						<div className={`services-filters ${mobileFiltersOpen ? 'mobile-filters-open' : ''}`}>
							<button className="mobile-filters-toggle" onClick={closeMobileFilters}>
								<span>
									<span className="icon icon-chevron-right">
										<Icons id="chevron-down" />
									</span>
									{backToPageTitle}{' '}
								</span>
							</button>
							<div className="filter-section">
								<h4 className="filter-header browse-all">{browseTitle}</h4>
								<div className="input-icon-container">
									<label htmlFor="services-search">{searchData?.name}</label>
									<input
										id="services-search"
										type="text"
										placeholder={searchData?.placeholder || ''}
										onChange={(e) => setSearchText(e.target.value)}
									/>
									<span className="icon icon-search" role="img" aria-hidden="true">
										<Icons isColored id="search" />
									</span>
								</div>
							</div>
							<div className="filter-section">
								<h4 className="filter-header">{viewTitle}</h4>

								{categoryFilters.map((categoryFilter, i) =>
									activeCategoryFilters.some(
										(filter) => filter.filterCategory === categoryFilter.filterCategory
									) ? (
										<div className="tag" key={i}>
											<div className="tag-description">{categoryFilter.title}</div>
											<button
												className="close-btn"
												aria-label={`Remove view only ${categoryFilter.title} filter.`}
												onClick={() => {
													removeActiveCategoryFilter(categoryFilter);
												}}
											>
												<span className="icon icon-close" role="img" aria-hidden="true">
													<Icons id="close" />
												</span>
											</button>
										</div>
									) : (
										<button
											className="view-only-btn"
											onClick={() => {
												addActiveCategoryFilter(categoryFilter);
											}}
											key={i}
										>
											{categoryFilter.title}
										</button>
									)
								)}
							</div>
							<div className="filter-section">
								<h4 className="filter-header">{filterHeader}</h4>
								<div className="starting-letters">
									{'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => {
										return (
											<div className="filter-letter-btn-wrapper" key={letter}>
												<button
													className={`filter-letter-btn ${
														activeStartingLetters.includes(letter) ? 'active' : ''
													}`}
													disabled={
														!results.some((letterSection) => letterSection.letter === letter)
													}
													onClick={() => {
														toggleActiveStartingLetter(letter);
													}}
												>
													{letter}
												</button>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</FocusTrap>
					<div className="services-listings">
						<h2 className="services-title">{pageTitle}</h2>
						{results.map((letterSection, i) =>
							activeStartingLetters.length &&
							!activeStartingLetters.includes(letterSection.letter) ? null : (
								<div className="services-section" key={i}>
									<h4 className="services-section-letter">{letterSection.letter.toUpperCase()}</h4>
									<ul className="services-list">
										{letterSection.results.map((result: any, idx: number) => (
											<li key={idx}>
												<a href={result.url}>
													{result.title.toLowerCase().includes(searchText.toLowerCase())
														? highlightText(result.title, searchText)
														: result.title}
												</a>
											</li>
										))}
									</ul>
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllServices;
