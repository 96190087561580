import React, { useEffect } from 'react';

type Component = {
	componentName: string;
	placeholders?: Placeholders;
};

type PlaceHolder = {
	[key: string]: Component[];
};

type Placeholders = {
	[key: string]: PlaceHolder;
};

type ScriptField = {
	value: string;
};

type MultilistField = {
	fields: {
		location: {
			value: string;
		};
	};
};

type ScriptLoaderProps = {
	fields: {
		scriptcontent: ScriptField;
		selector: MultilistField;
		scriptlocation: MultilistField;
	};
};

const addedScripts = new Set<string>();
let lock: Promise<unknown> = Promise.resolve();

const ScriptLoader: React.FC<ScriptLoaderProps> = ({ fields }) => {
	useEffect(() => {
		const scriptContent = fields?.scriptcontent?.value || '';
		const selector = fields?.selector?.fields?.location?.value;
		const scriptLocation = fields?.scriptlocation?.fields?.location?.value;

		lock = lock.then(() => {
			return new Promise((resolve) => {
				if (selector && !addedScripts.has(scriptContent)) {
					const element = document.querySelector(selector);

					if (element) {
						const parser = new DOMParser();
						const parsedScriptContent = parser.parseFromString(scriptContent, 'text/html');

						const originalScriptElement = parsedScriptContent.querySelector('script');

						const scriptElement = document.createElement('script');

						if (originalScriptElement) {
							const attrs = originalScriptElement.attributes;

							// Copy all the attributes of the original script tag
							for (let i = 0; i < attrs.length; i++) {
								scriptElement.setAttribute(attrs[i].name, attrs[i].value);
							}

							scriptElement.text = originalScriptElement.innerHTML;
						} else {
							scriptElement.text = scriptContent;
						}

						scriptLocation === 'Start'
							? element.prepend(scriptElement)
							: element.append(scriptElement);

						addedScripts.add(scriptContent);
					}
				}

				resolve(true);
			});
		});
	}, [fields]);

	return null;
};

export default ScriptLoader;
