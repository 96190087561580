import React, { useState, useEffect, useCallback } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import GetViewportSize from '../../assets/utils/getViewportSize';
import { SubLinkItem, InternalLinksItem } from 'components/types/OSFHeaderTypes';
import OSFNavMobile from 'components/organisms/OSFNavMobile';

interface OSFNavSubLinksProps {
	subLinks: SubLinkItem[];
	maxColumnRows: number;
	columnNumber: number;
	activeColumn: number | null;
	setActiveColumn: React.Dispatch<React.SetStateAction<number | null>>;
	activeSubColumn: number | null;
	setActiveSubColumn: React.Dispatch<React.SetStateAction<number | null>>;
	groupedSubLinks: Array<{ heading: string; items: SubLinkItem[] }>;
	item: InternalLinksItem;
	setActiveSubNav: React.Dispatch<React.SetStateAction<string>>;
}

const OSFNavSubLinks: React.FC<OSFNavSubLinksProps> = ({
	subLinks,
	maxColumnRows,
	columnNumber,
	activeColumn,
	setActiveColumn,
	activeSubColumn,
	setActiveSubColumn,
	groupedSubLinks,
	item,
	setActiveSubNav,
}) => {
	const [columns, setColumns] = useState<JSX.Element[][]>([]);
	const [cards, setCards] = useState<JSX.Element[]>([]);

	const renderCard = useCallback((subLink: SubLinkItem) => {
		const imagePosition = subLink.fields.imagePosition?.fields?.name || 'Left';
		const isImageLeft = imagePosition === 'Left';

		const { image, heading, description, link } = subLink.fields;
		const { value: imageValue } = image || {};
		const { value: linkValue } = link || {};

		const cardImage = imageValue && (
			<div className="card-image">
				<img
					src={imageValue.src}
					alt={imageValue.alt}
					width={imageValue.width}
					height={imageValue.height}
				/>
			</div>
		);

		const cardContent = (
			<div className="card-content">
				<h3 className="card-heading">{heading?.value}</h3>
				<RichText field={description} className="card-description" />
				{linkValue && (
					<a href={linkValue.href} className="card-link" target={linkValue.target}>
						{linkValue.text}
					</a>
				)}
			</div>
		);

		return (
			<div className="card-list-item" key={subLink.id}>
				<div className={`card ${isImageLeft ? 'image-left' : 'image-right'}`}>
					{isImageLeft ? [cardImage, cardContent] : [cardContent, cardImage]}
				</div>
			</div>
		);
	}, []);

	const processSubLinks = useCallback(() => {
		const newCards: JSX.Element[] = [];
		let currentColumn = 0;
		const totalColumns = columnNumber || 2;
		const newColumns: JSX.Element[][] = Array(totalColumns)
			.fill([])
			.map(() => []);

		const moveToNextColumn = () => {
			currentColumn = currentColumn + 1 < totalColumns ? currentColumn + 1 : currentColumn;
		};

		const getColumnItemCount = (column: JSX.Element[]) => {
			return column.reduce((count, item) => {
				return count + (item.props.children[1]?.props.children.length || 0);
			}, 0);
		};

		const addToColumn = (
			items: SubLinkItem | SubLinkItem['fields']['lists'],
			heading: string | undefined,
			columnIndex: number,
			isFirstItemInColumn: boolean
		) => {
			if (!newColumns[columnIndex]) {
				newColumns[columnIndex] = [];
			}

			if (!Array.isArray(items) && items?.fields?.image && !items?.fields?.allowColumnFlow?.value) {
				newColumns[columnIndex].push(renderCard(items as SubLinkItem));
				return;
			}

			newColumns[columnIndex].push(
				<div
					key={`${heading || 'group'}-${Array.isArray(items) ? items[0]?.id : Date.now()}`}
					className="sub-link-item"
				>
					{isFirstItemInColumn &&
						(!Array.isArray(items) &&
						items?.fields?.generalLink?.value?.href &&
						items?.fields?.generalLink?.value?.href !== 'http://' &&
						items?.fields?.generalLink?.value?.id ? (
							<a
								href={items.fields.generalLink.value.href}
								target={items.fields.generalLink.value.target}
								className="sub-link-heading"
							>
								{heading || '\u00A0'}
							</a>
						) : (
							<div className="sub-link-heading">{heading || '\u00A0'}</div>
						))}
					<ul>
						{Array.isArray(items) &&
							items.map((list) => (
								<li key={list.id}>
									<a
										href={list.fields?.generalLink?.value?.href}
										target={list.fields?.generalLink?.value?.target}
									>
										{list.fields?.generalLink?.value?.text || list.displayName || list.name}
									</a>
								</li>
							))}
					</ul>
				</div>
			);
		};

		subLinks.forEach((subLink) => {
			let isFirstItemInColumn = newColumns[currentColumn].length === 0;
			const linkItemsToRender =
				Array.isArray(subLink.fields.lists) && subLink.fields.lists.length > 0
					? subLink.fields.lists
					: [];
			const isCard = subLink.fields.link && subLink.fields.image && !linkItemsToRender.length;
			const isHeading = subLink.fields.heading?.value && linkItemsToRender.length;
			const shouldStartInNewColumn = subLink.fields.startInNewColumn?.value;

			if (isCard) {
				newCards.push(renderCard(subLink));
				addToColumn(subLink, undefined, currentColumn, false);
				moveToNextColumn();
				isFirstItemInColumn = true;
			}

			if (isHeading) {
				if (!isFirstItemInColumn && shouldStartInNewColumn) {
					moveToNextColumn();
					isFirstItemInColumn = true;
				} else {
					isFirstItemInColumn = true;
				}

				addToColumn(subLink, subLink.fields?.heading?.value, currentColumn, isFirstItemInColumn);
				isFirstItemInColumn = false;
			}

			for (let i = 0; i < linkItemsToRender.length; i++) {
				if (!newColumns[currentColumn]) {
					newColumns[currentColumn] = [];
				}

				const currentColumnItemCount = getColumnItemCount(newColumns[currentColumn]);
				if (currentColumnItemCount >= maxColumnRows) {
					if (!subLink.fields.allowColumnFlow?.value) {
						break;
					}
					moveToNextColumn();
					isFirstItemInColumn = true;
					if (isFirstItemInColumn) {
						addToColumn([], '\u00A0', currentColumn, true);
						isFirstItemInColumn = false;
					}
					addToColumn([linkItemsToRender[i]], undefined, currentColumn, false);
					continue;
				}

				if (isFirstItemInColumn) {
					addToColumn([], '\u00A0', currentColumn, true);
					isFirstItemInColumn = false;
				}

				addToColumn([linkItemsToRender[i]], undefined, currentColumn, false);
			}

			if (
				!subLink.fields.allowColumnFlow?.value ||
				(linkItemsToRender.length === 0 && !subLink.fields.link)
			) {
				moveToNextColumn();
				isFirstItemInColumn = true;
			}
		});

		return { newCards, newColumns };
	}, [subLinks, maxColumnRows, columnNumber, renderCard]);

	useEffect(() => {
		const { newCards, newColumns } = processSubLinks();
		setCards(newCards);
		setColumns(newColumns);
	}, [processSubLinks]);

	const viewportSize = GetViewportSize();
	if (viewportSize !== 'xl') {
		return (
			<div className="sub-nav-content">
				<OSFNavMobile
					groupedSubLinks={groupedSubLinks}
					activeColumn={activeColumn}
					setActiveColumn={setActiveColumn}
					activeSubColumn={activeSubColumn}
					setActiveSubColumn={setActiveSubColumn}
					item={item}
					setActiveSubNav={setActiveSubNav}
					cards={cards}
				/>
			</div>
		);
	}

	return (
		<div className={`sub-nav-content tts`}>
			{columns
				.filter((column) => column.length > 0)
				.map((column, index) => (
					<div key={index} className="sub-nav-column">
						{column}
					</div>
				))}
		</div>
	);
};

export default OSFNavSubLinks;
