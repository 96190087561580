class GetCareHeader {
	constructor(Utils) {
		if (typeof window !== 'undefined') {
			const document = window.document;
			let el = document?.querySelector('[data-module="GetCareHeader"]'),
				objects = {
					buttons: el?.querySelectorAll('.menu-container .has-children'),
					mobileNav: el?.querySelector('.toggle'),
					menuContainer: el?.querySelector('.menu-container'),
					subNavLinks: el?.querySelectorAll('.menu-container .has-children .columns ul li a'),
				};

			this.closeMobNav = () => {
				objects?.menuContainer?.classList?.remove('mobileOpen');
			};
			this.navClick = (e) => {
				const navItem = e.target.closest('.has-children');
				objects?.buttons?.forEach((element) => {
					element != navItem && element?.classList.remove('sfHover');
				});
				if (navItem?.classList?.contains('sfHover')) {
					navItem.classList?.remove('sfHover');
				} else {
					navItem.classList?.add('sfHover');
				}
			};

			this.mobileNavClick = (e) => {
				objects?.menuContainer?.classList?.toggle('mobileOpen');

				if (!objects?.menuContainer?.classList?.contains('mobileOpen')) {
					e?.target?.classList?.add('mobileOpen');
				} else {
					e?.target?.classList?.remove('mobileOpen');
				}
			};

			this.documentClick = (e) => {
				if (!e.target.closest('.has-children')) {
					objects?.buttons?.forEach((element) => {
						element?.classList.remove('sfHover');
					});
				}
			};

			this.firstRun = () => {
				console.log('---> GetCareHeader Module Init');

				objects?.buttons?.forEach((item) => {
					item?.addEventListener('click', this.navClick);
				});

				objects?.mobileNav?.addEventListener('click', this.mobileNavClick);

				objects?.subNavLinks?.forEach((link) => {
					link?.addEventListener('click', this.closeMobNav);
				});

				document?.addEventListener('click', this.documentClick);
			};
		}
	}

	name() {
		return 'GetCareHeader';
	}

	init() {
		if (typeof window !== 'undefined') {
			this.firstRun();
		}
	}
}

export default GetCareHeader;
