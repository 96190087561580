import { LinkField, Link, Text, Field, Image, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

type LinkGroupParentProps = {
	fields: {
		BaseHeadline: Field<string>;
		BaseLinkGroupChild: Array<LinkGroupChildProps>;
		BaseCTA: LinkField;
	};
};

type LinkGroupChildProps = {
	fields: {
		BaseImage: ImageField;
		BaseHeading: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
	};
};

const LinkGroup = (props: LinkGroupParentProps): JSX.Element => {
	if (!props || !props.fields || !props.fields.BaseLinkGroupChild) {
		return <div></div>;
	}
	return (
		<div className="overhauled">
			<div className="link-group bg-primary inner-vertical-spacing">
				<div className="jss-inner-container">
					<div className="inner-container">
						{props.fields?.BaseHeadline?.value && (
							<Text className="heading" tag="h2" field={props.fields?.BaseHeadline} />
						)}
						<div className="wrapper">
							<div className="link-items">
								{props.fields?.BaseLinkGroupChild &&
									props.fields?.BaseLinkGroupChild?.map((item, index) => (
										<div key={index} className="link-item">
											{item.fields?.BaseImage?.value?.src && (
												<div className="img-container">
													<Image field={item.fields?.BaseImage} />
												</div>
											)}
											{item.fields?.BaseHeading?.value && (
												<Text tag="h3" className="osf-inner-h3" field={item.fields?.BaseHeading} />
											)}
											{item.fields?.BaseDescription?.value && (
												<Text tag="p" field={item.fields?.BaseDescription} />
											)}
											{item.fields?.BaseLink?.value?.href && (
												<Link className="link btn-tertiary" field={item.fields?.BaseLink} />
											)}
										</div>
									))}
							</div>
						</div>
						{props.fields?.BaseCTA?.value?.href && (
							<Link field={props.fields?.BaseCTA} className="btn-primary" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LinkGroup;
