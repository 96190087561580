import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type TextBoxProps = StyleguideComponentProps & {
	fields: {
		content: Field<string>;
	};
};

const TextBox = (props: TextBoxProps): JSX.Element => {
	//const { content } = props.fields;

	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="text-box text-container jss-inner-container">
			{props.fields.content && (
			<RichText field={props.fields.content} />
			)}
		</div>
	);
};

export default TextBox;
