import { Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type FooterResourcesListProps = StyleguideComponentProps & {
	fields: {
		link: LinkField;
		items: Array<ItemValue>;
	};
};

const FooterResourcesList = (props: FooterResourcesListProps): JSX.Element => {
	return (
		<>
			{props.fields.items.map((item) => (
				<Link
					className="footer-link"
					aria-label="OSF Link"
					data-target=""
					key={item.id}
					field={item.fields.link}
				/>
			))}
		</>
	);
};

export default FooterResourcesList;
