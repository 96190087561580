import React from 'react';
import ActionButton from '../r2/ActionButton';
import { ItemValue } from 'components/types/ListItem';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

type ActionButtonItem = {
	id: string;
	fields: {
		BaseLink: {
			value: {
				text: string;
				href: string;
				target: string;
			};
		};
		BaseSvg: {
			value: string;
		};
		BasePhoneNumber: Field<string>;
	};
};

type ActionButtonGroupProps = {
	fields: {
		BaseBackgroundColor: ItemValue;
		list: Array<ActionButtonItem>;
	};
};

const ActionButtonGroup = (props: ActionButtonGroupProps): JSX.Element => {
	const { fields } = props;
	return (
		<>
			<div className="overhauled">
				<div
					className={`${
						fields?.BaseBackgroundColor?.name ? fields?.BaseBackgroundColor?.name : 'bg-container'
					}`}
				>
					<div className="jss-inner-container">
						<div className="action-btn-group">
							{fields?.list?.map(({ id, fields }) => {
								return (
									<ActionButton
										key={id}
										isLinkExternal={fields?.BaseLink?.value?.target || ''}
										icon={fields?.BaseSvg?.value || ''}
										link={fields?.BaseLink?.value?.href || '#'}
										label={fields?.BaseLink?.value?.text || ''}
										phone={fields?.BasePhoneNumber?.value}
										additionalClasses={fields?.BaseSvg?.value || ''}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ActionButtonGroup;
