import React from 'react';
import { StyleguideComponentProps } from 'lib/component-props';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

// eslint-disable-next-line
type ThreeColumnLayoutProps = StyleguideComponentProps & {};

const ThreeColumn = (props: ThreeColumnLayoutProps): JSX.Element => {
	const { rendering } = props;
	return (
		<div className="jss-three-column">
			<div className="three-column-left">
				<Placeholder name="three-column-left" rendering={rendering} />
			</div>
			<div className="three-column-middle">
				<Placeholder name="three-column-middle" rendering={rendering} />
			</div>
			<div className="three-column-right">
				<Placeholder name="three-column-right" rendering={rendering} />
			</div>
		</div>
	);
};

export default ThreeColumn;
