import React, { useEffect } from 'react';
import ArticleListingFilters from 'components/organisms/ArticleListingFilters';
import { useFilter } from 'components/context/FilterContext';
import { useQuery } from '@apollo/client';
import GET_CATEGORIES from './graphql/GET_CATEGORIES';

type ArticleListingComponentProps = {
	// eslint-disable-next-line
	fields: any;
};

const ArticleListingComponent = (props: ArticleListingComponentProps): JSX.Element => {
	const { setFilterState } = useFilter();

	const { data: queryData } = useQuery(GET_CATEGORIES, {
		variables: {
			path: '{2F3908C9-8A4D-444D-80B2-FC30C6E216D1}', // path to categories
			templateID: '{38CF2D0D-C5FE-4BFD-84CD-E4EED14D0F94}', // template id of categories
		},
		context: { clientName: 'searchOnEdge' },
	});

	useEffect(() => {
		if (queryData && queryData.search) {
			setFilterState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					categoriesList: queryData.search.results || [],
				},
			}));
		}
	}, [queryData]);

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilterState((prevState) => ({
			...prevState,
			filter: {
				...prevState.filter,
				[name]: value,
			},
		}));
	};

	const handleInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setFilterState((prevState) => ({
			...prevState,
			filter: {
				...prevState.filter,
				[name]: value,
			},
		}));
	};

	return (
		<div>
			<ArticleListingFilters
				{...{ handleInputSearch, handleSelectChange }}
				fields={{ ...props?.fields }}
			/>
		</div>
	);
};

export default ArticleListingComponent;
