import {
	Text,
	RichText,
	Field,
	LinkField,
	Link,
	ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ItemValue } from 'components/types/ListItem';

type SpotlightTallProps = {
	fields: {
		BaseImage: ImageField;
		BaseHeadline: Field<string>;
		BaseDescription: Field<string>;
		BaseAlignment: ItemValue;
		BaseColor: ItemValue;
		BaseGradient: {
			value: boolean;
		};
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
	};
};

const SpotlightTall = (props: SpotlightTallProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	const backgroundStyle: React.CSSProperties = {};
	if (props.fields.BaseImage?.value?.src) {
		backgroundStyle.backgroundImage = `url(${props.fields.BaseImage?.value?.src})`;
	}

	return (
		<div className="overhauled">
			<div className="spotlight tall" style={backgroundStyle}>
				<div
					className={`overlay ${
						props.fields?.BaseAlignment?.name
							? `${props.fields?.BaseAlignment?.name?.toLowerCase()}`
							: ''
					} ${
						props.fields?.BaseColor?.name ? `${props.fields?.BaseColor?.name?.toLowerCase()}` : ''
					} ${props.fields?.BaseGradient?.value ? 'gradient' : ''}`}
				></div>
				<div
					className={`jss-inner-container content-wrapper ${
						props.fields?.BaseAlignment?.name
							? `align-${props.fields?.BaseAlignment?.name?.toLowerCase()}`
							: ''
					}`}
				>
					{props.fields?.BaseHeadline?.value && (
						<Text tag="h2" className="headline" field={props.fields.BaseHeadline} />
					)}
					{props.fields?.BaseDescription?.value && (
						<RichText tag="p" className="description" field={props.fields.BaseDescription} />
					)}
					{props.fields?.BaseLink?.value?.href && props.fields?.BaseLinkType?.name && (
						<Link
							field={props.fields?.BaseLink?.value}
							className={
								props.fields?.BaseLinkType
									? `btn-mobile-fw btn-${props.fields?.BaseLinkType?.name.toLowerCase()}`
									: 'btn-mobile-fw'
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SpotlightTall;
