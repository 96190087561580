import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type BackgroundImageProps = StyleguideComponentProps & {
	fields: {
		imageLarge: ImageField;
		imageSmall: ImageField;
	};
};

const BackgroundImage = (props: BackgroundImageProps): JSX.Element => (
	<div className="background-image">
		<picture>
			{props.fields.imageSmall && (
				<source media="(min-width: 414px)" srcSet={props.fields.imageLarge.value?.src} />
			)}

			{props.fields.imageSmall && <img src={props.fields.imageSmall.value?.src} />}
		</picture>
	</div>
);

export default BackgroundImage;
