import React from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import { AlertFilterGroup } from 'components/types/AlertTypes';

type AlertListingFiltersProps = {
	Title: Field<string>;
	activeFilterGroup: string;
	filterItem: Array<AlertFilterGroup>;
	// eslint-disable-next-line
	onFilterClick: (value: string) => void;
	onMobileCloseClick?: () => void;
};

const AlertListingFilters = (props: AlertListingFiltersProps): JSX.Element => {
	const { Title, activeFilterGroup, onFilterClick, onMobileCloseClick, filterItem } = props;

	return (
		<div className="alert-listing-filters">
			<Text className="filters-title" tag="h4" field={Title} />
			<ul className="filter-button-list">
				<li>
					<button
						className={`filter-button ${activeFilterGroup === 'all' ? 'active' : ''}`}
						onClick={() => {
							onFilterClick('all');

							if (onMobileCloseClick) {
								onMobileCloseClick();
							}
						}}
					>
						All Alerts
					</button>
				</li>
				{filterItem
					?.filter((data) => data?.fields?.alerts?.length)
					?.map(({ id, fields }) => (
						<li key={id}>
							<button
								className={`filter-button ${
									activeFilterGroup === fields?.Title?.value ? 'active' : ''
								}`}
								onClick={() => {
									onFilterClick(fields?.Title?.value || '');

									if (onMobileCloseClick) {
										onMobileCloseClick();
									}
								}}
							>
								{fields?.Title?.value || ''}
							</button>
						</li>
					))}
			</ul>
			{onMobileCloseClick && (
				<button className="mobile-filter-close" onClick={onMobileCloseClick}>
					<span className="icon icon-close" aria-hidden="true">
						<Icons id="close" />
					</span>
				</button>
			)}
		</div>
	);
};

export default AlertListingFilters;
