import {
	Text,
	Field,
	LinkField,
	ImageField,
	Link,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type ThreeUpProps = {
	id: string;
	fields: {
		BaseImage: ImageField;
		BaseHeading: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
	};
};

type ThreeUpParentProps = StyleguideComponentProps & {
	fields: {
		BaseBackground: {
			value: boolean;
		};
		BaseHeadline: Field<string>;
		BaseViewMoreLink: LinkField;
		children: Array<ThreeUpProps>;
	};
};

const ThreeUp = (props: ThreeUpParentProps): JSX.Element => {
	if (props.fields == undefined) {
		return <div>Datasource not set </div>;
	}
	return (
		<div className="overhauled">
			<div
				className={`${
					props.fields.BaseBackground?.value
						? 'bg-secondary jss-three-up-wrapper inner-vertical-spacing'
						: 'jss-three-up-wrapper outer-vertical-spacing'
				}`}
			>
				{props.fields.BaseHeadline?.value && (
					<div className="jss-three-up-headline jss-inner-container">
						<h2>{props.fields.BaseHeadline?.value}</h2>
					</div>
				)}
				<div
					className={`jss-inner-container
						${props.fields?.children?.length == 1 ? 'jss-one-up' : 'jss-three-up'}`}
				>
					<div className="inner-wrapper">
						{props.fields?.children?.length > 0 &&
							props.fields.children.map((item) => (
								<div key={item.id}>
									{item.fields?.BaseLink?.value?.href ? (
										<Link field={item.fields.BaseLink} className="link-card">
											<div
												className={`additional-cards inner-side-spacing ${
													item.fields?.BaseImage?.value?.src ? 'with-img' : ''
												}`}
											>
												{item.fields?.BaseImage?.value?.src !== undefined && (
													<div className="card-img">
														<img
															src={item.fields?.BaseImage?.value?.src}
															alt={
																(item.fields?.BaseImage?.value?.alt as string) != ''
																	? (item.fields?.BaseImage?.value?.alt as string)
																	: 'Image'
															}
														/>
													</div>
												)}
												<div className="content">
													{item.fields?.BaseHeading?.value && (
														<Text
															tag="h3"
															className="osf-inner-h3 header"
															field={item.fields.BaseHeading}
														/>
													)}
													{item.fields?.BaseDescription?.value && (
														<RichText
															tag=""
															className="description osf-body"
															field={item.fields.BaseDescription}
														/>
													)}
													{item.fields?.BaseLink?.value?.href && (
														<Link field={item.fields.BaseLink} className="btn-tertiary" />
													)}
												</div>
											</div>
										</Link>
									) : (
										<div
											key={item.id}
											className={`additional-cards inner-side-spacing ${
												item.fields?.BaseImage?.value?.src ? 'with-img' : ''
											}`}
										>
											{item.fields?.BaseImage?.value?.src !== undefined && (
												<div className="card-img">
													<img
														src={item.fields?.BaseImage?.value?.src}
														alt={
															(item.fields?.BaseImage?.value?.alt as string) != ''
																? (item.fields?.BaseImage?.value?.alt as string)
																: 'Image'
														}
													/>
												</div>
											)}
											<div className="content">
												{item.fields?.BaseHeading?.value && (
													<Text
														tag="h3"
														className="osf-inner-h3 header"
														field={item.fields.BaseHeading}
													/>
												)}
												{item.fields?.BaseDescription?.value && (
													<RichText
														tag=""
														className="description osf-body"
														field={item.fields.BaseDescription}
													/>
												)}
												{item.fields?.BaseLink?.value?.href && (
													<Link field={item.fields.BaseLink} className="btn-tertiary" />
												)}
											</div>
										</div>
									)}
								</div>
							))}
					</div>
					{props.fields?.BaseViewMoreLink?.value?.href && (
						<div className="view-more">
							<Link className="btn-primary" field={props.fields?.BaseViewMoreLink} />
							<div className="divider"></div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default ThreeUp;
