import React from 'react';

type InputProps = {
	value: string;
	name: string;
	// eslint-disable-next-line
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormTextField = ({ name, value, handleChange, ...props }: InputProps): JSX.Element => {
	return <input {...props} {...{ name, value, onChange: handleChange }} />;
};

export default FormTextField;
