import React, { useState } from 'react';
import { ImageField, Field, LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import Image from '../atoms/Image';
import Video from 'components/atoms/Video';
import { ItemValue } from '../types/ListItem';
import Icons from '../atoms/Icons';
import Shapes from 'components/molecules/Shapes';

type HeroComponentProps = StyleguideComponentProps & {
	fields: {
		BaseImage: ImageField;
		BaseVIdeoURL: Field<string>;
		BaseTitle: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
		BaseChoiShapes: ItemValue;
	};
};

const HeroComponent = (props: HeroComponentProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	const [playing, setPlaying] = useState(1);

	const controlVideo = () => {
		const vid: HTMLMediaElement | null = document.querySelector('.video');
		setPlaying(playing == 0 ? 1 : 0);
		playing == 0 ? vid?.play() : vid?.pause();
	};

	return (
		<div className="overhauled">
			<div className={`hero ${props.fields.BaseVIdeoURL?.value ? 'hero-video' : ''}`}>
				{props.fields?.BaseChoiShapes?.name && (
					<Shapes
						variant={props.fields?.BaseChoiShapes?.name.toLowerCase().replaceAll(' ', '')}
					></Shapes>
				)}
				{props.fields.BaseVIdeoURL?.value && (
					<div
						className="video-btn"
						onClick={controlVideo}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ') {
								e.preventDefault();
								controlVideo();
							}
						}}
						tabIndex={0}
					>
						{playing == 0 ? <Icons id={'play-btn'}></Icons> : <Icons id={'pause-btn'}></Icons>}
					</div>
				)}
				{props.fields.BaseImage?.value?.src && (
					<Image
						imageUrl={props.fields.BaseImage?.value?.src as string}
						alt={
							(props.fields.BaseImage.value?.alt as string) != ''
								? (props.fields.BaseImage.value?.alt as string)
								: 'Image'
						}
						aditionalClass="hero-img"
					/>
				)}
				{props.fields.BaseVIdeoURL?.value && (
					<div className="hero-video">
						<Video
							videoUrl={props.fields?.BaseVIdeoURL?.value}
							aditionalClass={'video hero-video'}
						></Video>
					</div>
				)}
				<div className={`hero-inner-wrapper`}>
					<div className="jss-inner-container">
						<div className="hero-content">
							{props.fields.BaseTitle?.value && (
								<h1 className="osf-home-h1">{props.fields.BaseTitle?.value}</h1>
							)}
							{props.fields.BaseDescription?.value && (
								<p className="osf-intro-copy">{props.fields.BaseDescription?.value}</p>
							)}
							{props.fields.BaseLink?.value?.href && props.fields.BaseLink?.value.href !== '' && (
								<>
									<Link
										className={`btn-${props.fields.BaseLinkType?.name.toLowerCase()}`}
										field={props.fields.BaseLink}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroComponent;
