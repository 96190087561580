import React, { useState } from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import AlertListingFilters from 'components/molecules/AlertListingFilters';
import AlertListingGroup from 'components/molecules/AlertListingGroup';
import { AlertFilterGroup } from 'components/types/AlertTypes';

type AlertListingPageProps = {
	fields: {
		Title: Field<string>;
		heading: Field<string>;
		FilterAlertList: Array<AlertFilterGroup>;
		Button: {
			fields: {
				Title: Field<string>;
				Svg: Field<string>;
			};
		};
	};
};

const AlertListingPage = (props: AlertListingPageProps): JSX.Element => {
	const { fields } = props;
	const [activeFilterGroup, setActiveFilterGroup] = useState('all');
	const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

	return (
		<>
			{fields ? (
				<div className="alert-listing-page">
					<div className="alert-listing-page-container">
						<div className="alert-listing-row">
							<div className="title-filter-toggle-container">
								<Text tag="h2" className="alert-listing-page-title" field={fields?.Title} />
								<button
									aria-label="Open Alerts Filter List"
									className="mobile-filter-toggle"
									onClick={() => {
										setMobileFiltersOpen(true);
									}}
								>
									<span className="icon icon-chevron-down" aria-hidden="true">
										<Icons id={fields?.Button?.fields?.Svg?.value || 'chevron-down'} />
									</span>
									{fields?.Button?.fields?.Title?.value || ''}
								</button>
								{mobileFiltersOpen && (
									<div className="mobile-filters-container">
										{fields?.FilterAlertList?.length ? (
											<AlertListingFilters
												filterItem={fields?.FilterAlertList}
												Title={fields?.heading}
												activeFilterGroup={activeFilterGroup}
												onFilterClick={setActiveFilterGroup}
												onMobileCloseClick={() => {
													setMobileFiltersOpen(false);
												}}
											/>
										) : null}
									</div>
								)}
							</div>
						</div>
						<div className="alert-listing-row">
							{fields && fields?.FilterAlertList?.length ? (
								<div className="alert-listing-filter-container">
									<AlertListingFilters
										filterItem={fields?.FilterAlertList}
										Title={fields?.heading}
										activeFilterGroup={activeFilterGroup}
										onFilterClick={setActiveFilterGroup}
									/>
								</div>
							) : null}
							<div className="alert-listing-groups">
								{fields &&
									fields?.FilterAlertList?.filter((val) => val?.fields?.alerts)?.map(
										({ id, fields }) =>
											(activeFilterGroup === 'all' ||
												activeFilterGroup === fields?.Title?.value) && (
												<AlertListingGroup
													key={id}
													listTitle={fields?.Title}
													results={fields?.alerts}
												/>
											)
									)}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default AlertListingPage;
