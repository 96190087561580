import React, { useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from '../atoms/Icons';
import { classNames } from 'assets/utils/helper';

type AccordionProps = {
	fields: {
		AccordionHeader: Field<string>;
		AccordionList: Array<AccordionDataContent>;
	};
};

type AccordionDataContent = {
	id: string;
	fields: {
		BaseHeading: Field<string>;
		BaseHeadingH2: {
			value: boolean;
		};
		BaseDescription: Field<string>;
		BaseBackground: {
			value: boolean;
		};
	};
};

const Accordion = (props: AccordionProps): JSX.Element => {
	const [activeKey, setActiveKey] = useState<string>('');

	const setActiveAccordion = (id: string) => setActiveKey(activeKey === id ? '' : id);

	if (!props.fields) {
		return <p>No datasource set</p>;
	}

	return (
		<div className="overhauled">
			<div className="accordion-container outer-vertical-spacing">
				<div className="jss-inner-container">
					<RichText
						className="accordion-top-header"
						tag="div"
						field={props.fields.AccordionHeader}
					/>
					<div className="accordion">
						{props?.fields &&
							props?.fields?.AccordionList?.map(({ id, fields }, index) => {
								return (
									<div
										key={index}
										className={classNames('accordion-item', id === activeKey ? 'active' : '')}
									>
										<div
											className={`accordion-header inner-side-spacing ${
												fields?.BaseBackground?.value ? 'background bg-secondary' : ''
											}`}
											onClick={() => setActiveAccordion(id)}
											onKeyDown={(e) => {
												if (e.key === 'Enter' || e.key === ' ') {
													e.preventDefault();
													setActiveAccordion(id);
												}
											}}
											tabIndex={0}
										>
											{' '}
											{fields?.BaseHeading && fields?.BaseHeadingH2?.value == true ? (
												<h2 className="accordion-label osf-h2">{fields?.BaseHeading?.value}</h2>
											) : (
												<h4 className="accordion-label osf-h4">{fields?.BaseHeading?.value}</h4>
											)}
											<Icons id={id === activeKey ? 'chevron-up' : 'chevron-down'} />
										</div>
										<RichText
											tag="div"
											field={fields?.BaseDescription || ''}
											className={classNames('accordion-content osf-body')}
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Accordion;
