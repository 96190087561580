import React, { useState } from 'react';
import Button from 'components/atoms/Button';
import Modal from 'assets/shared/Modal';

type RecipeVideoProps = {
	fields: {
		'YouTube Thumbnail': {
			value: string;
		};
		'YouTube Video ID': {
			value: string;
		};
	};
};

const RecipeVideo = (props: RecipeVideoProps): JSX.Element => {
	const { fields } = props;
	const [modalOpen, setModalOpen] = useState(false);
	const handleClick = () => setModalOpen(true);

	return (
		<div>
			{modalOpen ? (
				<Modal
					customClass={`innovation-modal media-modal`}
					openOnLoad={true}
					modalOnly={true}
					onCloseModal={() => {
						setModalOpen(false);
					}}
					portalId="media-modal-root"
				>
					<iframe
						src={`https://www.youtube.com/embed/${fields?.['YouTube Video ID']?.value}?enablejsapi=1`}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					></iframe>
				</Modal>
			) : null}
			<Button {...{ handleClick }} label="Watch Video" className="innovation-button" />
			<div id="media-modal-root"></div>
		</div>
	);
};

export default RecipeVideo;
