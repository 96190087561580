const breakpoints = {
	xs: 320,
	sm: 414,
	md: 768,
	lg: 1024,
	xl: 1440,
	xxl: 1680,
}

export default () => {

	if (typeof window !== 'undefined') {
		let width = window.innerWidth
		if (width < breakpoints.sm) {
			return 'xs'
		} else if (width < breakpoints.md) {
			return 'sm'
		} else if (width < breakpoints.lg) {
			return 'md'
		} else if (width < breakpoints.xl) {
			return 'lg'
		} else {
			return 'xl'
		}
	}
}
