import React from 'react';
import moment from 'moment';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { AlertItem } from 'components/types/AlertTypes';

type AlertListingGroupProps = {
	listTitle: Field<string>;
	results: Array<AlertItem>;
};

const AlertListingGroup = (props: AlertListingGroupProps): JSX.Element => {
	const { listTitle, results } = props;

	return (
		<div className="alert-listing-group">
			{results?.length ? (
				<Text tag="h5" className="alert-listing-group-title" field={listTitle} />
			) : null}
			{results?.map(({ id, fields }) => (
				<div key={id} className="alert-listing">
					<div className="alert-type-date">
						<Text className="alert-type" tag="span" field={fields?.Title} />
						<Text
							className="alert-date"
							tag="span"
							field={{
								value: moment(fields?.Date?.value).format('MM/DD/YYYY'),
							}}
						/>
					</div>
					<Text className="alert-content" tag="span" field={fields?.Teaser} />
					{fields?.LearnMore?.value?.href && (
						<a
							href={fields?.LearnMore?.value?.href}
							className="learn-more"
							target={fields?.LearnMore?.value?.target}
						>
							Learn More...
						</a>
					)}
				</div>
			))}
		</div>
	);
};

export default AlertListingGroup;
