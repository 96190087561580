import {
	LinkField,
	Link,
	Text,
	Field,
	Image,
	ImageField,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import GetOsfViewportSize from '../../assets/utils/getOsfViewportSize';
import { StyleguideComponentProps } from 'lib/component-props';
import { useEffect, useState } from 'react';
import Icons from '../atoms/Icons';

type LinkListParentProps = StyleguideComponentProps & {
	fields: {
		BaseHeadline: Field<string>;
		BaseDescription: Field<string>;
		BaseImage: ImageField;
		BaseLinks: Array<LinkListChildProps>;
	};
};

type LinkListChildProps = {
	fields: {
		BaseURL: LinkField;
	};
};

const LinkList = (props: LinkListParentProps): JSX.Element => {
	const [size, setSize] = useState(GetOsfViewportSize());

	const resize = () => {
		const viewportSize = GetOsfViewportSize();
		setSize(viewportSize);
	};

	useEffect(() => {
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	if (!props || !props.fields || !props.fields.BaseLinks) {
		return <div></div>;
	}
	return (
		<div className="overhauled">
			<div className="link-list bg-primary inner-vertical-spacing">
				<div className="jss-inner-container">
					<div className="inner-container">
						<div className="desktop">
							{(size == 'lg' || size == 'xl' || size == 'xxl') &&
								props.fields.BaseImage?.value?.src && (
									<div className="img-container">
										<Image field={props.fields.BaseImage} />
									</div>
								)}
						</div>
						<div className="content-wrapper">
							{props.fields.BaseHeadline?.value && (
								<Text className="heading" tag="h2" field={props.fields.BaseHeadline} />
							)}
							{props.fields.BaseDescription?.value && (
								<RichText className="description" tag="p" field={props.fields.BaseDescription} />
							)}
							<div className="wrapper">
								<div className="mobile">
									{(size == 'xs' || size == 'sm' || size == 'md') &&
										props.fields.BaseImage?.value?.src && (
											<div className="img-container">
												<Image field={props.fields.BaseImage} />
											</div>
										)}
								</div>
								<div className="links">
									{props.fields?.BaseLinks &&
										props.fields?.BaseLinks?.map((item, index) => (
											<Link className="link osf-inner-h3" field={item.fields.BaseURL} key={index}>
												<div className="link-outer">
													<div className="link-wrapper">
														<span className="osf-inner-h3">
															{item.fields.BaseURL?.value?.text ||
																item.fields.BaseURL?.value?.title}
														</span>
														<Icons id={'chevron-next'} />
													</div>
												</div>
											</Link>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LinkList;
