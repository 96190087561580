import React, { useEffect } from 'react';
import {
	Field,
	Image,
	ImageField,
	RichText,
	Text,
	LinkField,
	Link,
} from '@sitecore-jss/sitecore-jss-nextjs';
import ArrowGo from '../../assets/images/icons/arrow-go.svg';

type LinksItem = {
	fields: {
		Link: LinkField;
	};
};

type ChatBotLinksItem = {
	id: string;
	fields: {
		Link: {
			value: {
				href: string;
				url: string;
				text: string;
			};
		};
	};
};

type CareScheduleAndChatProps = {
	fields: {
		Title: Field<string>;
		Image: ImageField;
		ChatbotTitle: Field<string>;
		Description: Field<string>;
		Links: Array<LinksItem>;
		ChatbotLinks: Array<ChatBotLinksItem>;
	};
};

const CareScheduleAndChat = (props: CareScheduleAndChatProps): JSX.Element => {
	const { fields } = props;
	const handleOpenClare = (id: string) => {
		const widget = document.getElementById('widgetRoot');
		const widgetBtn = document.getElementById('toggle-widget-button');
		if (widget) {
			widget.style.display = 'block';
			widgetBtn?.click();
		}
		return id;
	};

	useEffect(() => {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
					const addedNode = mutation.addedNodes[0];
					if (addedNode.nodeName === 'DIV') {
						const divNode = addedNode as HTMLElement;
						const myDivId = divNode.id;
						if (myDivId === 'widgetRoot') {
							// eslint-disable-next-line
							const target = document.getElementById('widgetRoot')!;
							target.style.display = 'none';
						}
					}
				}
			});
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, []);

	return (
		<div className="care-schedule-and-chat">
			<Text className="care-heading" tag="h3" field={fields?.Title || ''} />
			<ul className="care-link-list">
				{fields?.Links?.map(({ fields }, index) => (
					<li key={index}>
						<Link
							className="care-link"
							rel="noreferrer"
							field={fields?.Link}
							onClick={(e) => e.stopPropagation()}
						/>
					</li>
				))}
			</ul>
			<div className="chat-section">
				<div className="chat-img">
					<Image alt="Clare" field={fields?.Image || ''} />
				</div>
				<div className="chat-content">
					<Text tag="h4" className="care-heading" field={fields?.ChatbotTitle || ''} />
					<RichText field={fields?.Description || ''} />
					{fields?.ChatbotLinks?.map(({ fields, id }) => (
						<span key={id} className="chat-link" onClick={() => handleOpenClare(id)}>
							{fields?.Link?.value?.text || ''}
							<span className="icon">
								<ArrowGo className="icon-arrow-go" />
							</span>
						</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default CareScheduleAndChat;
