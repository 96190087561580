export default {
	breakpoints: {
		xs: 0,
		sm: 375,
		md: 768,
		lg: 1280,
		xl: 1440,
		xxl: 1680,
	},
};
