import React from 'react';
import { withExperienceEditorChromes } from '@sitecore-jss/sitecore-jss-react';

const PageNavigation = (): JSX.Element => {
	return (
		<ul className="page-navigation gic-page-navigation">
			<li>
				<a aria-label="mammograms" href="Mammograms">
					<div className="list-block">Mammograms</div>
				</a>
			</li>
			<li>
				<a aria-label="safety-protocols" href="safety-protocols">
					<div className="list-block">Safety Protocols</div>
				</a>
			</li>
			<li>
				<a aria-label="patient-stories" href="patient-stories">
					<div className="list-block">Patient Stories</div>
				</a>
			</li>
		</ul>
	);
};

export default withExperienceEditorChromes(PageNavigation);
