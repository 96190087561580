import React, { useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import GetViewportSize from '../../assets/utils/getViewportSize';
import { Image, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import { classNames } from 'assets/utils/helper';
import SubNavProvider from 'components/molecules/SubNavProvider';
import R2SearchBar from './R2SearchBar';

const linkClassName = ['get-care-btn', 'find-btn'];

type LinkItem = {
	id: string;
	fields: {
		svg: {
			value: string;
		};
		Link: {
			value: {
				href: string;
				text: string;
				url: string;
				target: string;
			};
		};
	};
};

type SubLinkItem = {
	id: string;
};

type internalLinksItem = {
	id: string;
	fields: {
		BackgroundColor: {
			name: string;
		};
		Link: LinkField;
		SubLink: Array<SubLinkItem>;
		svg: {
			value: string;
		};
	};
};

type R2HeaderProps = {
	fields: {
		logo: ImageField;
		placeholder: {
			value: string;
		};
		svg: {
			value: string;
		};
		links: Array<LinkItem>;
		internalLinks: Array<internalLinksItem>;
		homeUrl: {
			value: {
				target: string;
				href: string;
			};
		};
	};
};

const R2Header = (props: R2HeaderProps): JSX.Element => {
	const ref = useRef<HTMLInputElement>(null);
	const { fields } = props;
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [activeSubNav, setActiveSubNav] = useState('');

	const openMobileNav = () => {
		const topOffset = `-${window.pageYOffset}px`;
		document.body.classList.add('body-fixed');
		document.body.style.top = topOffset;
		setMobileNavOpen(true);
	};

	const closeMobileNav = () => {
		const mobileNavEl = ref?.current?.querySelector('.mobile-nav-open');
		mobileNavEl && mobileNavEl.classList.add('closing');
		const viewportSize = GetViewportSize();

		setTimeout(() => {
			setMobileNavOpen(false);
			setActiveSubNav('');
			mobileNavEl && mobileNavEl.classList.remove('closing');

			const topOffset = parseInt(document.body.style.top);
			document.body.classList.remove('body-fixed');
			document.body.style.removeProperty('top');
			if (viewportSize === 'xs' || viewportSize === 'sm') {
				window.scroll(0, -topOffset);
			}
		}, 400);
	};

	const openSubNav = (subNav: string) => {
		const viewportSize = GetViewportSize();
		if (viewportSize === 'xs' || viewportSize === 'sm') {
			setActiveSubNav(subNav);
		}
	};

	const resetMenu = (e: any) => {
		e.target.querySelector('.sub-nav-menu')
			? e.target.querySelector('.sub-nav-menu')?.classList.remove('closed')
			: e.target.nextElementSibling?.classList.remove('closed');
	};

	const closeSubNav = () => {
		if (ref) {
			const activeSubNav = ref?.current?.querySelector('.has-sub-nav.active');
			activeSubNav?.classList.add('closing');
			setTimeout(() => {
				setActiveSubNav('');
				activeSubNav?.classList.remove('closing');
			}, 400);
		}
	};

	const resize = () => {
		const viewportSize = GetViewportSize();
		if (viewportSize === 'md' || viewportSize === 'lg' || viewportSize === 'xl') {
			closeMobileNav();
		}
	};

	useEffect(() => {
		window.addEventListener('resize', resize);
	}, []);

	return (
		<header className="r2-navigation" ref={ref}>
			<div className="nav-outer-container">
				<div className="nav-container">
					<div className="navbar">
						<div className="osf-logo nav-logo">
							<a className="icon icon-osf-logo" href={fields?.homeUrl?.value?.href}>
								<Image className="icon-osf-logo" alt="osf_logo" field={fields?.logo || ''} />
							</a>
							<button className="menu-open" onClick={openMobileNav}>
								<span className="icon icon-menu">
									<Icons id="menu" />
								</span>
							</button>
						</div>
					</div>
					<FocusTrap
						active={mobileNavOpen}
						focusTrapOptions={{
							escapeDeactivates: false,
						}}
					>
						<nav
							aria-hidden={
								GetViewportSize() !== 'md' &&
								GetViewportSize() !== 'lg' &&
								GetViewportSize() !== 'xl' &&
								!mobileNavOpen
							}
							aria-expanded={
								GetViewportSize() !== 'md' &&
								GetViewportSize() !== 'lg' &&
								GetViewportSize() !== 'xl' &&
								mobileNavOpen
							}
							className={`nav-menu ${mobileNavOpen ? 'mobile-nav-open' : ''}`}
						>
							<div className="nav-header">
								<div className="osf-logo">
									<a className="icon icon-osf-logo" href={fields?.homeUrl?.value?.href}>
										<Image alt="osf_logo" field={fields?.logo || ''} />
									</a>
								</div>
								<button className="menu-close" onClick={closeMobileNav}>
									<span className="icon icon-close">
										<Icons id="close" />
									</span>
								</button>
							</div>

							<div className="nav-content">
								<div className="osf-logo">
									<a className="icon icon-osf-logo" href="">
										<Image alt="osf_logo" field={fields?.logo || ''} />
									</a>
								</div>

								<div className="nav-search">
									<R2SearchBar />
								</div>
								<div className="main-nav">
									{fields?.internalLinks &&
										fields?.internalLinks?.map(({ id, fields }, index) => {
											return (
												<div
													key={id}
													className={classNames(
														'nav-action',
														fields?.SubLink?.length ? 'has-sub-nav' : '',
														activeSubNav === id ? 'active' : ''
													)}
													onMouseEnter={(e) => resetMenu(e)}
												>
													{!fields?.SubLink?.length ? (
														<a
															className={classNames(
																'nav-action-btn',
																linkClassName[index],
																fields.BackgroundColor?.name
																	? `bg-${fields.BackgroundColor?.name}`
																	: 'bg-neutral-lightest'
															)}
															href={fields?.Link?.value?.href || '#'}
														>
															{fields?.Link?.value?.text || ''}
															<span className={classNames('icon', `${fields?.svg?.value}`)}>
																<Icons id={fields?.svg?.value || ''} />
															</span>
														</a>
													) : (
														<>
															<button
																onClick={() => {
																	openSubNav(id);
																}}
																className={classNames(
																	'nav-action-btn',
																	fields.BackgroundColor?.name
																		? `bg-${fields.BackgroundColor?.name}`
																		: 'bg-neutral-lightest'
																)}
																onFocus={(e) => resetMenu(e)}
																onBlur={(e) => resetMenu(e)}
															>
																{fields?.Link?.value?.text || ''}
																<span className="icon icon-chevron-right">
																	<Icons id={fields?.svg?.value || ''} />
																</span>
															</button>
															<FocusTrap
																active={activeSubNav === id}
																focusTrapOptions={{
																	escapeDeactivates: false,
																}}
															>
																<SubNavProvider
																	{...{
																		closeSubNav,
																		closeMobileNav,
																		data: fields?.SubLink,
																		mobileNavOpen,
																	}}
																	id={fields?.Link?.value?.text || ''}
																/>
															</FocusTrap>
														</>
													)}
												</div>
											);
										})}
								</div>
								<div className="nav-utility">
									<div className="utility-links">
										{fields?.links?.map(({ id, fields }) => {
											return (
												<a
													className="utility-link"
													href={fields?.Link?.value?.href || '#'}
													key={id}
													target={fields?.Link?.value?.target || '_self'}
												>
													{fields?.svg?.value && (
														<span className="icon icon-location">
															<Icons id={fields?.svg?.value} />
														</span>
													)}
													{fields?.Link?.value?.text || ''}
												</a>
											);
										})}
									</div>
								</div>
							</div>
						</nav>
					</FocusTrap>
				</div>
			</div>
		</header>
	);
};

export default R2Header;
