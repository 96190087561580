import { Field, Link, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import React from 'react';

type AlertProviderProps = {
	id: string;
	fields: {
		Title: Field<string>;
		Date: Field<string>;
		Type: Field<string>;
		Description: Field<string>;
		LearnMore: LinkField;
		PrimaryAction: LinkField;
		SecondaryAction: LinkField;
	};
	// eslint-disable-next-line
	setOpen: (data: any) => void;
	open: any;
};

const ClearAlert = ({ open, setOpen, ...props }: AlertProviderProps) => {
	setOpen({ ...open, [props?.id]: !open[props?.id] });
	localStorage.setItem('alertClose', JSON.stringify({ ...open, [props?.id]: !open[props?.id] }));
	const element = document.querySelector('.subnav-container');
	if (element) {
		element.classList.remove('top-banner-layout');
	}
};

const AlertProvider = ({ open, setOpen, ...props }: AlertProviderProps): JSX.Element => {
	const { Type } = props?.fields;
	return (
		<>
			{open?.[props?.id] ? (
				<div className="system-alert-banner">
					<div className="alert-container">
						<div className="alert-icon">
							{Type.value === 'default' && <Icons id="info-circle" />}
							{Type.value === 'warning' && <Icons id="warning" />}
							{Type.value === 'critical' && <Icons id="critical" />}
						</div>
						<div>
							<div className="alert-description">
								<RichText field={props?.fields?.Description} tag="span" />
							</div>
							{props?.fields?.LearnMore?.value?.href && (
								<div className="action-links">
									{props?.fields?.LearnMore.value.className === 'close' ? (
										<a
											className="action-link"
											onClick={() => ClearAlert({ open, setOpen, ...props })}
										>
											{props?.fields?.LearnMore.value.title}
										</a>
									) : (
										<Link className="action-link" field={props?.fields?.LearnMore} />
									)}
									{props?.fields?.SecondaryAction?.value?.className === 'close' ? (
										<a
											className="action-link"
											onClick={() => ClearAlert({ open, setOpen, ...props })}
										>
											{props?.fields?.SecondaryAction.value.title}
										</a>
									) : (
										props?.fields?.SecondaryAction && (
											<Link className="action-link" field={props?.fields?.SecondaryAction} />
										)
									)}
								</div>
							)}
						</div>
						{Type.value !== 'critical' && (
							<button className="btn-close" onClick={() => ClearAlert({ open, setOpen, ...props })}>
								<span className="icon icon-close">
									<Icons id="close" />
								</span>
							</button>
						)}
					</div>
				</div>
			) : null}
		</>
	);
};

export default AlertProvider;
