import {
	Text,
	RichText,
	Field,
	LinkField,
	Link,
	ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ItemValue } from 'components/types/ListItem';

type SpotlightNarrowProps = {
	fields: {
		BaseHeadline: Field<string>;
		BaseDescription: Field<string>;
		BaseAlignment: ItemValue;
		BaseColor: ItemValue;
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
		BaseImage: ImageField;
		BaseGradient: {
			value: boolean;
		};
	};
};

const SpotlightNarrow = (props: SpotlightNarrowProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	const backgroundStyle: React.CSSProperties = {};
	if (props.fields.BaseImage?.value?.src) {
		backgroundStyle.backgroundImage = `url(${props.fields.BaseImage?.value?.src})`;
	}

	return (
		<div className="overhauled">
			<div
				className={`spotlight narrow inner-vertical-spacing ${
					props.fields?.BaseImage?.value?.src
						? 'image-bg'
						: props.fields?.BaseGradient?.value == true
						? 'gradient gradient-bg-dark'
						: 'bg-secondary'
				}`}
				style={backgroundStyle && backgroundStyle}
			>
				{props.fields?.BaseImage?.value?.src && (
					<div
						className={`overlay ${
							props.fields?.BaseAlignment?.name
								? `${props.fields?.BaseAlignment?.name?.toLowerCase()}`
								: ''
						} ${
							props.fields?.BaseColor?.name ? `${props.fields?.BaseColor?.name?.toLowerCase()}` : ''
						} ${props.fields?.BaseGradient?.value ? 'gradient' : ''}`}
					></div>
				)}
				<div
					className={`jss-inner-container content-wrapper ${
						props.fields?.BaseAlignment?.name
							? `align-${props.fields?.BaseAlignment?.name?.toLowerCase()}`
							: ''
					}`}
				>
					{props.fields?.BaseGradient?.value == true && !props.fields?.BaseImage?.value?.src ? (
						props.fields?.BaseHeadline?.value && (
							<div className="text-wrapper">
								<Text tag="h3" className="headline" field={props.fields.BaseHeadline} />
								{props.fields?.BaseDescription?.value && (
									<RichText
										tag=""
										className="osf-intro-copy description"
										field={props.fields.BaseDescription}
									/>
								)}
							</div>
						)
					) : (
						<>
							{props.fields?.BaseHeadline?.value &&
								(props.fields?.BaseImage?.value?.src ? (
									<Text
										tag="h2"
										className="osf-inner-h2 headline"
										field={props.fields.BaseHeadline}
									/>
								) : (
									<Text tag="h2" className="headline" field={props.fields.BaseHeadline} />
								))}
							{props.fields?.BaseDescription?.value && (
									<RichText tag="" className="osf-intro-copy description" field={props.fields.BaseDescription} />
							)}
						</>
					)}
					{props.fields?.BaseLink?.value?.href && props.fields?.BaseLinkType?.name && (
						<Link
							field={props.fields?.BaseLink?.value}
							className={
								props.fields?.BaseLinkType
									? `btn-mobile-fw btn-${props.fields?.BaseLinkType?.name.toLowerCase()}`
									: 'btn-mobile-fw'
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SpotlightNarrow;
