import React, { useEffect, useRef } from 'react';
import { RichText, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import ScrollBox from 'lib/scrollBox';

type ContentBlockProps = StyleguideComponentProps & {
	fields?: {
		heading?: Field<string>;
		content?: Field<string>;
	};
};

const ContentBlock = (props: ContentBlockProps): JSX.Element => {
	const { fields } = props;
	const scrollBoxRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const scrollBoxModule = new ScrollBox(); // Create an instance of the ScrollBox class

		const scrollBoxElement = scrollBoxRef.current;
		if (scrollBoxElement) {
			scrollBoxModule.init(); // Call the init() method to initialize the ScrollBox module

			// Clean up when the component unmounts
			return () => {
				// Perform any necessary cleanup
			};
		}

		// Default return statement
		return;
	}, []);

	if (!fields || !fields.heading || !fields.heading) {
		return <div></div>;
	}

	return (
		<>
			<div className="text-content">
				{fields.heading && <RichText tag="h1" className="content-title" field={fields.heading} />}
				{fields.content && <RichText className="contentDescription" field={fields.content} />}
			</div>
			<div
				className="scroll-box track-nav"
				data-module="ScrollBox"
				aria-label="Scroll To Top"
				ref={scrollBoxRef}
			>
				<div>Scroll To Top</div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="21.121"
					height="20.5"
					viewBox="0 0 21.121 20.5"
					className="icon icon-arrow-up"
				>
					<g
						id="Icon_Arrow_2_Right"
						data-name="Icon Arrow 2 Right"
						transform="translate(1.061 19.75) rotate(-90)"
					>
						<path
							id="Path_497"
							data-name="Path 497"
							d="M4613.654,590.652l9.5,9.5,9.5-9.5"
							transform="translate(-581.152 4632.654) rotate(-90)"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.5"
						/>
						<path
							id="Path_498"
							data-name="Path 498"
							d="M4625.855,576.15v19"
							transform="translate(-576.15 4635.355) rotate(-90)"
							strokeLinecap="round"
							strokeWidth="1.5"
						/>
					</g>
				</svg>
			</div>
		</>
	);
};

export default ContentBlock;
