class InnovationHeader {
	constructor(Utils) {
		if (typeof window !== 'undefined') {
			const document = window.document;
			let el = document?.querySelector('[data-module="InnovationHeader"]'),
				objects = {
					anchors: el?.querySelectorAll('.nav-element'),
					body: document?.querySelector('body'),
					openMobileNav: el?.querySelector('.open-mobile-nav'),
					closeMobileNav: el?.querySelector('.close-mobile-nav'),
					subnavContainer: el?.querySelector('.subnav-container'),
					subnavElements: el?.querySelectorAll('.subnav-container .subnav-element'),
				};

			this.openMobileNav = () => {
				objects?.body?.classList?.add('osfhealthcare-page');
				objects?.body?.classList?.add('mobile-nav-open');
			};

			this.closeMobileNav = () => {
				objects?.body?.classList?.remove('mobile-nav-open');
			};

			this.hideSubnavElements = () => {
				objects?.subnavElements?.forEach((item) => {
					item && item?.classList?.remove('active');
				});
			};

			this.resetAnchors = () => {
				objects?.anchors?.forEach((item) => {
					item && item?.classList?.remove('hover');
				});
			};

			this.mouseEnterAnchor = (e) => {
				const link = e?.target,
					anchor = e?.target?.closest('.nav-element'),
					subnav = document?.querySelector(`[data-id="${link.dataset.target}"]`);

				this.hideSubnavElements();
				this.resetAnchors();

				anchor && anchor.classList.add('hover');
				objects.subnavContainer && objects.subnavContainer.classList.add('active');
				subnav && subnav.classList.add('active');
			};

			this.mouseLeaveHeader = (e) => {
				this.hideSubnavElements();
				this.resetAnchors();
				objects.subnavContainer && objects.subnavContainer.classList.remove('active');
			};

			this.firstRun = () => {
				console.log('---> InnovationHeader Module Init');

				 objects?.anchors?.forEach((item) => {
				 item && item?.addEventListener('mouseenter', this.mouseEnterAnchor);
				 });

				 el && el?.addEventListener('mouseleave', this.mouseLeaveHeader);

				 objects?.openMobileNav?.addEventListener('click', this.openMobileNav);

				 objects?.closeMobileNav?.addEventListener('click', this.closeMobileNav);
			};
		}
	}

	name() {
		return 'InnovationHeader';
	}

	init() {
		if (typeof window !== 'undefined') {
			this.firstRun();
		}
	}
}

export default InnovationHeader;
