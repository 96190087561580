import { useEffect, useState } from 'react';
import ArrowUp from '../../assets/images/icons/arrow-up.svg';

type PaginationProps = {
	numberOfVisiblePages: number;
	itemsPerPage: number;
	numberOfItems: number;
	index: number;
	// eslint-disable-next-line no-unused-vars
	setIndex: (a: number) => void;
};

const Pagination = (props: PaginationProps): JSX.Element => {
	if (!props) return <div></div>;
	const [realNumberOfPages, setRealNumberOfPages] = useState<number>(0);
	const [numberOfVisiblePages, setNumberOfVisiblePages] = useState(
		new Array(props.numberOfVisiblePages).fill(0)
	);
	const [indexStartAtPage, setIndexStartAtPage] = useState(0);

	useEffect(() => {
		setRealNumberOfPages(Math.ceil(props.numberOfItems / props.itemsPerPage));
	}, [props.numberOfItems]);

	useEffect(() => {
		setNumberOfVisiblePages(
			props.numberOfVisiblePages > realNumberOfPages
				? new Array(realNumberOfPages).fill(0)
				: new Array(props.numberOfVisiblePages).fill(0)
		);
	}, [props.numberOfVisiblePages, realNumberOfPages]);

	const handleNext = () => {
		if (props.index < realNumberOfPages) {
			props.setIndex(props.index + 1);
			if (props.index >= props.numberOfVisiblePages - 1) {
				setIndexStartAtPage(indexStartAtPage + 1);
			}
		} else {
			props.setIndex(props.index);
		}
	};
	const handlePrevious = () => {
		if (props.index !== 0) {
			props.setIndex(props.index - 1);
			if (props.index <= indexStartAtPage + 1 && indexStartAtPage !== 0) {
				setIndexStartAtPage(indexStartAtPage - 1);
			}
		} else {
			props.setIndex(props.index);
		}
	};

	return (
		<div className="pagination">
			<ul>
				<li onClick={() => props.index !== 0 && handlePrevious()}>
					<a className="pagination-btn previous">
						<ArrowUp className="icon icon-arrow-up" />
					</a>
				</li>
				<>
					{numberOfVisiblePages.map((_page, idx) => {
						return (
							<li
								className={props.index === idx + indexStartAtPage ? 'active' : ''}
								key={idx + 1 + indexStartAtPage}
								onClick={() => props.setIndex(idx)}
							>
								<a href="#">{idx + 1 + indexStartAtPage}</a>
							</li>
						);
					})}
				</>
				<>
					{realNumberOfPages > props.numberOfVisiblePages &&
						props.index != realNumberOfPages &&
						indexStartAtPage <= realNumberOfPages - props.numberOfVisiblePages && (
							<>
								<li>
									<span>...</span>
								</li>

								<li
									className={props.index === realNumberOfPages + 1 ? 'active' : ''}
									onClick={() => props.setIndex(realNumberOfPages + 1)}
								>
									<a href="#">{realNumberOfPages + 1}</a>
								</li>
							</>
						)}
				</>
				<li onClick={() => props.index + 1 !== numberOfVisiblePages?.length && handleNext()}>
					<a className="pagination-btn next">
						<ArrowUp className="icon icon-arrow-up" />
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Pagination;
