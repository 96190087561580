import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import { useFilter } from 'components/context/FilterContext';
import React, { ChangeEvent } from 'react';

type OptionProps = {
	id: string;
	fields: { Name: { value: string }; hashvalue: { value: string } };
};

type NumberOfSelectItemsProps = {
	id: string;
	fields: {
		Name: { value: string };
		SelectLabel: Field<string>;
		SelectItems: Array<OptionProps>;
		DownIcon: {
			value: string;
		};
	};
};

type ArticleListingFiltersProps = {
	fields: {
		Title: Field<string>;
		SearchTitle: Field<string>;
		SearchPlaceholder: Field<string>;
		Category?: Array<NumberOfSelectItemsProps>;
		CategoryIcon: {
			value: string;
		};
	};
	// eslint-disable-next-line
	handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	// eslint-disable-next-line
	handleInputSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};

function decodeHTMLEntities(text: string) {
	const textArea = document.createElement('textarea');
	textArea.innerHTML = text;
	return textArea.value;
}

const ArticleListingFilters = (props: ArticleListingFiltersProps): JSX.Element => {
	const { fields, handleSelectChange, handleInputSearch } = props;
	const { filterState } = useFilter();
	const dataContext = filterState.data;

	return (
		<div className="article-listing-filters">
			<div className="filter-header">
				<Text tag="h4" className="filter-title" field={fields?.Title} />
				<span className="result-count">{filterState.total} results</span>
			</div>
			<div className="article-filters row">
				<div className="filter-container col-12 col-md-7">
					<Text tag="label" field={fields?.SearchTitle} />
					<div className="input-icon-container">
						<input
							placeholder={fields?.SearchPlaceholder?.value}
							key={filterState.filter.category || 'default'}
							type="text"
							name="text"
							value={filterState.filter?.text || ''}
							id="keyword"
							onChange={handleInputSearch}
						/>
						<span className="icon icon-search">
							<Icons isColored id={fields?.CategoryIcon?.value} />
						</span>
					</div>
				</div>
				<div className="filter-container col-12 col-md-5">
					<Text tag="label" field={{ value: 'Categories' }} />
					<div className="form-select-wrapper">
						<select
							name="category"
							value={filterState.filter.category}
							onChange={(e: ChangeEvent<HTMLSelectElement>) => handleSelectChange(e)}
						>
							<option hidden>Select Category</option>
							<option value="all">All Categories</option>
							{dataContext?.categoriesList &&
								dataContext.categoriesList.map(({ id, name }) => (
									<option key={id} value={id}>
										{decodeHTMLEntities(name)}
									</option>
								))}
						</select>
						<span className="icon chevron-down">
							<Icons isColored id="chevron-down" />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArticleListingFilters;
