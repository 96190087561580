import React from 'react';
import ActionLink from 'components/atoms/ActionLink';

type AdditionalCardsProps = {
	title: string;
	description: string;
	imgUrl: string;
	isHideSecondary?: boolean;
	link: {
		text: string;
		url: string;
		target: string;
	};
};

function AdditionalCards({
	title,
	description,
	imgUrl,
	link,
	isHideSecondary,
}: AdditionalCardsProps): JSX.Element {
	return (
		<div className="additional-cards">
			{imgUrl && (
				<div className="">
					<img src={imgUrl} />
				</div>
			)}
			<div className="content">
				<h5 className="header">{title}</h5>
				<p className="description">{description}</p>
			</div>
			<div>
				<ActionLink
					id="additional_1"
					label={link.text || 'Lorem Ipsum Dolor'}
					iconClassName="icon-arrow-up"
					icon="arrow-up"
					isLinkExternal
					link={link.url || '#'}
				/>
			</div>
			{!isHideSecondary ? (
				<div>
					<ActionLink
						id="additional_2"
						label="Lorem Ipsum Dolor"
						iconClassName="icon-arrow-up"
						icon="arrow-up"
						additionalClasses="bg-green-lighter"
					/>
				</div>
			) : null}
		</div>
	);
}

export default AdditionalCards;
