import { LinkField, Link, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type FooterLinkListProps = StyleguideComponentProps & {
	fields: {
		link: LinkField;
		title: Field<string>;
		children: Array<ItemValue>;
	};
};

const FooterLinkList = (props: FooterLinkListProps): JSX.Element => {
	const { title, children } = props.fields;

	// if (!children) {
	// 	return <div className="no-datasource-set"></div>;
	// }
	if (!props || !props.fields || !props.fields.children) {
		return <div></div>;
	}
	return (
		<div className="link-list-block">
			{props.fields.title && <Text className="link-list-heading" tag="h3" field={title} />}
			{children.map((item) => (
				<Link
					className="footer-link"
					aria-label="OSF Inspire"
					data-target="inspire"
					key={item.id}
					field={item.fields.link}
				/>
			))}
		</div>
	);
};

export default FooterLinkList;
