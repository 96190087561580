import { Field, Image, ImageField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import React, { useEffect, useState } from 'react';

type ToastProps = {
	fields: {
		type: {
			value: string;
		};
		MessageSvg: ImageField;
		MessageDesc: Field<string>;
	};
};

const Toast = (props: ToastProps): JSX.Element => {
	const { fields } = props;
	const [open, setOpen] = useState(true);

	useEffect((): void => {
		if (fields?.type?.value === 'critical') {
			const timer = setTimeout(() => setOpen(false), 6000);
			return clearTimeout(timer);
		}
	}, []);

	return (
		<>
			{open ? (
				<div className="toast">
					<div className="toast-container">
						<div className="toast-inner">
							<div className="toast-icon">
								{fields?.type?.value === 'default' && (
									<span className="icon icon-info-circle" aria-hidden="true">
										<Image field={fields?.MessageSvg} />
									</span>
								)}
								{fields?.type?.value === 'warning' && (
									<span className="icon icon-warning" aria-hidden="true">
										<Image field={fields?.MessageSvg} />
									</span>
								)}
								{fields?.type?.value === 'critical' && (
									<span className="icon icon-critical" aria-hidden="true">
										<Image field={fields?.MessageSvg} />
									</span>
								)}
							</div>
							<div>
								<p className="toast-description">
									<RichText field={fields?.MessageDesc || ''} />
								</p>
							</div>
							{fields?.type?.value !== 'critical' && (
								<button
									className="btn-close"
									onClick={() => {
										setOpen(false);
										const element = document.querySelector('.get-care-container');
										if (element) {
											element.classList.add('get-care-toast');
										}
									}}
								>
									<span className="icon icon-close" aria-hidden="true">
										<Icons id="close" />
									</span>
								</button>
							)}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Toast;
