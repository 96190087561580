class ScrollBox {
	constructor() {
		let el = document.querySelector('[data-module="ScrollBox"]')

		this.firstRun = () => {
			/* keep */
			console.log('---> ScrollBox Module Init')

			el.addEventListener('click', () =>
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				})
			)
		}
	}

	name() {
		return 'ScrollBox'
	}

	init() {
		this.firstRun()
	}
}
export default ScrollBox
