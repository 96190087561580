import React from 'react';

type VideoProps = {
	videoUrl: string;
	aditionalClass?: string;
};

const Video = ({ videoUrl, aditionalClass }: VideoProps): JSX.Element => (
	<video muted autoPlay loop className={aditionalClass ? aditionalClass : ''}>
		<source className="jss-hero-video" src={videoUrl + `?t=w400`} type="video/mp4"></source>
		<source className="jss-hero-video" src={videoUrl + `?t=w800`} type="video/mp4"></source>
		<source className="jss-hero-video" src={videoUrl + `?t=w2000`} type="video/mp4"></source>
		<video className="jss-hero-video" src={videoUrl + `?t=w800`}/>
	</video>
);

export default Video;
