import React from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type PageTitleProps = {
	fields: {
		Title: Field<string>;
	};
};

const PageTitle = (props: PageTitleProps): JSX.Element => {
	const { fields } = props;

	return <Text className="heading-1" tag="h1" field={fields?.Title || ''} />;
};

export default PageTitle;
