import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type JumpSimulationBannerProps = StyleguideComponentProps & {
	fields: {
		content: Field<string>;
	};
};

const JumpSimulationBanner = (props: JumpSimulationBannerProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="simulation-banner">
			{props.fields.content && (
				<RichText tag="div" className="text jss-inner-container" field={props.fields.content} />
			)}
		</div>
	);
};

export default JumpSimulationBanner;
