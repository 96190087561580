import { LinkField, ImageField, Image } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type FooterSocialBarIconsProps = StyleguideComponentProps & {
	fields: {
		link: LinkField;
		image: ImageField;
		items: Array<ItemValue>;
	};
};

const FooterSocialBarIcons = (props: FooterSocialBarIconsProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="social-channels">
			{props.fields.items.map((item) => (
				<a
					className="social-channel-link"
					href={item.fields.link.value.href}
					target="_blank"
					rel="noopener noreferrer"
					key={item.id}
				>
					{item.fields.image && (
						<Image
							className="icon"
							field={item.fields.image}
							alt={
								(item.fields.image.value?.alt as string) != ''
									? (item.fields.image.value?.alt as string)
									: 'Image'
							}
						/>
					)}
				</a>
			))}
		</div>
	);
};

export default FooterSocialBarIcons;
