import { ImageField, Image } from '@sitecore-jss/sitecore-jss-nextjs';
import Modal from 'assets/shared/Modal';
import Icons from 'components/atoms/Icons';
import React, { useEffect, useState } from 'react';

type MediaSelectionItem = {
	id: string;
	fields: {
		image: ImageField;
		'YouTube Video ID': {
			value: string;
		};
		'YouTube Thumbnail': {
			value: {
				src: string;
			};
		};
		caption: {
			value: string;
		};
	};
};

type MediaViewerProps = {
	fields: {
		'media selection': Array<MediaSelectionItem>;
	};
};

const MediaViewer = (props: MediaViewerProps): JSX.Element => {
	const [activeMediaItem, setActiveMediaItem] = useState<MediaSelectionItem>();
	const [modalOpen, setModalOpen] = useState(false);
	const { fields } = props;

	useEffect(() => {
		setActiveMediaItem({ ...fields?.['media selection']?.[0] });
	}, []);

	console.log('fields', fields);
	return (
		<div className="overhauled">
			<div className="jss-inner-container">
				<div className="media-viewer outer-vertical-spacing">
					{modalOpen ? (
						<>
							<Modal
								customClass={`innovation-modal full-page media-modal`}
								openOnLoad={true}
								modalOnly={true}
								onCloseModal={() => {
									setModalOpen(false);
								}}
								portalId="media-modal-root"
							>
								<div className="active-media">
									{activeMediaItem?.fields?.['YouTube Video ID']?.value ? (
										<iframe
											src={`https://www.youtube.com/embed/${activeMediaItem?.fields?.['YouTube Video ID']?.value}?enablejsapi=1`}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										></iframe>
									) : (
										<Image field={{ ...activeMediaItem?.fields?.image }} />
									)}
								</div>
								{activeMediaItem?.fields?.caption ? (
									<div className="caption">{activeMediaItem?.fields?.caption?.value}</div>
								) : null}
							</Modal>
						</>
					) : null}
					{activeMediaItem && (
						<div className="active-media">
							<button
								className="media-btn"
								aria-label={`Open in pop-up viewer`}
								onClick={() => {
									setModalOpen(true);
								}}
							>
								{activeMediaItem?.fields?.['YouTube Video ID']?.value ? (
									activeMediaItem?.fields?.['YouTube Thumbnail']?.value ? (
										<>
											<Image field={activeMediaItem?.fields?.['YouTube Thumbnail']} />
											<span className="icon icon-play-filled" aria-hidden="true">
												<Icons id="icon-play-filled" />
											</span>
										</>
									) : (
										<iframe
											src={`https://www.youtube.com/embed/${activeMediaItem?.fields?.['YouTube Video ID']?.value}?enablejsapi=1`}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										></iframe>
									)
								) : (
									<Image field={{ ...activeMediaItem?.fields?.image }} />
								)}
								<span className="icon icon-expand-fs" aria-hidden="true">
									<Icons id="icon-expand-fs" />
								</span>
							</button>
						</div>
					)}
					<div className="media-btns-container">
						{fields?.['media selection'] &&
							fields?.['media selection']?.map(({ id, ...data }) => {
								return (
									<button
										key={id}
										aria-label={`Set as active media item`}
										className="media-btn"
										onClick={() => {
											setActiveMediaItem({ id, ...data });
										}}
									>
										{data?.fields?.['YouTube Video ID']?.value ? (
											data?.fields?.['YouTube Thumbnail']?.value ? (
												<>
													<Image field={{ ...data?.fields?.['YouTube Thumbnail'] }} />
													<span className="icon icon-play-filled" aria-hidden="true">
														<Icons id="icon-play-filled" />
													</span>
												</>
											) : (
												<iframe
													src={`https://www.youtube.com/embed/${data?.fields?.['YouTube Video ID']?.value}?enablejsapi=1`}
													title="YouTube video player"
													frameBorder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												></iframe>
											)
										) : (
											<Image field={{ ...data?.fields?.image }} />
										)}
									</button>
								);
							})}
					</div>
					<div id="media-modal-root"></div>
				</div>
			</div>
		</div>
	);
};

export default MediaViewer;
