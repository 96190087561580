import React from 'react';
import Pagination from 'components/r2/Pagination';

type PaginationProps = {
	numberOfVisiblePages: number;
	dataLength: number;
	itemsPerPage: number;
	numberOfItems: number;
	index: number;
	totalResult: number;
	pageSize: number;
	// eslint-disable-next-line
	setIndex: (a: number) => void;
	// eslint-disable-next-line
	setPageSize: (a: number) => void;
};

const PaginationControls = ({
	numberOfVisiblePages,
	totalResult,
	dataLength,
	itemsPerPage,
	numberOfItems,
	index,
	pageSize,
	setPageSize,
	setIndex,
}: PaginationProps): JSX.Element => {
	return (
		<div className="pagination-controls">
			<div className="limit-dropdown">
				Show
				<div className="form-select-wrapper">
					<span className="icon icon-chevron-right"></span>
					<select value={pageSize} onChange={(e: any) => setPageSize(e.target.value)}>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
			</div>
			<Pagination {...{ numberOfVisiblePages, numberOfItems, itemsPerPage, index, setIndex }} />
			<div className="results-count">
				{dataLength} of {totalResult} Results
			</div>
		</div>
	);
};

export default PaginationControls;
