import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useRef, useState } from 'react';
import ArrowUp from '../../assets/images/icons/arrow-up.svg';
import { useRouter } from 'next/router';

interface AuthorProps {
	id: string;
	fields: {
		'Author Image': {
			value: {
				src: string;
				alt: string;
			};
		};
		'Author Name': {
			value: string;
		};
		Email: {
			value: string;
		};
		hashvalue: {
			value: string;
		};
		link: LinkField;
		Phone: {
			value: string;
		};
		slug: {
			value: string;
		};
		Summary: {
			value: string;
		};
		'WordPress Id': {
			value: string;
		};
	};
}

const AuthorComponent: React.FC<AuthorProps> = ({ fields, id }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const summaryRef = useRef<HTMLDivElement>(null);
	const router = useRouter();

	const getParentPath = () => {
		const segments = router.asPath.split('?')[0].split('/');
		segments.pop();
		return segments.join('/');
	};
	const handleViewAllPosts = (e: React.MouseEvent) => {
		e.preventDefault();
		const parentPath = getParentPath();
		router.push(`${parentPath}?author=${id.replace(/-/g, '').toUpperCase()}}`);
	};

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className="author-component">
			<div className="author-container">
				<div className="author-image">
					{fields && Object.keys(fields['Author Image'].value).length > 0 && (
						<img src={fields['Author Image']?.value?.src} alt={fields['Author Name']?.value} />
					)}
				</div>
				<div className="author-details">
					<div className="author-info">
						{fields && fields['Author Name'] && (
							<h2 className="name">{fields['Author Name']?.value}</h2>
						)}
						<div ref={summaryRef} className={`summary ${isExpanded ? 'expanded' : 'collapsed'}`}>
							<p
								className="content-paragraph"
								dangerouslySetInnerHTML={{ __html: fields ? fields?.Summary?.value : '' }}
							/>
						</div>
						{fields && fields?.Summary?.value && (
							<button onClick={toggleExpand}>See {isExpanded ? `less` : `more`}</button>
						)}

						{fields && fields?.Email?.value && (
							<a className="email" href={`mailto:${fields.Email.value}`}>
								{fields.Email.value}
							</a>
						)}
						{fields && fields?.Phone?.value && <p className="phone">{fields.Phone.value}</p>}
					</div>
				</div>
			</div>
			<a
				href=""
				target="_blank"
				rel="noreferrer"
				className="action-btn"
				onClick={handleViewAllPosts}
			>
				<div>
					{fields && fields['Author Name'] && (
						<span className="action-text">View all posts by {fields['Author Name']?.value}</span>
					)}
				</div>
				<ArrowUp className="icon icon-arrow-up section-icon" />
			</a>
		</div>
	);
};

export default AuthorComponent;
