import {
	Text,
	RichText,
	Field,
	LinkField,
	Link,
	ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Image from '../atoms/Image';

type PhotoFeatureProps = {
	fields: {
		BaseHeadline: Field<string>;
		BaseDescription: Field<string>;
		BaseImageOne: ImageField;
		BaseLinkOne: LinkField;
		BaseImageTwo: ImageField;
		BaseLinkTwo: LinkField;
	};
};

const PhotoFeature = (props: PhotoFeatureProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="overhauled">
			<div className="photo-feature bg-primary inner-vertical-spacing">
				<div className="jss-inner-container">
					<div className="content-wrapper">
						{props.fields?.BaseHeadline?.value && (
							<Text tag="h2" className="headline" field={props.fields.BaseHeadline} />
						)}
						{props.fields?.BaseDescription?.value && (
							<RichText className="osf-body description" field={props.fields.BaseDescription} />
						)}
						{(props.fields?.BaseImageOne?.value?.src || props.fields?.BaseImageTwo?.value?.src) && (
							<div className="images-row">
								<div className="feature">
									{props.fields.BaseImageOne?.value?.src && props.fields?.BaseLinkOne?.value?.href && (
										<Link field={props.fields?.BaseLinkOne?.value}>
											<div className="img-container">
												<Image
													imageUrl={props.fields.BaseImageOne?.value?.src as string}
													alt={
														(props.fields.BaseImageOne?.value?.alt as string) != ''
															? (props.fields.BaseImageOne?.value?.alt as string)
															: 'Image'
													}
												/>
											</div>
										</Link>
									)}
									{props.fields?.BaseLinkOne?.value?.href && (
										<Link field={props.fields?.BaseLinkOne?.value} className="btn-tertiary" />
									)}
								</div>
								<div className="feature">
									{props.fields.BaseImageTwo?.value?.src && props.fields?.BaseLinkTwo?.value?.href && (
										<Link field={props.fields?.BaseLinkTwo?.value}>
											<div className="img-container">
												<Image
													imageUrl={props.fields.BaseImageTwo?.value?.src as string}
													alt={
														(props.fields.BaseImageTwo?.value?.alt as string) != ''
															? (props.fields.BaseImageTwo?.value?.alt as string)
															: 'Image'
													}
												/>
											</div>
										</Link>
									)}
									{props.fields?.BaseLinkTwo?.value?.href && (
										<Link field={props.fields?.BaseLinkTwo?.value} className="btn-tertiary" />
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PhotoFeature;
