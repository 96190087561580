const breakpoints = {
	xs: 0,
	sm: 375,
	md: 768,
	lg: 1280,
	xl: 1440,
	xxl: 1680,
};

export default (): string => {
	if (typeof window !== 'undefined') {
		const width = window.innerWidth;
		if (width < breakpoints.sm) {
			return 'xs';
		} else if (width < breakpoints.md) {
			return 'sm';
		} else if (width < breakpoints.lg) {
			return 'md';
		} else if (width < breakpoints.xl) {
			return 'lg';
		} else {
			return 'xl';
		}
	} else return '';
};
