import React from 'react';
import { classNames } from 'assets/utils/helper';

type ButtonProps = {
	label?: string;
	btnClass?: string;
	field: any;
};

const CustomButton = ({ btnClass, label, field, ...props }: ButtonProps): JSX.Element => {
	const { model } = field;
	return (
		<button {...props} className={classNames('innovation-button', btnClass || '')}>
			{label || model?.title || ''}
		</button>
	);
};

export default CustomButton;
