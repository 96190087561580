import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import GetBreakpoint from '../utils/getBreakPoint';
import Icons from '../../components/atoms/Icons';
import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type ModelProps = {
	ariaLabel?: string;
	children?: ReactNode;
	customClass?: string;
	hideDefaultCloseBtn?: boolean;
	modalOnly?: boolean; // boolean for modal with no associated open button (ie alerts or auto-open modals)
	modalTitle?: string;
	openBtnIcon?: string;
	openBtnLabel?: string;
	role?: string;
	openOnLoad?: boolean;
	onCloseModal?: () => void;
	onOpenModal?: () => void;
	portalId: string;
	shouldCloseOnOverlayClick?: boolean;
	BaseHeadline?: Field<string>;
	BaseHeading?: Field<string>;
	BaseDescription?: Field<string>;
	BaseLink?: LinkField;
};

type ModelState = {
	isOpen: boolean;
	isVisible: boolean;
};

class Modal extends React.Component<ModelProps, ModelState> {
	modal: HTMLDivElement | null | undefined;

	constructor(props: ModelProps) {
		super(props);
		this.state = {
			isOpen: false,
			isVisible: false,
		};
	}

	static defaultProps = {
		portalId: 'modal-root',
		openBtnLabel: 'Open modal',
		shouldCloseOnOverlayClick: true,
	};

	open = (): void => {
		this.setState({ isVisible: true, isOpen: true });
		document.body.classList.add('body-fixed');
		document.body.classList.add('custom-modal-open');
		if (this.props.onOpenModal) this.props.onOpenModal();
	};

	close = (): void => {
		document.body.classList.remove('custom-modal-open');
		document.body.classList.remove('body-fixed');
		this.setState({ isOpen: false }, () => {
			setTimeout(() => this.setState({ isVisible: false }), 300);
			if (this.props.onCloseModal) this.props.onCloseModal();
		});
	};

	closeOverlayOnClick = (e: React.MouseEvent<HTMLDivElement>): void => {
		if (
			this.props.shouldCloseOnOverlayClick &&
			(GetBreakpoint() === 'lg' || GetBreakpoint() === 'xl' || GetBreakpoint() === 'xxl') &&
			(e.target as HTMLDivElement).classList.contains('modal-container')
		) {
			this.close();
		}
	};

	componentDidMount = (): void => {
		if (this.props.openOnLoad) this.open();
	};

	render(): JSX.Element {
		const { customClass, hideDefaultCloseBtn, modalOnly, openBtnIcon, openBtnLabel } = this.props;

		return (
			<>
				{!modalOnly && (
					<button className="modal-open-btn" onClick={this.open}>
						{openBtnLabel ? openBtnLabel : null}
						{openBtnIcon ? (
							<span className={`icon icon-${openBtnIcon}`}>
								<Icons id={`icon-${openBtnIcon}`} />
							</span>
						) : null}
					</button>
				)}

				{this.state.isVisible &&
					ReactDOM.createPortal(
						<div className="overhauled">
							<FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
								<div
									className={`modal osf-modal ${customClass || ''} ${
										this.state.isVisible ? ' visible' : ''
									} ${this.state.isOpen ? ' open' : ''}`}
									tabIndex={-1}
									aria-modal="true"
									aria-label={this.props.ariaLabel}
									role={this.props.role}
									onClick={this.closeOverlayOnClick}
								>
									<div className="modal-container jss-inner-container">
										<div className="modal-inner-container">
											<div className="modal-body">
												<div className="modal-inner inner-side-spacing">
													<ModalContent {...this.props} />
													{!hideDefaultCloseBtn && (
														<button
															className="modal-close-btn"
															type="button"
															onClick={this.close}
															aria-label="Close Modal"
														>
															<span className="icon icon-close">
																<Icons id="close" />
															</span>
														</button>
													)}
													{this.props.children}
												</div>
											</div>
										</div>
									</div>
								</div>
							</FocusTrap>
						</div>,

						document.getElementById(this.props.portalId) as HTMLElement
					)}
			</>
		);
	}
}

const ModalContent = (props: ModelProps): JSX.Element => {
	const { BaseHeadline, BaseHeading, BaseDescription, BaseLink } = props;

	return (
		<div className="modal-content">
			{BaseHeadline?.value && <h2>{BaseHeadline?.value}</h2>}
			{BaseHeading?.value && <h3 className="modal-content osf-inner-h3">{BaseHeading?.value}</h3>}

			<RichText tag="div" field={BaseDescription} className="body" />

			{BaseLink?.value && (
				<a href={BaseLink.value.href} className="btn-tertiary">
					{BaseLink.value.text || BaseLink.value.href}
				</a>
			)}
		</div>
	);
};

export default Modal;
