import React from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type PullQuoteProps = {
	fields: {
		QuoteDescription: Field<string>;
	};
};
const PullQuote = (props: PullQuoteProps): JSX.Element => {
	const { fields } = props;
	return (
		<div className="overhauled">
			<div className="jss-inner-container">
				<div className="pull-quote bg-color-base shadow">
					<div className="full-inner-spacing">
						<RichText className="quote" tag="h2" field={fields?.QuoteDescription} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PullQuote;
