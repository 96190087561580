import { Field, LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type SearchProps = StyleguideComponentProps & {
	fields: {
		inputtext: Field<string>;
		redirectionlink: LinkField;
	};
};

const Search = (props: SearchProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div>
			<p>Search Component</p>

			<div className="input-group">
				<input
					className="form-control border-end-0 border rounded-pill"
					type="search"
					placeholder={props.fields.inputtext.value}
					id="example-search-input"
				></input>
				{props.fields.redirectionlink && <Link field={props.fields.redirectionlink} />}
			</div>
		</div>
	);
};

export default Search;
