import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-nextjs';

type SplitContentCalloutProps = {
	fields: {
		BaseHeadline: Field<string>;
		BaseLeftContent: Field<string>;
		BaseRightContent: Field<string>;
	};
};

const SplitContentCallout = (props: SplitContentCalloutProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="overhauled">
			<div className="split-content-callout outer-vertical-spacing">
				<div className="jss-inner-container">
					<div className="content-wrapper bg-primary">
						<div className="border-accent"></div>
						<div className="inner-content inner-side-spacing inner-vertical-spacing">
							<div className="text-content">
								{props.fields?.BaseLeftContent?.value && (
									<div className="left-content">
										{props.fields?.BaseHeadline?.value && (
											<Text
												tag="h2"
												className="osf-inner-h2 headline"
												field={props.fields?.BaseHeadline}
											/>
										)}
										<RichText
											tag="div"
											className="osf-body left-description"
											field={props.fields?.BaseLeftContent}
										/>
									</div>
								)}
								{props.fields?.BaseRightContent?.value && (
									<RichText
										tag="div"
										className="osf-body right-content"
										field={props.fields?.BaseRightContent}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SplitContentCallout;
