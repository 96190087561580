import { Field, Image, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type PhoneNumberItem = {
	id: string;
	fields: {
		phoneNumber: Field<string>;
	};
};

type EmailSelectionItem = {
	id: string;
	fields: {
		emailId: Field<string>;
	};
};

type ContactCardProps = {
	fields: {
		image: ImageField;
		name: Field<string>;
		position: Field<string>;
		phoneNumberSelection: Array<PhoneNumberItem>;
		emailSelection: Array<EmailSelectionItem>;
	};
};

const ContactCard = (props: ContactCardProps): JSX.Element => {
	const { fields } = props;
	return (
		<div className="contact-card">
			<div className="contact-img">
				<Image field={fields?.image} />
			</div>
			<div className="contact-info">
				<div className="name-and-title">
					<Text tag="h4" className="contact-name" field={fields?.name} />
					<Text tag="span" field={fields?.position} className="contact-title" />
				</div>
				<div className="contact-methods">
					{fields?.emailSelection?.map(({ id, ...data }) => (
						<a key={id} className="contact-email" href={`mailto:${data?.fields?.emailId?.value}`}>
							{data?.fields?.emailId?.value}
						</a>
					))}
					{fields?.phoneNumberSelection?.map(({ id, ...data }) => (
						<a key={id} className="contact-phone" href={`tel:${data?.fields?.phoneNumber?.value}`}>
							{data?.fields?.phoneNumber?.value}
						</a>
					))}
				</div>
			</div>
		</div>
	);
};

export default ContactCard;
