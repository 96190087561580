import { Field, Image, ImageField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import ActionButton from 'components/r2/ActionButton';
import React from 'react';

type actionButtonItem = {
	id: string;
	label: Field<string>;
	icon: Field<string>;
	paragraph: Field<string>;
	link: LinkField;
};

type EventListItemProps = {
	image: ImageField;
	date?: Field<string>;
	title: Field<string>;
	description?: Field<string>;
	time?: Field<string>;
	type?: Field<string>;
	actionButton: Array<actionButtonItem>;
};

const EventListItem = ({
	image,
	date,
	title,
	description,
	time,
	type,
	actionButton,
}: EventListItemProps): JSX.Element => {
	return (
		<div className="event-listing">
			<div className="event-img">
				<Image field={image} />
			</div>
			<div className="event-content">
				<Text tag="span" className="event-date" field={date} />
				<Text tag="h1" field={title} />
				<Text tag="p" field={description} />
				<div className="event-details">
					<Text className="event-time" tag="span" field={time} />
					<Text className="event-type" tag="span" field={type} />
				</div>
			</div>
			<div className="learn-or-register">
				{actionButton?.map(({ id, label, paragraph, link, icon }) => {
					return (
						<ActionButton
							key={id}
							iconClassName="icon-arrow-up"
							label={label?.value}
							paragraph={paragraph?.value}
							link={link?.value?.href}
							icon={icon?.value}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default EventListItem;
