import React from 'react';
import FormInput from 'components/molecules/FormInput';
import FormSelect from 'components/molecules/FormSelect';
import FormTextArea from 'components/molecules/FormTextArea';

type FormFieldProviderProps = {
	type: string;
	label: string;
	name: string;
	value: string;
	isRequired?: boolean;
	// eslint-disable-next-line
	handleChange: (e: any) => void;
};

const FormFieldProvider: React.FC<FormFieldProviderProps> = ({
	type,
	label,
	name,
	value,
	handleChange,
	...props
}) => {
	switch (type) {
		case 'text':
			return (
				<FormInput
					{...props}
					{...{
						type,
						name,
						className: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
						label,
						value,
						handleChange,
					}}
				/>
			);
		case 'select':
			return (
				<FormSelect
					{...props}
					{...{
						className: '',
						name,
						label,
						value,
						handleChange,
					}}
				/>
			);
		case 'textarea':
			return (
				<FormTextArea
					{...props}
					{...{
						className: '',
						label,
						name,
						value,
						handleChange,
					}}
				/>
			);
		default:
			return <></>;
	}
};

export default FormFieldProvider;
