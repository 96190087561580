import { gql } from '@apollo/client';

export const SEARCH_QUERY = gql`
	query Search($text: String!) {
		typeaHead(text: $text) {
			viewAllCount
			suggestedWord
			viewAllUrl
			resultSets {
				resultCategory
				results {
					resultTitle
					resultUrl
				}
			}
		}
	}
`;

export const GLOBAL_SEARCH_QUERY = gql`
	query Search(
		$text: String!
		$page: Int!
		$pageSize: Int!
		$filters: CategoryType
		$sortType: SortType
	) {
		globalSearch(
			text: $text
			page: $page
			pageSize: $pageSize
			sortType: $sortType
			filters: $filters
		) {
			totalPages
			count {
				blogCount
				eventCount
				healthLibraryCount
				viewAllCount
			}
			page
			pageSize
			results {
				date
				description
				link
				title
			}
		}
	}
`;

export const PROVIDERS_SEARCH = gql`
	query Providers($text: String!) {
		providers(text: $text) {
			totalSearchResults
			results {
				provider {
					id
					imageUrl
					url
					fullName
					specialties
					rating
				}
			}
		}
	}
`;

export const LOCATIONS_SEARCH = gql`
	query Locations($text: String!) {
		locations(text: $text) {
			results {
				location {
					id
					imageUrl
					url
					name
					street1
					street2
					city
					country
					zip
				}
			}
			totalSearchResults
		}
	}
`;

export const FIND_HOSPITALS_NEAR_YOU = gql`
	query GetLocation($lat: Float!, $long: Float!) {
		locations(origin: { latitude: $lat, longitude: $long }) {
			results {
				location {
					id
					imageUrl
					url
					name
					street1
					street2
					city
					country
					zip
				}
			}
			totalSearchResults
		}
	}
`;
