import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import Icons from 'components/atoms/Icons';

type SocialMediaLinksProps = {
	fields: {
		SocialMediaLinks: Array<SocialMediaLinksContent>;
		Title: Field<string>;
	};
};

type SocialMediaLinksContent = {
	fields: {
		BaseLink: {
			value: {
				href: string;
				url: string;
				text: string;
			};
		};
		BaseSvg: {
			value: string;
		};
	};
};

const SocialMediaLinks = (props: SocialMediaLinksProps): JSX.Element => {
	if (props == undefined) {
		return <div>Data source is not defined</div>;
	}

	const { fields } = props;
	return (
		<div className="overhauled">
			<div className="gradient-bg-dark">
				<div className="jss-inner-container inner-vertical-spacing">
					<div className="social-media-links">
						<Text className="social-description" tag="h3" field={fields?.Title} />
						<div className="social-links">
							{fields?.SocialMediaLinks?.map(({ fields }, index) => (
								<a
									key={index}
									className="social-link"
									target="_blank"
									rel="noreferrer"
									href={fields?.BaseLink?.value?.href || '#'}
								>
									<span className={`icon icon-${fields?.BaseSvg?.value}`}>
										<Icons id={fields?.BaseSvg?.value} />
									</span>
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SocialMediaLinks;
