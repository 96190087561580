import Video from 'lib/videoPlay';
import React, { useEffect, useRef } from 'react';

interface Thumbnail {
	src: string;
	alt: string;
}

interface YouTubeVideoProps {
	fields: {
		thumbnail?: { value: Thumbnail };
		source: { value: string };
	};
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = (props) => {
	const videoRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const videoModule = new Video(); // Create an instance of the Video class

		const videoElement = videoRef.current;
		if (videoElement) {
			videoModule.init(); // Call the init() method to initialize the Video module

			// Clean up when the component unmounts
			return () => {
				// Perform any necessary cleanup
			};
		}

		// Default return statement
		return;
	}, []);

	return (
		<div className="col-12 video-content-wrapper" data-module="Video" ref={videoRef}>
			{props.fields.thumbnail && (
				<img
					className="video-thumbnail"
					src={props.fields.thumbnail.value.src}
					alt={props.fields.thumbnail.value.alt}
				/>
			)}
			<iframe
				src={props.fields.source.value}
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	);
};

export default YouTubeVideo;
