import React from 'react';

type ContentSeparatorProps = {
	fields: {
		BaseBlankSpace: {
			value: boolean;
		};
	};
};

const ContentSeparator = (props: ContentSeparatorProps): JSX.Element => {
	const { fields } = props;

	return (
		<div className="overhauled">
			<div className="jss-inner-container">
				<div className={fields?.BaseBlankSpace?.value ? 'separator-blank' : 'separator'}></div>
			</div>
		</div>
	);
};

export default ContentSeparator;
