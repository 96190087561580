import { Field, ImageField, LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';
import Modal from 'assets/shared/Modal';

type OneUpChild = {
	id: string;
	fields: {
		BaseImage: ImageField;
		BaseVideoUrl: Field<string>;
		BaseImageRight: {
			value: boolean;
		};
		BaseHeadline: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
	};
};

type OneUpParentProps = StyleguideComponentProps & {
	fields: {
		children: Array<OneUpChild>;
	};
};

const OneUp = (props: OneUpParentProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="overhauled">
			<div className="jss-inner-container jss-oneup-container">
				{props.fields.children &&
					props.fields.children.map((item) => (
						<div
							className={`one-up bg-secondary outer-vertical-spacing ${
								item.fields?.BaseImageRight?.value ? 'img-right' : ''
							}`}
							key={item.id}
						>
							{item.fields?.BaseImage?.value?.src && (
								<div className="one-up-img">
									<img
										src={item.fields?.BaseImage?.value?.src}
										alt={
											(item.fields?.BaseImage?.value?.alt as string) != ''
												? (item.fields?.BaseImage?.value?.alt as string)
												: 'Image'
										}
									/>

									{item.fields?.BaseVideoUrl?.value && (
										<>
											<div id="modal-root"></div>
											<Modal
												portalId="modal-root"
												openBtnIcon="play-filled"
												openBtnLabel=""
												customClass="video-modal"
											>
												<iframe
													src={item.fields?.BaseVideoUrl?.value}
													title="YouTube video player"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												></iframe>
											</Modal>
										</>
									)}
								</div>
							)}
							<div className="one-up-content">
								{item.fields?.BaseHeadline?.value && (
									<Text tag="h3" className="osf-inner-h3" field={item.fields?.BaseHeadline} />
								)}
								{item.fields?.BaseDescription?.value && (
									<RichText tag="" className="osf-body" field={item.fields?.BaseDescription} />
								)}
								{item.fields?.BaseLink && item.fields?.BaseLinkType?.name && (
									<a
										href={item.fields?.BaseLink?.value?.href}
										className={
											item.fields?.BaseLinkType
												? `btn-${item.fields?.BaseLinkType?.name.toLowerCase()}`
												: ''
										}
										target={
											item.fields?.BaseLink?.value?.target != null
												? item.fields?.BaseLink?.value?.target
												: item.fields?.BaseLink?.value?.linktype == 'internal'
												? '_self'
												: '_blank'
										}
									>
										{item.fields?.BaseLink?.value?.text}
									</a>
								)}
							</div>
						</div>
					))}
				{!props.fields.children && <p>No datasource set</p>}
			</div>
		</div>
	);
};

export default OneUp;
