import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-nextjs';

type IntroParagraphProps = {
	fields: {
		BaseHeadline: Field<string>;
		BaseLeftContent: Field<string>;
		BaseRightContent: Field<string>;
	};
};

const IntroParagraph = (props: IntroParagraphProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="overhauled">
			<div className="intro-paragraph">
				<div className="bg-primary inner-vertical-spacing">
					<div className="content-wrapper jss-inner-container">
						<div className="inner-content">
							{props.fields?.BaseHeadline?.value && (
								<Text tag="h2" className="headline" field={props.fields?.BaseHeadline} />
							)}
							<div className="text-content">
								{props.fields?.BaseLeftContent?.value && (
									<RichText
										tag=""
										className="osf-overview left-content"
										field={props.fields?.BaseLeftContent}
									/>
								)}
								<div className="divider"></div>
								{props.fields?.BaseRightContent?.value && (
									<RichText
										tag=""
										className="right-content osf-body"
										field={props.fields.BaseRightContent}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntroParagraph;
