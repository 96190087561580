import React, { MouseEventHandler } from 'react';
import { func } from 'prop-types';

type ChevronIconProps = {
	stroke: string;
};

const ChevronIcon = ({ stroke }: ChevronIconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="8.828" height="14.829" viewBox="0 0 8.828 14.829">
		<g id="Icon_Arrow_Right" data-name="Icon Arrow Right" transform="translate(1.414 1.414)">
			<path
				id="Path_747"
				data-name="Path 747"
				d="M4918.513-354.946l6,6-6,6"
				transform="translate(-4918.513 354.946)"
				fill="none"
				stroke={stroke}
				strokeLinecap="round"
				strokeWidth="2"
			/>
		</g>
	</svg>
);

type ContentCarouselControlsProps = {
	slideLeft: MouseEventHandler<HTMLDivElement>;
	slideRight: MouseEventHandler<HTMLDivElement>;
};

const ContentCarouselControls = ({
	slideLeft,
	slideRight,
}: ContentCarouselControlsProps): JSX.Element => {
	return (
		<>
			<div className="control">
				<div className="control-previous" onClick={slideLeft}>
					<ChevronIcon stroke="#4E8209" />
				</div>
				<div className="control-next" onClick={slideRight}>
					<ChevronIcon stroke="#4E8209" />
				</div>
				<div className="divider"></div>
			</div>
		</>
	);
};

ContentCarouselControls.propTypes = {
	slideLeft: func,
	slideRight: func,
};

export default ContentCarouselControls;
