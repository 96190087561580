import React, { useEffect, useState } from 'react';
import Icons from 'components/atoms/Icons';
import { classNames } from 'assets/utils/helper';
import { useRouter } from 'next/router';
import PaginationControls from 'components/molecules/PaginationControls';
import { useQuery } from '@apollo/client';
import {
	GLOBAL_SEARCH_QUERY,
	LOCATIONS_SEARCH,
	PROVIDERS_SEARCH,
} from 'src/apollo/queries/search-result';

type SortingItem = {
	id: string;
	fields: {
		HasValue: {
			value: string;
		};
		Title: {
			value: string;
		};
	};
};

type SearchProps = {
	fields: {
		name: { value: string };
		placeholder: { value: string };
		svg: { value: string };
		'Sorting types': Array<SortingItem>;
	};
};

type SearchType = {
	activeKey: string;
	// eslint-disable-next-line
	text: string | any;
};

const tabs = [
	{
		label: 'All Results',
		key: 'all_results',
		value: 'viewAllCount',
		query: 'ALL_RESULT',
	},
	{
		label: 'Blogs',
		key: 'blogs',
		value: 'blogCount',
		query: 'BLOGS',
	},
	{
		label: 'Events',
		key: 'events',
		value: 'eventCount',
		query: 'EVENTS',
	},
	{
		label: 'Health Library',
		key: 'health_library',
		value: 'healthLibraryCount',
		query: 'HEALTH_LIBRARY',
	},
];

const SearchData = (props: SearchProps): JSX.Element => {
	const { fields } = props;
	const { query } = useRouter();
	const [index, setIndex] = useState(0);
	const [countType, setCountType] = useState('viewAllCount');
	const [pageSize, setPageSize] = useState(10);
	const [filterData, setFIlterData] = useState({
		sortType: 'MOST_RELEVANT',
		filters: 'ALL_RESULT',
	});
	const [searchData, setSearchData] = useState<SearchType>({
		text: '',
		activeKey: 'all_results',
	});

	const { data } = useQuery(GLOBAL_SEARCH_QUERY, {
		variables: {
			text: searchData.text,
			page: index + 1,
			pageSize,
			sortType: filterData?.sortType,
			filters: filterData?.filters,
		},
		context: { clientName: 'searchByISC' },
	});

	const { data: locationData, loading: locationLoading } = useQuery(LOCATIONS_SEARCH, {
		variables: {
			text: searchData.text,
		},
		context: { clientName: 'searchByItemId' },
	});

	const { data: providerData, loading: providerLoading } = useQuery(PROVIDERS_SEARCH, {
		variables: {
			text: searchData.text,
		},
		context: { clientName: 'searchByItemId' },
	});

	useEffect(() => {
		if (query) {
			setSearchData({ ...searchData, text: query?.query || '' });
		}
	}, [query]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setSearchData({
			...searchData,
			[name]: value,
		});
	};

	const highlightText = (text: string, highlight: string) => {
		// Split on highlight term and include term into parts, ignore case
		const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
		return parts.map((part, i: number) =>
			part.toLowerCase() === highlight.toLowerCase() ? (
				<mark key={i}>{part}</mark>
			) : (
				<React.Fragment key={i}>{part}</React.Fragment>
			)
		);
	};

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.keyCode === 38 || e.charCode === 38) {
			e.preventDefault();
		}
	};

	const handleRedirect = (link: any) => {
		window.location.href = `${link?.url}`;
	};

	const handleActiveTab = (id: string, query: string, type: string) => {
		setSearchData({ ...searchData, activeKey: id });
		setIndex(0);
		setFIlterData({ ...filterData, filters: query });
		setCountType(type);
	};

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value, name } = e.target;
		setFIlterData({ ...filterData, [name]: value });
	};

	return (
		<div className="query-search-page">
			<div className="query-search-page-container">
				<div className="query-page-row">
					<div className="search-listing-groups">
						<div className="r2-search-bar">
							<div className="search-input-container">
								<input
									className="search-input"
									type="text"
									value={searchData.text}
									onChange={handleInputChange}
									onKeyDown={onKeyDown}
									name="text"
									placeholder={fields?.placeholder?.value || 'Search keywords'}
								/>
								<span className="icon icon-desktop icon-search">
									<Icons id={fields?.svg?.value || 'icon-search'} />
								</span>
							</div>
						</div>
						<div className="mobile-provider-location-links">
							<a className="providers-link" href="https://www2.osfhealthcare.org/Providers">
								Providers ({providerData?.providers?.totalSearchResults || 0})
							</a>
							<a className="locations-link" href="https://www2.osfhealthcare.org/Locations">
								Locations ({locationData?.locations?.totalSearchResults || 0})
							</a>
						</div>
						<div className="query-filter-lists">
							<ul className="query-filter-list">
								{tabs?.map(({ label, key, value, query }) => (
									<li
										key={key}
										className={classNames(
											'filter-item',
											searchData.activeKey === key ? 'active' : ''
										)}
										onClick={() => handleActiveTab(key, query, value)}
									>
										{label}
										{key !== 'all_results' ? `(${data?.globalSearch?.count[value] || '0'})` : ''}
									</li>
								))}
							</ul>
						</div>
						<div className="search-result-container">
							<span className="search-result-text">
								{data?.globalSearch?.count[countType] || 0} RESULTS FOR{' '}
								{searchData.text ? <span>{`"${searchData.text}"`}</span> : null}
							</span>
							<div className="filter-dropdown form-select-wrapper">
								<select
									name="sortType"
									defaultValue={'MostRelevant'}
									value={filterData?.sortType}
									onChange={handleChange}
								>
									{fields?.['Sorting types']
										?.filter((data) => {
											if (data.id === 'b548870c-9e52-48d8-9a07-30b0f50996c7') {
												return searchData.activeKey === 'events' ? { ...data } : null;
											} else {
												return { ...data };
											}
										})
										?.map(({ id, fields }) => (
											<option key={id} value={fields?.HasValue?.value || ''}>
												{fields?.Title?.value || ''}
											</option>
										))}
								</select>
								<span className="icon icon-chevron-right">
									<Icons id="icon-chevron-right" />
								</span>
							</div>
						</div>
						<div className="alert-listing-groups">
							<div className="alert-listing-group">
								{data?.globalSearch?.results?.length ? (
									<>
										{data?.globalSearch?.results?.map((data: any, index: number) => (
											<div
												onClick={() => handleRedirect(data?.link)}
												className="alert-listing"
												key={index}
											>
												<div className="alert-type-date">
													<span className="alert-type">
														{data?.title?.toLowerCase()?.includes(searchData?.text?.toLowerCase())
															? highlightText(data?.title, searchData?.text)
															: data?.title || ''}
													</span>
												</div>
												<p className="alert-content">
													{data?.description
														?.toLowerCase()
														?.includes(searchData?.text?.toLowerCase())
														? highlightText(data?.description, searchData?.text)
														: data?.description || ''}
												</p>
											</div>
										))}
									</>
								) : (
									<>No results.</>
								)}
							</div>
						</div>
						<PaginationControls
							{...{
								itemsPerPage: 1,
								numberOfVisiblePages: data?.globalSearch?.totalPages || 1,
								numberOfItems: data?.globalSearch?.totalPages || 1,
								index,
								dataLength: index * pageSize + data?.globalSearch?.results?.length || 0,
								totalResult:
									countType === 'viewAllCount'
										? data?.globalSearch?.count?.viewAllCount || 0
										: data?.globalSearch?.count[countType] || 0,
								pageSize,
								setPageSize,
								setIndex,
							}}
						/>
					</div>
					{searchData.activeKey === 'all_results' && (
						<div className="provider-location-container">
							<div className="r2-search-bar">
								<div className="provider-location-results">
									<div className="search-category-set">
										<h4 className="search-category">Providers:</h4>
										{providerLoading ? (
											<div className="search-category-set">
												<>Loading...</>
											</div>
										) : (
											<div className="featured-list">
												{!providerData?.providers?.results?.length ? (
													<>No results.</>
												) : (
													<div className="list-inner">
														{providerData?.providers?.results
															?.slice(0, 2)
															?.map(({ provider }: any) => (
																<a
																	href={`https://www2.osfhealthcare.org${provider.url}`}
																	key={provider.id}
																	className="featured-item"
																>
																	<div className="item-image">
																		<img
																			src={
																				provider.imageUrl ||
																				'https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-female-provider-transparent'
																			}
																			alt={provider.id}
																		/>
																	</div>
																	<div
																		className="content-container"
																		itemScope
																		itemType="http://schema.org/Physician"
																	>
																		<h4 className="item-name" itemProp="name">
																			{provider.fullName}
																		</h4>
																		<h5
																			className="item-subtitle"
																			itemProp="medicalSpecialty"
																			itemScope={false}
																			itemType="https://schema.org/medicalSpecialty"
																		>
																			{provider.specialty?.toLocaleString()}
																		</h5>
																	</div>
																</a>
															))}
														<a
															className="view-all-link"
															href="https://www2.osfhealthcare.org/Providers"
														>
															All Matching Providers (
															{providerData?.providers?.totalSearchResults || 0})
														</a>
													</div>
												)}
											</div>
										)}
									</div>
									<div className="search-category-set">
										<h4 className="search-category">Locations:</h4>
										{locationLoading ? (
											<div className="search-category-set">
												<>Loading...</>
											</div>
										) : (
											<div className="featured-list">
												{!locationData?.locations?.results?.length ? (
													<>No results.</>
												) : (
													<div className="list-inner">
														{locationData?.locations?.results
															?.slice(0, 2)
															?.map(({ location }: any) => (
																<a
																	href={`https://www2.osfhealthcare.org${location.url}`}
																	key={location?.id}
																	className="featured-item"
																>
																	<div className="item-image">
																		<img
																			src={
																				location.imageUrl ||
																				'https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-location-transparent'
																			}
																			alt={location.id}
																		/>
																	</div>
																	<div className="content-container">
																		<div
																			className="address"
																			itemProp="hospitalAffiliation"
																			itemScope={false}
																			itemType="http://schema.org/Hospital"
																		>
																			<h4 className="item-name" itemProp="name">
																				{location.name}
																			</h4>
																			<h5
																				className="item-subtitle"
																				itemProp="address"
																				itemScope
																				itemType="http://schema.org/PostalAddress"
																			>
																				{location.street1 && <span>{location.street1}, </span>}
																				{location.street2 && <span>{location.street2}, </span>}
																				{location.city && <span>{location.city}</span>}
																				{location.zip && <span>{location.zip}</span>}
																			</h5>
																		</div>
																	</div>
																</a>
															))}
														<a
															className="view-all-link"
															href="https://www2.osfhealthcare.org/Locations"
														>
															All Matching Locations (
															{locationData?.locations?.totalSearchResults || 0})
														</a>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchData;
