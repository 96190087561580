import { ImageField, Image, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type MapImageProps = {
	fields: {
		generalLink: {
			value: { href: string };
		};
		image: ImageField;
	};
};

const MapImage = (props: MapImageProps): JSX.Element => {
	const { fields } = props;
	return (
		<div className="map-image">
			<div className="image">
				<Image field={{ ...fields?.image }} />
			</div>
			<RichText
				tag="div"
				className="map"
				field={{ value: fields?.generalLink?.value?.href || '' }}
			/>
		</div>
	);
};

export default MapImage;
