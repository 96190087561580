import React from 'react';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type BannerProps = StyleguideComponentProps & {
	fields: {
		image_lg: ImageField;
		image_sm: ImageField;
	};
};

const Banner = (props: BannerProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="banner">
			<picture className="banner-img">
				{props.fields.image_lg && (
					<source media="(min-width: 1280px)" srcSet={props.fields.image_lg.value?.src} />
				)}
				{props.fields.image_sm && <img src={props.fields.image_sm.value?.src} alt="banner" />}
			</picture>
		</div>
	);
};

export default Banner;
