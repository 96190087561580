import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import React from 'react';

type LocationCollection = {
	id: string;
	url: string;
	name: string;
	fields: {
		Street1: Field<string>;
		FeaturedStreet2: Field<string>;
		City: Field<string>;
		LocationId: Field<string>;
		State: Field<string>;
		Zip: Field<string>;
		LocationImage: ImageField;
		FeaturedLocationLink: {
			value: string;
		};
		LocationName: Field<string>;
	};
};
type FeaturedLocationsProps = {
	fields: {
		Title: Field<string>;
		BaseVertical: {
			value: boolean;
		};
		Locations: Array<LocationCollection>;
		BaseViewMoreLink: LinkField;
	};
};

const FeaturedLocations = (props: FeaturedLocationsProps): JSX.Element => {
	const { fields } = props;
	if (fields == undefined) {
		return <div>Data source is not defined</div>;
	}

	return (
		<div className="overhauled">
			<div className="featured-list locations jss-inner-container outer-vertical-spacing">
				{fields?.Title?.value && <h2 className="title">{fields?.Title?.value}</h2>}
				<div className={`list-inner ${fields?.BaseVertical?.value ? 'vertical' : ''}`}>
					{fields?.Locations &&
						fields?.Locations?.length > 0 &&
						fields?.Locations.map(({ fields, id, name }) => (
							<a
								href={`https://www2.osfhealthcare.org/locations/${String(name)
									?.toLowerCase()
									?.split(' ')
									?.join('-')}-${String(fields?.LocationId?.value)
									?.replace('LOC', '')
									?.replace(/^0+/, '')}`}
								key={id}
								className="featured-item bg-secondary"
							>
								<div className="item-image">
									{fields?.LocationImage?.value?.src ? (
										<Image field={fields?.LocationImage} />
									) : (
										<img
											alt={`image-${id}`}
											src="https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-location-transparent"
										/>
									)}
								</div>
								<div className="content-container">
									<div
										className="address"
										itemProp="hospitalAffiliation"
										itemType="http://schema.org/Hospital"
									>
										<Text
											tag="h4"
											className="item-name"
											itemProp="hospitalAffiliation"
											field={fields?.LocationName}
										/>
										<div className="address-details">
											<svg className="icon icon-location">
												<Icons id="icon-location" />
											</svg>
											<p
												className="item-subtitle"
												itemProp="address"
												itemScope
												itemType="http://schema.org/PostalAddress"
											>
												{fields?.Street1 && <Text field={fields?.Street1} />} <br />
												{fields?.City && <Text field={fields?.City} />},{' '}
												{fields?.State && <Text field={fields?.State} />}{' '}
												{fields?.Zip && <Text field={fields?.Zip} />}
											</p>
										</div>
									</div>
								</div>
							</a>
						))}
				</div>
				{props.fields?.BaseViewMoreLink?.value?.href && (
					<div className="view-more">
						<Link className="btn-primary" field={props.fields?.BaseViewMoreLink} />
						<div className="divider"></div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FeaturedLocations;
