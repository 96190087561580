import { gql } from 'graphql-tag';

const GET_CATEGORIES = gql`
	query GetCategories($path: String!, $templateID: String!) {
		search(
			where: {
				AND: [
					{ name: "_path", value: $path, operator: CONTAINS }
					{ name: "_templates", value: $templateID, operator: CONTAINS }
				]
			}
			first: -1
		) {
			total
			results {
				name
				id
			}
		}
	}
`;

export default GET_CATEGORIES;
