import { classNames } from 'assets/utils/helper';
import React from 'react';

type FormTextAreaProps = {
	name: string;
	value: string;
	className: string;
	label: string;
	errMessage?: string;
	isRequired?: boolean;
	// eslint-disable-next-line
	handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const FormTextArea = ({
	name,
	value,
	className,
	label,
	handleChange,
	errMessage,
	isRequired,
	...props
}: FormTextAreaProps): JSX.Element => {
	return (
		<div className={classNames('form-input-wrapper', className)}>
			<label className="form-label" htmlFor="field-textarea">
				{isRequired ? `${label} *` : label || ''}
			</label>
			<div className="form-text-field form-text-area">
				<textarea
					id="field-textarea"
					{...{ name, value }}
					{...props}
					onChange={handleChange}
				></textarea>
			</div>
			{errMessage && <span>{errMessage}</span>}
		</div>
	);
};

export default FormTextArea;
