import React from 'react';

type NestedLinksItem = {
	id: string;
	fields: {
		generalLink: {
			value: {
				href: string;
				text: string;
				target: string;
				linktype: string;
			};
		};
	};
};

type CopyrightFooterProps = {
	nestedLinks: Array<NestedLinksItem>;
	copyright: {
		value: string;
	};
};

const CopyrightFooter = (props: CopyrightFooterProps): JSX.Element => {
	const { copyright, nestedLinks } = props;
	return (
		<div id="footer-legal" role="contentinfo">
			{copyright?.value}
			<ul>
				{nestedLinks?.map(({ id, fields }) => (
					<li className="child" key={id}>
						<a
							href={fields?.generalLink?.value?.href || '#'}
							target={fields?.generalLink?.value?.target}
						>
							{fields?.generalLink?.value?.text}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default CopyrightFooter;
