import React from 'react';
import {
	Form,
	FieldTypes,
	createDefaultFieldFactory,
} from '@sitecore-jss/sitecore-jss-react-forms';
import { withRouter } from 'next/router';
import CustomInput from 'components/atoms/CustomInput';
import CustomSelector from 'components/atoms/CustomSelector';
import CustomButton from 'components/atoms/CustomButton';
import CustomTextArea from 'components/atoms/CustomTextArea';

const EcardForm = ({ fields, router }: any): JSX.Element => {
	const defaultFieldFactory = createDefaultFieldFactory();

	defaultFieldFactory.setComponent(FieldTypes.SingleLineText, (props) => (
		<CustomInput {...props} />
	));
	defaultFieldFactory.setComponent(FieldTypes.DropdownList, (props) => (
		<CustomSelector {...props} />
	));
	defaultFieldFactory.setComponent(FieldTypes.MultipleLineText, (props) => (
		<CustomTextArea {...props} />
	));
	defaultFieldFactory.setComponent(FieldTypes.Button, (props) => (
		<CustomButton btnClass="card-button" {...props} />
	));
	return (
		<Form
			{...fields}
			form={fields}
			sitecoreApiHost={'https://sitecoredxp-dev-cms.osfhealthcare.org/'}
			fieldFactory={defaultFieldFactory}
			sitecoreApiKey={'{83B432B0-3E2B-41D5-BA34-46569D0C743D}'}
			onRedirect={(url: string) => router.push(url)}
		/>
	);
};

export default withRouter(EcardForm);
