import { classNames } from 'assets/utils/helper';
import React from 'react';

type SelectorProps = {
	inputClass?: string;
	disabled?: boolean;
	field: any;
};

const CustomSelector = ({ inputClass, disabled, field, ...props }: SelectorProps): JSX.Element => {
	const { model } = field;
	return (
		<div className={classNames('form-input-wrapper', inputClass || '')} {...props}>
			<label className="form-label" htmlFor="field-selector">
				{model?.title || ''}
			</label>
			<div className="form-text-field form-text-selector">
				<select className="selector" disabled={disabled}>
					{model?.items?.map(({ itemId, text, value, ...data }: any) => (
						<option key={itemId} {...data} value={value}>
							{text}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default CustomSelector;
