// Hook

import { useState, useEffect } from 'react';

type InitialState = {
	width: number;
	height: number;
};

const useWindowSize = (): InitialState => {
	// Initialize state with undefined width/height so server and client renders match
	const [windowSize, setWindowSize] = useState<InitialState>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		// Handler to call on window resize
		const handleResize = () => {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so the state gets updated with the initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;
};
export default useWindowSize;
