import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type DisclaimerWidgetProps = {
	fields: {
		body: Field<string>;
	};
};

const DisclaimerWidget = (props: DisclaimerWidgetProps): JSX.Element => {
	const { fields } = props;
	return (
		<div className="text-banner">
			<RichText field={fields?.body || ''} />
		</div>
	);
};
export default DisclaimerWidget;
