import { Text, Field, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type RelatedLinksProps = StyleguideComponentProps & {
	fields: {
		header: Field<string>;
		category: ItemValue;
		nooflinks: Field<string>;
		children: Array<ItemValue>;
	};
};

const RelatedLinks = (props: RelatedLinksProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	const { category, header, children } = props.fields;
	if (category.name === 'Most Popular') {
		return (
			<div className="related-links">
				{props.fields.header && (
					<Text tag="h4" field={props.fields.header} className="section-title" />
				)}
				<div>
					<ul>
						{children &&
							children.map((item) => (
								<li key={item.id}>
									<Link
										className="text-plus color-green"
										aria-label="OSF Inspire"
										data-target="inspire"
										key={item.id}
										field={item.fields.link}
									/>
								</li>
							))}
					</ul>
				</div>
			</div>
		);
	} else {
		return (
			<div className="related-links">
				{props.fields.header && <Text tag="h4" field={header} className="section-title" />}
				<div>
					<ul>
						{children &&
							children.map((item) => (
								<div key={item.id}>
									{item.fields.header && (
										<Text tag="h3" field={item.fields.header} className="section-title" />
									)}
									<li key={item.id}>
										<Link
											className="text-plus color-green"
											aria-label="OSF Inspire"
											data-target="inspire"
											key={item.id}
											field={item.fields.link}
										/>
									</li>
								</div>
							))}
					</ul>
				</div>
			</div>
		);
	}
};

export default RelatedLinks;
