import React, { useState } from 'react';
import { Image, Field, ImageField, Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import Menu from '../../assets/images/icons/menu.svg';
import MenuDark from '../../assets/images/icons/menu-dark.svg';
import Close from '../../assets/images/icons/close.svg';
import { ItemValue } from '../types/ListItem';
//import ArrowUp from '../../assets/images/icons/arrow-up.svg';
//import Calendar from '../../assets/images/icons/calendar.svg';

type NavigationProps = StyleguideComponentProps & {
	fields: {
		logo: Field<ImageField>;
		title: Field<string>;
		hoverLinkLeft: LinkField;
		hoverLinkLeftDescription: Field<string>;
		hoverLinkRight: LinkField;
		hoverLinkRightDescription: Field<string>;
		link: LinkField;
		children: Array<ItemValue>;
	};
};

const Navigation = (props: NavigationProps): JSX.Element => {
	if (!props || !props.fields || !props.fields.children) {
		return <div></div>;
	}

	const [isMenuOpen, setMenuOpen] = useState(false);
	const { logo, children } = props.fields;
	return (
		<div className={isMenuOpen ? `mobile-nav-open` : ``}>
			<header className="osfhealthcare-innovation-header" data-module="InnovationHeader">
				<div className="navigation-container">
					{props.fields.logo && (
						<a className="home-link" aria-label="OSF Health Care Home Page" href="/">
							<Image field={logo.value} className="osf-logo" alt="OSF Innovation" />
						</a>
					)}

					<nav className="innovation-links">
						{children &&
							children.map((item) => (
								<Link
									className="nav-element"
									aria-label={item.displayName}
									data-target={item.id}
									key={item.id}
									field={item.fields.link}
								>
									<span>{item.fields.link.value.text}</span>
								</Link>
							))}
					</nav>

					<button
						className="open-mobile-nav"
						onClick={() => setMenuOpen(!isMenuOpen)}
						aria-label="Open Menu"
					>
						<MenuDark className="icon icon-menu-dark">
							<use href="#menu-dark" />
						</MenuDark>
						<Menu className="icon icon-menu">
							<use href="#menu" />
						</Menu>
					</button>
				</div>

				{/*<div className="subnav-container">*/}
				{/*	{children &&*/}
				{/*		children.map((item) => {*/}
				{/*			const hasRightEl =*/}
				{/*				item.fields.hoverLinkRight.value && item.fields.hoverLinkRightDescription.value;*/}
				{/*			return (*/}
				{/*				<div className="subnav-element" data-id={item.id} key={item.id}>*/}
				{/*					<div className="subnav-inner">*/}
				{/*						<Link className="subnav-cta" field={item.fields.hoverLinkLeft}>*/}
				{/*							<div className="section">*/}
				{/*								<h3 className="subnav-headline">*/}
				{/*									{item.fields.hoverLinkLeftDescription.value}*/}
				{/*								</h3>*/}
				{/*								<p className="subnav-description"></p>*/}
				{/*							</div>*/}
				{/*							<div className="section">*/}
				{/*								<ArrowUp className="icon icon-arrow-up section-icon" />*/}
				{/*							</div>*/}
				{/*						</Link>*/}
				{/*						{hasRightEl && (*/}
				{/*							<Link*/}
				{/*								className="subnav-cta subnav-cta-secondary"*/}
				{/*								field={item.fields.hoverLinkRight}*/}
				{/*							>*/}
				{/*								<div className="section">*/}
				{/*									<h3 className="subnav-headline">*/}
				{/*										{item.fields.hoverLinkRightDescription.value}*/}
				{/*									</h3>*/}
				{/*									*/}{/* <p className="subnav-description">Lorem ipsum sit dolor met.</p> */}
				{/*									<svg className="icon icon-calendar section-icon" aria-hidden="true">*/}
				{/*										<Calendar />*/}
				{/*									</svg>*/}
				{/*								</div>*/}
				{/*							</Link>*/}
				{/*						)}*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*			);*/}
				{/*		})}*/}
				{/*</div>*/}

				<div className="mobile-nav-menu">
					<div className="mobile-nav-menu-container">
						<div className="mobile-nav-header">
							{props.fields.logo && (
								<a className="mobile-home-link" aria-label="OSF Health Care Home Page" href="/">
									<Image field={logo.value} className="osf-logo" alt="OSF Innovation" />
								</a>
							)}

							<button className="close-mobile-nav" aria-label="Close Menu">
								<div className="mobile-close-button icon icon-menu-close">
									<Close className="icon icon-close" onClick={() => setMenuOpen(!isMenuOpen)}>
										<use href="#close" />
									</Close>
								</div>
							</button>
						</div>

						<ul className="mobile-nav-list">
							{children &&
								children.map((item) => {
									return (
										<li key={item.id}>
											<a aria-label={item.displayName} href={item.fields.link.value.href}>
												<div className="list-block">
													<h3>{item.fields.link.value.text}</h3>
												</div>
											</a>
										</li>
									);
								})}
						</ul>
					</div>
				</div>
			</header>
		</div>
	);
};

export default Navigation;
