import AdditionalCards from 'components/molecules/AdditionalCards';
import React from 'react';

type Item = {
	description: string;
	title: string;
	image: {
		src: string;
	};
	link: {
		text: string;
		url: string;
		target: string;
	};
};

type RelatedBlogsProps = {
	fields: {
		title: string;
		link: {
			text: string;
			url: string;
			target: string;
		};
		data: Array<Item>;
	};
};

const RelatedBlogs = (props: RelatedBlogsProps): JSX.Element => {
	const { fields } = props;
	return (
		<div className="related-card-container">
			<div className="card-header">
				<h1>{fields?.title || ''}</h1>
			</div>
			<div className="row">
				{fields?.data?.map(({ title, description, image, link }, index) => (
					<div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3" key={index}>
						<AdditionalCards
							isHideSecondary
							{...{ title, description, imgUrl: image?.src, link }}
						/>
					</div>
				))}
			</div>
			<div className="view-all-link">
				<a href={fields?.link?.url || '#'} target={fields?.link?.target}>
					{fields?.link?.text || ''}
				</a>
			</div>
		</div>
	);
};

export default RelatedBlogs;
