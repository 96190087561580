import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import AuthorComponent from 'components/r2/BlogAuthor';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

interface Author {
	id: string;
	url: string;
	name: string;
	displayName: string;
	fields: {
		'Author Image': {
			value: {
				src: string;
				alt: string;
			};
		};
		'Author Name': {
			value: string;
		};
		Email: {
			value: string;
		};
		hashvalue: {
			value: string;
		};
		link: LinkField;
		Phone: {
			value: string;
		};
		slug: {
			value: string;
		};
		Summary: {
			value: string;
		};
		'WordPress Id': {
			value: string;
		};
	};
}

interface RawHtmlProps {
	html: string;
}
interface TextField {
	value: string;
}

interface LinkField {
	value: {
		href: string;
	};
}

interface ImageField {
	value: {
		src?: string;
		alt?: string;
		width?: number;
		height?: number;
		srcset?: string;
		sizes?: string;
	};
}

interface Category {
	id: string;
	url: string;
	name: string;
	displayName: string;
	fields: {
		hashvalue: TextField;
		link: LinkField;
		Name: TextField;
		WordPressId: TextField;
	};
}

interface RouteFields {
	Author: Author;
	Categories: Category[];
	Date: TextField;
	Tags: string[];
	Id: TextField;
	['Last Updated']: TextField;
	Status: TextField;
	BodyRichText: TextField;
	image: ImageField;
	Link: LinkField;
	Summary: TextField;
	Thumbnail: ImageField;
	Title: TextField;
	BrowserTitle: TextField;
	MetaDescription: TextField;
	MetaKeyword: TextField;
	MetaTitle: TextField;
	PageTitle: TextField;
	RobotsTags: TextField;
	TwitterCardType: string | null;
	TwitterDescription: TextField;
	TwitterImage: ImageField;
	TwitterSite: TextField;
	TwitterTitle: TextField;
	OpenGraphAdmins: TextField;
	OpenGraphDescription: TextField;
	OpenGraphImageUrl: ImageField;
	OpenGraphType: TextField;
	OpenGraphSiteName: TextField;
	OpenGraphTitle: TextField;
	OpenGraphAppId: TextField;
	breadcrumbtitle: TextField;
	TitleRawText: TextField;
	'Blog Image': ImageField;
}

interface SitecoreContext {
	route: {
		fields: RouteFields;
	};
}

interface BlogPostDetailsProps {
	sitecoreContext: SitecoreContext;
}

const RawHtml: React.FC<RawHtmlProps> = ({ html }) => {
	return <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: html }} />;
};

const formatDate = (dateString: string): string => {
	const date = new Date(dateString);
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
	const day = String(date.getDate()).padStart(2, '0');
	const year = date.getFullYear();

	return `${month}/${day}/${year}`;
};

interface DateComponentProps {
	dateValue: string;
}

const DateComponent: React.FC<DateComponentProps> = ({ dateValue }) => {
	return <div className="content-title">{formatDate(dateValue)}</div>;
};

const BlogPostDetails = (props: BlogPostDetailsProps) => {
	const bodyContent = props.sitecoreContext.route.fields.BodyRichText?.value;
	const BlogPostDetails = props.sitecoreContext.route.fields;
	const AuthorPostDetails: Author = props.sitecoreContext.route.fields.Author;
	return (
		<div className="blog-details">
			<RichText tag="h1" className="heading-1" field={BlogPostDetails.TitleRawText} />
			{BlogPostDetails['Blog Image']?.value && (
				<img src={BlogPostDetails['Blog Image'].value as string} alt="Image" />
			)}
			<DateComponent dateValue={BlogPostDetails['Last Updated']?.value} />
			<RawHtml html={bodyContent} />
			<AuthorComponent fields={AuthorPostDetails?.fields} id={AuthorPostDetails?.id} />
		</div>
	);
};

// wrap BlogPostDetails in the HOC (note the double function invocation - important)
export default withSitecoreContext()(BlogPostDetails);
