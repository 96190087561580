const classNames = (...classes: string[]): string => classes.filter(Boolean).join(' ');

const addUnderScoreWithLowerCase = (value: string): string => {
	const cloneValue = String(value);
	return value ? cloneValue?.replace(/\s+/g, '_')?.toLowerCase() : '';
};

const paginate = (array: any, page_size: number, page_number = 1): any => {
	return array?.slice((page_number - 1) * page_size, page_number * page_size);
};

const decodeHTMLEntities = (text: string) => {
	const textArea = typeof document === 'undefined' ? null : document.createElement('textarea');
	if (!textArea) return text;
	textArea.innerHTML = text;
	return textArea.value;
};

export { classNames, addUnderScoreWithLowerCase, paginate, decodeHTMLEntities };
