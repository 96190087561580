import { Text, Field, LinkField, Link, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import ContentBlock from 'components/shared/SharedContentBlock';
import { ItemValue } from 'components/types/ListItem';

type SixUpProps = StyleguideComponentProps & {
	fields: {
		heading: Field<string>;
		title: Field<string>;
		content: Field<string>;
		description: Field<string>;
		link: LinkField;
		children: Array<ItemValue>;
	};
};

const SixUp = (props: SixUpProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="sixup jss-inner-container">
			{props.fields.title && props.fields.description && (
				<ContentBlock title={props.fields.title} description={props.fields.description} />
			)}
			<div className="jss-six-up sixup-list">
				{props.fields.children &&
					props.fields.children.map((item) => (
						<div className="additional-cards" key={item.id}>
							<div className="content">
								{item.fields.heading && (
									<Text tag="h5" className="header" field={item.fields.heading} />
								)}
								{item.fields.content && (
									<RichText tag="div" className="description" field={item.fields.content} />
								)}
							</div>
							<div>
								{item.fields.link && (
									<Link className="text-plus color-green jss-link" field={item.fields.link} />
								)}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default SixUp;
