import React from 'react';
import { Field, ImageField, Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ItemValue } from 'components/types/ListItem';
import ArticleSummary from '../organisms/ArticleSummary';

type ArticleSummary = {
	id: string;
	fields: {
		BaseSummary: Field<string>;
		BaseImage: ImageField;
		BaseImageRight: {
			value: boolean;
		};
		BaseDate: Field<string>;
		BaseLocation: Field<string>;
		BaseHeading: Field<string>;
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
	};
};

type ArticleSummaryComponentProps = {
	fields: {
		BaseBackground: {
			value: boolean;
		};
		BaseVertical: {
			value: boolean;
		};
		BaseViewMoreLink: LinkField;
		children: Array<ArticleSummary>;
	};
};

const ArticleSummaryComponent = (props: ArticleSummaryComponentProps): JSX.Element => {
	const { fields } = props;
	const innerFields = fields;

	return (
		<div className="mx-0 row overhauled">
			<div
				className={`${
					fields.BaseBackground?.value == true
						? fields.BaseVertical?.value == true
							? 'article-summary-wrapper bg-secondary inner-vertical-spacing'
							: 'article-summary-wrapper bg-secondary'
						: 'article-summary-wrapper outer-vertical-spacing'
				}`}
			>
				<div className="jss-inner-container">
					<div
						className={`${
							fields.BaseVertical?.value == true
								? 'vertical article-summaries'
								: 'article-summaries'
						}`}
					>
						{innerFields?.children?.map(({ id, fields }, index) => {
							return (
								<div key={id} className={`${'article-summary-outer outer-vertical-spacing'}`}>
									<ArticleSummary
										summary={fields?.BaseSummary?.value}
										image={fields?.BaseImage}
										imageRight={fields?.BaseImageRight?.value}
										date={fields?.BaseDate?.value}
										location={fields?.BaseLocation?.value}
										heading={fields?.BaseHeading?.value}
										link={fields?.BaseLink}
										linkType={fields?.BaseLinkType?.name}
										imgAlt={`articles_image_${index + 1}`}
									/>
								</div>
							);
						})}
					</div>
					{props.fields?.BaseViewMoreLink?.value?.href && (
						<div className="view-more">
							<Link className="btn-primary" field={props.fields?.BaseViewMoreLink} />
							<div className="divider"></div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ArticleSummaryComponent;
