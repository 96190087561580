import React from 'react';

const arrowBottomClasses = ['arrow-bottom-left', 'arrow-bottom-right'];

type ToolTipProps = {
	text?: string;
	link?: string;
	TitleElement: () => JSX.Element;
	DescElement?: () => JSX.Element;
	showCloseBtn?: boolean;
	isShowDescElement?: boolean;
	onClose?: () => void;
	arrowClass?: string;
};

const Tooltip = ({
	text,
	link,
	TitleElement,
	showCloseBtn,
	onClose,
	DescElement,
	isShowDescElement,
	arrowClass,
}: ToolTipProps): JSX.Element => {
	const getArrowClass = link
		? arrowBottomClasses.includes(arrowClass || '')
			? arrowClass
			: 'arrow-bottom-left'
		: arrowClass;

	return (
		<div className={`tooltip`}>
			<TitleElement />
			<div className={`content ${getArrowClass}`}>
				<div className={`data-content ${link && 'color-neutral-lightest'}`}>
					<div className="text">
						{link && <span className="link-text">{link}</span>}
						{isShowDescElement ? <>{DescElement ? <DescElement /> : null}</> : <p>{text || ''}</p>}
					</div>
					{showCloseBtn && (
						<div className="x-icon">
							<svg
								className={`icon icon-close ${link ? 'icon-black' : 'icon-white'}`}
								aria-hidden="true"
								onClick={onClose}
							>
								<use xlinkHref="#close"></use>
							</svg>
						</div>
					)}
				</div>

				{link && (
					<div className="data-wrapper">
						<div>Data Input</div>
						<div>Data Input</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Tooltip;
