import React from 'react';
import { Image, ImageField, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type HeaderProps = StyleguideComponentProps & {
	fields: {
		logo: ImageField;
	};
};

const Header = (props: HeaderProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div>
			<header className="innovation-header" data-module="InnovationHeader">
				<div className="navigation-container">
					{props.fields.logo && (
						<a className="home-link" aria-label="OSF Health Care Home Page" href="/">
							<Image
								field={props.fields.logo}
								className="osf-logo"
								alt={
									(props.fields.logo.value?.alt as string) != ''
										? (props.fields.logo.value?.alt as string)
										: 'OSF Innovation'
								}
							/>
						</a>
					)}
					<Placeholder name="jss-header" rendering={props.rendering} />
				</div>
			</header>
		</div>
	);
};

export default Header;
