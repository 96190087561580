import React from 'react';

const OverviewArrow = (): JSX.Element => {
	return (
		<a className="overview-link" aria-label="OSF Health Care Home Page" href="/gic">
			<svg
				className="icon icon-arrow-left"
				aria-hidden="true"
				xmlns="http://www.w3.org/2000/svg"
				width="20.5"
				height="21.121"
				viewBox="0 0 20.5 21.121"
			>
				<g
					id="Component_330_4"
					data-name="Component 330 – 4"
					transform="translate(19.75 20.061) rotate(180)"
				>
					<path
						id="Path_10297"
						data-name="Path 10297"
						d="M216.785,117.552l9.173,9.5-9.173,9.5"
						transform="translate(-206.959 -117.552)"
						fill="none"
						stroke="#000"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
					<line
						id="Line_2"
						data-name="Line 2"
						x2="19"
						transform="translate(0 9.501)"
						fill="none"
						stroke="#000"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
				</g>
			</svg>

			<span>Overview</span>
		</a>
	);
};

export default OverviewArrow;
