import {
	Text,
	RichText,
	Field,
	LinkField,
	Link,
	ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Image from '../atoms/Image';
import { ItemValue } from 'components/types/ListItem';

type SpotlightLocationsProps = {
	fields: {
		BaseImage: ImageField;
		BaseHeadline: Field<string>;
		BaseDescription: Field<string>;
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
	};
};

const SpotlightLocations = (props: SpotlightLocationsProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="overhauled">
			<div className="spotlight locations jss-inner-container outer-vertical-spacing">
				<div className="bg-secondary">
					<div className="content-wrapper">
						{props.fields.BaseImage?.value?.src && (
							<div className="img-container">
								<Image
									imageUrl={props.fields.BaseImage?.value?.src as string}
									alt={
										(props.fields.BaseImage?.value?.alt as string) != ''
											? (props.fields.BaseImage?.value?.alt as string)
											: 'Image'
									}
									aditionalClass="location-img"
								/>
							</div>
						)}
						<div className="inner-content inner-side-spacing">
							{props.fields?.BaseHeadline?.value && (
								<Text tag="h2" className="headline" field={props.fields.BaseHeadline} />
							)}
							{props.fields?.BaseDescription?.value && (
								<RichText tag="" className="description" field={props.fields.BaseDescription} />
							)}
							{props.fields?.BaseLink?.value?.href && props.fields?.BaseLinkType?.name && (
								<Link
									field={props.fields?.BaseLink?.value}
									className={
										props.fields?.BaseLinkType
											? `btn-mobile-fw btn-${props.fields?.BaseLinkType?.name.toLowerCase()}`
											: 'btn-mobile-fw'
									}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpotlightLocations;
