import { Field, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type ContentCardProps = {
	fields: {
		link: LinkField;
		heading: Field<string>;
		text: Field<string>;
		footerText: Field<string>;
	};
};

const ContentCard = (props: ContentCardProps): JSX.Element => (
	<a className="content-card track-link-click" href={props.fields?.link.value.href}>
		<Text tag="h2" className="card-title" field={props.fields?.heading} />
		<div className="card-footer">
			<Text tag="div" className="link-text" field={props.fields?.footerText} />
			<div>
				<svg
					className="icon icon-chevron-right"
					xmlns="http://www.w3.org/2000/svg"
					width="8.121"
					height="14.122"
					viewBox="0 0 8.121 14.122"
				>
					<g id="Icon_Arrow_Right" data-name="Icon Arrow Right" transform="translate(1.061 1.061)">
						<path
							id="Path_747"
							data-name="Path 747"
							d="M4918.513-354.946l6,6-6,6"
							transform="translate(-4918.513 354.946)"
							fill="none"
						/>
					</g>
				</svg>
			</div>
		</div>
	</a>
);

export default ContentCard;
