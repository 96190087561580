import { Placeholder, LinkField, ImageField, Image } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type FooterProps = StyleguideComponentProps & {
	fields: {
		logo: ImageField;
		link: LinkField;
	};
};

const Footer = (props: FooterProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<footer className="innovation-footer" data-module="Footer">
			<div className="footer-inner">
				<div className="footer-container">
					<div className="content">
						<div className="logo-and-social">
							<a
								className="logo-link"
								href={
									props.fields.link && props.fields.link.value ? props.fields.link?.value.href : ''
								}
								aria-label="OSF Health Care Home Page"
							>
								{props.fields.logo && (
									<Image
										field={props.fields.logo}
										className="footer-logo"
										alt={
											(props.fields.logo.value?.alt as string) != ''
												? (props.fields.logo.value?.alt as string)
												: 'OSF Healthcare'
										}
									/>
								)}
							</a>
							<Placeholder name="jss-footer-social" rendering={props.rendering} />
						</div>

						<div className="links-and-resources">
							<div className="innovation-and-social">
								<Placeholder name="jss-footer-link-list" rendering={props.rendering} />
								<div className="social-channels">
									<a
										className="social-channel-link"
										href="https://www.facebook.com/OSFInnovation/?ref=aymt_homepage_panel"
										target="_blank"
										rel="noopener noreferrer"
									>
										<svg className="icon icon-facebook">
											<use href="#facebook" />
										</svg>
										<svg className="icon icon-facebook-hover">
											<use href="#facebook-hover" />
										</svg>
									</a>
									<a
										className="social-channel-link"
										href="https://www.linkedin.com/company/osfinnovation/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<svg className="icon icon-linkedin">
											<use href="#linkedin" />
										</svg>
										<svg className="icon icon-linkedin-hover">
											<use href="#linkedin-hover" />
										</svg>
									</a>
									<a
										className="social-channel-link"
										href="https://twitter.com/OSFInnovation"
										target="_blank"
										rel="noopener noreferrer"
									>
										<svg className="icon icon-twitter">
											<use href="#twitter" />
										</svg>
										<svg className="icon icon-twitter-hover">
											<use href="#twitter-hover" />
										</svg>
									</a>
									<a
										className="social-channel-link"
										href="https://www.osfhealthcare.org/innovation/contact/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<svg className="icon icon-mail">
											<use href="#mail" />
										</svg>
										<svg className="icon icon-mail-hover">
											<use href="#mail-hover" />
										</svg>
									</a>
								</div>
							</div>

							<div className="resources-and-legal">
								<Placeholder name="jss-footer-resources-list" rendering={props.rendering} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
