import Icons from 'components/atoms/Icons';
import React, { useEffect, useState } from 'react';

const R2ZipUpdate = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState('');
	const [geolocating, setGeolocating] = useState(false);
	const [zip, setZip] = useState('');
	const [zipInput, setZipInput] = useState('');

	const setLocationStorageItems = (
		locationName: string,
		lat: string,
		lng: string,
		zipCode: string
	) => {
		locationName ? localStorage.setItem('location', locationName) : delete localStorage.location;

		lat ? localStorage.setItem('latitude', lat) : delete localStorage.latitude;

		lng ? localStorage.setItem('longitude', lng) : delete localStorage.longitude;

		zipCode ? localStorage.setItem('zipCode', zipCode) : delete localStorage.zipCode;
	};

	const zipGeocode = (zip: any) => {
		const { maps } = (window as any).google;
		const geocoder = new maps.Geocoder();
		geocoder
			.geocode({ address: zip })
			.then((response: any) => {
				if (response.results[0]) {
					buildAddress(response.results[0], {
						lat: response.results[0].geometry.location.lat(),
						lng: response.results[0].geometry.location.lng(),
					});
				} else {
					console.log('No results found');
				}
			})
			.catch((e: any) => {
				console.log('Geocoder failed due to: ' + e);
				setError('Please enter a valid ZIP.');
			});
	};

	const reverseGeocode = (latLng: any) => {
		if (typeof window !== 'undefined') {
			const { maps } = (window as any).google;
			const geocoder = new maps.Geocoder();

			geocoder
				.geocode({ location: latLng })
				.then((response: any) => {
					setGeolocating(false);

					if (response.results[0]) {
						buildAddress(response.results[0], latLng);
					} else {
						console.log('No results found');
					}
				})
				.catch((e: any) => {
					console.log('Geocoder failed due to: ' + e);
					setGeolocating(false);
				});
		}
	};

	const geolocate = () => {
		setGeolocating(true);
		navigator.geolocation.getCurrentPosition(
			(position) => {
				if (position && position.coords && position.coords.latitude && position.coords.longitude) {
					const latLng = {
						lat: parseFloat(String(position.coords.latitude)),
						lng: parseFloat(String(position.coords.longitude)),
					};

					reverseGeocode(latLng);
				}
			},
			() => {
				console.log('error');
				setGeolocating(false);
				setNewZip('61602');
			}
		);
	};

	const setLatLng = (
		result: { address_components: any[] },
		latLng: { lat: string; lng: string },
		locationName: string
	) => {
		const zipObj = result.address_components.find((x) => x.types[0] === 'postal_code'),
			zipCode = zipObj && zipObj.short_name;

		setZip(zipCode);
		setZipInput(zipCode);

		if (zipCode) {
			setError('');
			setLocationStorageItems(locationName, latLng.lat, latLng.lng, zipCode);
			setOpen(false);
		} else {
			reverseGeocode(latLng);
		}
	};

	const buildAddress = (result: { address_components: any }, latLng: { lat: any; lng: any }) => {
		const zipObj = result.address_components.find((x: any) => x.types[0] === 'postal_code'),
			zipCode = zipObj && zipObj.short_name,
			localityObj = result.address_components.find((x: any) => x.types[0] === 'locality'),
			localityName = localityObj && localityObj.short_name,
			stateObj = result.address_components.find(
				(x: any) => x.types[0] === 'administrative_area_level_1'
			),
			stateName = stateObj && stateObj.short_name,
			locationName = `${localityName ? localityName + ', ' : ''}${
				stateName ? stateName + ' ' : ''
			}${zipCode ? zipCode : ''}`;

		if (latLng) {
			setLatLng(result, latLng, locationName);
		}
	};

	const onZipInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setZipInput(e.target.value);

	const setNewZip = (zipCode: any) => {
		if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode)) {
			zipGeocode(zipCode);
		} else {
			setError('Please enter a valid ZIP.');
		}
	};

	const onPopupKeydown = (e: React.KeyboardEvent) => {
		if (e.key === 'Escape') {
			setOpen(false);
		}
	};

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			setNewZip(zipInput);
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setZip(
				localStorage.getItem('zipCode')
					? decodeURIComponent(localStorage.getItem('zipCode') || '')
					: ''
			);
			setZipInput(
				localStorage.getItem('zipCode')
					? decodeURIComponent(localStorage.getItem('zipCode') || '')
					: ''
			);
		}
		!localStorage.getItem('zipCode') && geolocate();
	}, []);

	return (
		<>
			{' '}
			<button
				className={`utility-link zip-btn ${geolocating ? 'geolocating' : ''}`}
				onClick={() => {
					setOpen(!open);
				}}
				onKeyDown={(e) => {
					if ((e.key === 'Tab' && e.shiftKey) || e.key === 'Escape') {
						setOpen(false);
					}
				}}
			>
				<svg className="icon icon-location">
					<Icons id="icon-location" />
				</svg>
				Zip{geolocating ? '' : zip ? ` ${zip}` : ''}
			</button>
			{open ? (
				<>
					<div
						className="r2-zip-overlay"
						onClick={() => {
							setOpen(false);
						}}
					></div>
					<div className="r2-zip-update" onKeyDown={onPopupKeydown}>
						<label htmlFor="global-zip-input" className="zip-input-label">
							Enter your ZIP code:
						</label>
						<div className="input-btn-container">
							<div className={`input-container ${error ? 'invalid' : ''}`}>
								<input
									id="global-zip-input"
									type="text"
									placeholder="Enter ZIP Code"
									className="zip-input-field"
									value={zipInput}
									onChange={onZipInputChange}
									onKeyDown={onKeyDown}
								></input>
								{error && <span className="error-message">{error}</span>}
							</div>
							<button
								onClick={() => {
									setNewZip(zipInput);
								}}
								onKeyDown={(e) => {
									if (e.key === 'Tab' && !e.shiftKey) {
										setOpen(false);
									}
								}}
							>
								Update
							</button>
						</div>
						<p>
							Entering your ZIP code helps us provide information and results that are more relevant
							to you.
						</p>
					</div>
				</>
			) : null}
		</>
	);
};

export default R2ZipUpdate;
