import { Field, Image, ImageField, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

type GICFooterProps = {
	fields: {
		logo: ImageField;
		copyright: Field<string>;
		termsLink: LinkField;
		complianceLink: LinkField;
	};
};

const GICFooter = (props: GICFooterProps): JSX.Element => {
	return (
		<footer className="gic-footer gradient" data-module="GicFooter">
			<div className="footer-container">
				<div className="content">
					<div className="logo-wrap">
						{props.fields.logo && (
							<a className="track-nav" aria-label="OSF Health Care Home Page" href="/">
								<Image field={props.fields?.logo} className="footer-logo" alt="OSF Innovation" />
							</a>
						)}
					</div>
					<ul className="link-list">
						<li className="link-list-item">
							<span className="copyright-text">
								&copy; {new Date().getFullYear()} <Text field={props.fields.copyright} />
							</span>
						</li>
						<li className="link-list-item">
							<Link field={props.fields.termsLink} />
						</li>
						<li className="link-list-item">
							<Link field={props.fields.complianceLink} />
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};

export default GICFooter;
