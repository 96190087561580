import React from 'react';
import { StyleguideComponentProps } from 'lib/component-props';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

// eslint-disable-next-line @typescript-eslint/ban-types
type GICPageGridProps = StyleguideComponentProps & {};

const GICPageGrid = (props: GICPageGridProps): JSX.Element => {
	return (
		<div className="page-grid" data-module="PageGrid">
			<div className="content">
				<div className="row top-row">
					<Placeholder name="gic-top-row" rendering={props.rendering} />
				</div>
				<div className="row">
					<div className="col-12 col-lg-3 list-block-col">
						<Placeholder name="gic-list-block-col" rendering={props.rendering} />
					</div>
					<div className="col-12 col-lg-6 main-content-col">
						<Placeholder name="gic-main-content-col" rendering={props.rendering} />
					</div>
					<div className="col-12 col-lg-3 right-rail gic-right-rail">
						<Placeholder name="gic-right-rail" rendering={props.rendering} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default GICPageGrid;
