import React from 'react';
import PillList from 'components/atoms/PillList';

type TagsItem = {
	id: string;
	displayName: string;
	fields: {
		hashvalue: {
			value: string;
		};
		link: {
			value: {
				href: string;
			};
		};
	};
};

type CategoriesItem = {
	id: string;
	displayName: string;
	fields: {
		hashvalue: {
			value: string;
		};
		link: {
			value: {
				href: string;
			};
		};
	};
};

type BlogCategoriesProps = {
	fields: {
		Tags: Array<TagsItem>;
		Categories: Array<CategoriesItem>;
	};
};

const BlogCategories = (props: BlogCategoriesProps): JSX.Element => {
	const { fields } = props;

	return (
		<div className="blog-container">
			<div className="my-4">
				<p className="content-title">Categories:</p>
				<PillList
					type="category"
					pills={fields.Categories?.map(({ id, displayName, fields: { link, hashvalue } }) => {
						return {
							id,
							value: hashvalue?.value || '',
							href: link.value?.href || '#',
							text: displayName || '',
						};
					})}
				/>
			</div>
			<div className="tabs-container my-4">
				<p className="content-title">Tags:</p>
				<PillList
					type="tag"
					pills={fields.Tags?.map(({ id, displayName, fields: { link, hashvalue } }) => {
						return {
							id,
							value: hashvalue?.value || '',
							href: link.value?.href || '#',
							text: displayName || '',
						};
					})}
				/>
			</div>
		</div>
	);
};

export default BlogCategories;
