import React from 'react';
import { classNames } from 'assets/utils/helper';

type TextAreaProps = {
	inputClass?: string;
	disabled?: boolean;
	field: any;
};

const CustomTextArea = ({ inputClass, disabled, field, ...props }: TextAreaProps): JSX.Element => {
	const { model } = field;

	return (
		<div className={classNames('form-input-wrapper', inputClass || '')} {...props}>
			<label className="form-label" htmlFor="field-textarea">
				{model?.title || ''}
			</label>
			<div className="form-text-field form-text-area">
				<textarea
					id="field-textarea"
					{...{ disabled, placeholder: model?.placeholderText || '', required: true, rows: 4 }}
				></textarea>
			</div>
		</div>
	);
};

export default CustomTextArea;
