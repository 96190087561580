import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type TextCallOutProps = StyleguideComponentProps & {
	fields: {
		heading: Field<string>;
		children: Array<ItemValue>;
	};
};

const TextCallOut = (props: TextCallOutProps): JSX.Element => {
	const { children } = props.fields;

	if (!children) {
		return <div></div>;
	}
	return (
		<div className="text-call-out">
			{props.fields.children.map((item, idx) => (
				<RichText tag="div" field={item.fields.heading} key={idx} />
			))}
		</div>
	);
};
export default TextCallOut;
