import {
	Text,
	Field,
	LinkField,
	ImageField,
	Link,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { useState } from 'react';
import ContentBlock from 'components/shared/SharedContentBlock';
import { ItemValue } from 'components/types/ListItem';

type ListingComponentProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		heading: Field<string>;
		title: Field<string>;
		content: Field<string>;
		link: LinkField;
		viewalllink: LinkField;
		children: Array<ItemValue>;
	};
};

const ListingComponent = (props: ListingComponentProps): JSX.Element => {
	if (props.fields == undefined) {
		return <div></div>;
	}
	const [limit, setLimit] = useState<number>(9);

	const loadMore = (): void => {
		setLimit(limit + 9);
	};

	return (
		<div className="overhauled">
			<div className="jss-inner-container outer-vertical-spacing">
				<div className="jss-Listing-component-headline">
					<ContentBlock title={props.fields.title} />
				</div>
				<div className="jss-three-up-wrapper">
					<div className="jss-three-up">
						<div className="inner-wrapper">
							{props.fields.children.length > 0 &&
								props.fields.children.slice(0, limit).map((item) => (
									<div key={item.id}>
										{item.fields?.link?.value?.href ? (
											<Link field={item.fields.link} className="link-card">
												<div
													className={`additional-cards inner-side-spacing ${
														item.fields.image.value?.src ? 'with-img' : ''
													}`}
												>
													{item.fields.image.value?.src !== undefined && (
														<div className="card-img">
															<img
																src={item.fields.image.value?.src}
																alt={
																	(item.fields.image.value?.alt as string) != ''
																		? (item.fields.image.value?.alt as string)
																		: 'Image'
																}
															/>
														</div>
													)}
													{item.fields.heading.value !== '' && item.fields.content.value !== '' && (
														<div className="content">
															{item.fields.heading.value !== '' && (
																<Text
																	tag="h3"
																	className="osf-inner-h3 header"
																	field={item.fields.heading}
																/>
															)}
															{item.fields.content.value !== '' && (
																<RichText
																	tag=""
																	className="description osf-body"
																	field={item.fields.content}
																/>
															)}
															{item.fields?.link?.value?.href && (
																<Link field={item.fields.link} className="btn-tertiary" />
															)}
														</div>
													)}
													{item.fields.viewalllink.value.href !== '' && (
														<div>
															<Link
																className="text-plus color-green jss-link"
																field={item.fields.viewalllink}
															/>
														</div>
													)}
												</div>
											</Link>
										) : (
											<div
												key={item.id}
												className={`additional-cards inner-side-spacing ${
													item.fields.image.value?.src ? 'with-img' : ''
												}`}
											>
												{item.fields.image.value?.src !== undefined && (
													<div className="card-img">
														<img
															src={item.fields.image.value?.src}
															alt={
																(item.fields.image.value?.alt as string) != ''
																	? (item.fields.image.value?.alt as string)
																	: 'Image'
															}
														/>
													</div>
												)}
												{item.fields.heading.value !== '' && item.fields.content.value !== '' && (
													<div className="content">
														{item.fields.heading.value !== '' && (
															<Text
																tag="h3"
																className="osf-inner-h3 header"
																field={item.fields.heading}
															/>
														)}
														{item.fields.content.value !== '' && (
															<RichText
																tag=""
																className="description osf-body"
																field={item.fields.content}
															/>
														)}
														{item.fields?.link?.value?.href && (
															<Link field={item.fields.link} className="btn-tertiary" />
														)}
													</div>
												)}
												{item.fields.viewalllink.value.href !== '' && (
													<div>
														<Link
															className="text-plus color-green jss-link"
															field={item.fields.viewalllink}
														/>
													</div>
												)}
											</div>
										)}
									</div>
								))}
						</div>
					</div>
				</div>
				{props.fields.children.length > limit && (
					<div className="view-more">
						<button className="btn-primary" onClick={loadMore}>
							Load More
						</button>
						<div className="divider"></div>
					</div>
				)}
			</div>
		</div>
	);
};
export default ListingComponent;
