import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ActionButton from './ActionButton';
import { Event } from './EventsList';

export type EventDetailNotes = [
	{
		Title: string;
		PlainText: string;
		Text: string;
	}
];

export type RegistrationConfigurations = [
	{
		Description: string;
		RegistrationStarts: string;
		RegistrationEnds: string;
		Location: string;
	}
];

export type Spaces = {
	Note1: string;
	Note2: string;
	Location: string;
};
const EventDetails = (): JSX.Element => {
	const [eventData, setEventData] = useState<Event>({} as Event);
	const [eventNotesData, setEventNotesData] = useState<EventDetailNotes>([{}] as EventDetailNotes);
	const [regConfigData, setregConfigData] = useState<RegistrationConfigurations>([
		{},
	] as RegistrationConfigurations);
	const [spacesData, setSpacesData] = useState<Spaces>({} as Spaces);
	const getData = async (id: string) => {
		const token = await Axios.get(process.env.UNGERBOECK_API_URL + '/sdk_initialize', {
			headers: {
				Authorization: 'Basic ' + process.env.UNGERBOECK_API_TOKEN,
			},
		});

		const res = await Axios.get(process.env.UNGERBOECK_API_URL + '/Events/10/' + `${id}`, {
			headers: {
				Token: token.data,
			},
		});
		setEventData(res.data);

		const notes = await Axios.get(
			process.env.UNGERBOECK_API_URL + '/Notes/10?search=Event%20eq%20' + `${id}`,
			{
				headers: {
					Token: token.data,
				},
			}
		);
		setEventNotesData(notes.data);

		const regs = await Axios.get(
			process.env.UNGERBOECK_API_URL +
				'/RegistrationConfigurations/10?search=Event%20eq%20' +
				`${id}`,
			{
				headers: {
					Token: token.data,
				},
			}
		);

		setregConfigData(regs.data);

		if (regs.data !== null && regs.data.length > 0 && regs.data[0].Location) {
			const space = await Axios.get(
				process.env.UNGERBOECK_API_URL + `/spaces/10/${regs.data[0].Location}`,
				{
					headers: {
						Token: token.data,
					},
				}
			);

			setSpacesData(space.data);
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const id = window.location.href.split('/').pop()?.split('-').pop();
			console.log(`EVENT DETAILS`, id);
			if (id) {
				getData(id);
			}
		}
	}, []);

	const {
		StartDate,
		StartTime,
		EndDate,
		EndTime,
		Description,
		ForecastAttendance,
		Note1,
		WebAddress,
	}: Event = eventData;

	const WebCost = eventNotesData.filter((e) => {
		return e.Title == 'Web Cost';
	});
	const WebDescription = eventNotesData.filter((e) => {
		return e.Title == 'Web Description';
	});

	function createMarkup() {
		return { __html: WebDescription[0]?.Text };
	}

	return (
		<div className="jss-inner-container">
			<div className="event-details">
				<div className="event-img banner">
					<img
						src={Note1 ? Note1 : ('https://via.placeholder.com/1050x240' as string)}
						alt={Note1 ? Note1 : 'Event Image'}
						className="hero-img"
					/>
				</div>
				<div className="content">
					<div className="row">
						<div className="col-12 col-md-3">
							<div className="event-info">
								{(StartDate || StartTime) && (
									<div>
										<h6>
											{StartTime && 'TIME'} {StartTime && StartDate && '&'} {StartDate && 'DATE'}
										</h6>
										<p>
											{new Date(StartDate).toLocaleDateString('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
											})}
										</p>
										<p>
											{new Date(StartTime).toLocaleTimeString('en-US', {
												hour: '2-digit',
												minute: '2-digit',
											})}{' '}
											-{' '}
											{new Date(EndTime).toLocaleTimeString('en-US', {
												hour: '2-digit',
												minute: '2-digit',
											})}
										</p>
									</div>
								)}
								{spacesData && spacesData?.Note1 && (
									<div>
										<h6>LOCATION</h6>
										{spacesData?.Note1 && <p>{spacesData.Note1}</p>}
										{spacesData?.Note2 && <p>{spacesData.Note2}</p>}
									</div>
								)}
								{ForecastAttendance !== undefined && ForecastAttendance.toString().trim() !== '0' && (
									<div>
										<h6>CAPACITY</h6>
										<p>{ForecastAttendance}</p>
									</div>
								)}
								{WebCost !== undefined && WebCost[0] && (
									<div>
										<h6>COST</h6>
										<p>{WebCost[0]?.PlainText as string}</p>
									</div>
								)}
								{EndDate !== undefined && (
									<div>
										<h6>REGISTRATION CLOSES</h6>
										<p>
											{regConfigData &&
												new Date(regConfigData[0]?.RegistrationEnds as string).toLocaleDateString(
													'en-US',
													{
														year: 'numeric',
														month: 'long',
														day: 'numeric',
													}
												)}
										</p>
									</div>
								)}
								<div>
									<h6>CONTACT</h6>
									<p>
										For more information, email{' '}
										<a href="mailto:events@jumpsimulation.org" rel="noreferrer" target="_blank">
											events@jumpsimulation.org
										</a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div>{Description && <h1 className="event-title">{Description}</h1>}</div>
							{WebDescription && <div dangerouslySetInnerHTML={createMarkup()} />}
						</div>
						<div className="col-12 col-md-3">
							<div className="event-btns">
								{WebAddress && (
									<ActionButton label="Register Now" link={WebAddress} isLinkExternal="yes" />
								)}
								{spacesData && spacesData?.Location && (
									<ActionButton
										label="Get directions"
										link={spacesData && spacesData.Location}
										isLinkExternal="yes"
										icon="location"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventDetails;
