import React, { useState } from 'react';
import GetViewportSize from '../../../../../frontend/assets/js/utilities/GetViewportSize';

type RightRailProps = {
	parent: any;
	children: React.ReactElement;
};

const RightRail = ({ parent, children }: RightRailProps): JSX.Element => {
	const [open, setOpen] = useState(false);

	const toggleMobileFilters = () => {
		if (open) {
			const topOffset = parseInt(document.body.style.top);
			document.body.classList.remove('body-fixed');
			document.body.style.removeProperty('top');
			window.scroll(0, -topOffset);

			setOpen(false);
		} else {
			if (!(GetViewportSize() === 'lg' || GetViewportSize() === 'xl')) {
				const topOffset = `-${window.pageYOffset}px`;
				document.body.classList.add('body-fixed');
				document.body.style.top = topOffset;

				setOpen(true);
			}
		}
	};

	return (
		<div className="right-rail">
			<div className="right-rail-btn btn-open" onClick={toggleMobileFilters}>
				{parent.navlink.value.text}
				<svg
					className={`icon icon-chevron-right`}
					xmlns="http://www.w3.org/2000/svg"
					width="8.121"
					height="14.122"
					viewBox="0 0 8.121 14.122"
				>
					<g id="Icon_Arrow_Right" data-name="Icon Arrow Right" transform="translate(1.061 1.061)">
						<path
							id="Path_747"
							data-name="Path 747"
							d="M4918.513-354.946l6,6-6,6"
							transform="translate(-4918.513 354.946)"
							fill="none"
						/>
					</g>
				</svg>
			</div>
			<div className={`right-rail-container ${open ? 'open' : ''}`}>
				<div className="right-rail-btn btn-close" onClick={toggleMobileFilters}>
					<svg
						className={`icon icon-chevron-right`}
						xmlns="http://www.w3.org/2000/svg"
						width="8.121"
						height="14.122"
						viewBox="0 0 8.121 14.122"
					>
						<g
							id="Icon_Arrow_Right"
							data-name="Icon Arrow Right"
							transform="translate(1.061 1.061)"
						>
							<path
								id="Path_747"
								data-name="Path 747"
								d="M4918.513-354.946l6,6-6,6"
								transform="translate(-4918.513 354.946)"
								fill="none"
							/>
						</g>
					</svg>
					Back to Page
				</div>
				{children}
			</div>
		</div>
	);
};

export default RightRail;
