import React from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Tooltip from 'assets/shared/ToolTip';

type ToolTipProps = {
	fields: {
		body: Field<string>;
		heading: Field<string>;
	};
};

const InfoTip = (props: ToolTipProps): JSX.Element => {
	const { fields } = props;

	const DescElement = (): JSX.Element => (
		<RichText className="tooltip-description" tag="p" field={fields?.body || ''} />
	);

	const TitleElement = (): JSX.Element => (
		<RichText tag="span" className="tooltip-heading" field={fields?.heading || ''} />
	);

	return (
		<Tooltip
			isShowDescElement
			{...{
				DescElement,
				TitleElement,
				arrowClass: 'arrow-top-left',
			}}
		/>
	);
};

export default InfoTip;
