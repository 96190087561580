import { Text, Field, LinkField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type JoinUsPromoProps = StyleguideComponentProps & {
	fields: {
		heading: Field<string>;
		content: Field<string>;
		backgroundimage: ImageField;
		link: LinkField;
	};
};

const JoinUsPromo = (props: JoinUsPromoProps): JSX.Element => (
	<div className="bottom-cta">
		<img
			src={props.fields.backgroundimage.value?.src}
			alt={
				(props.fields.backgroundimage.value?.alt as string) != ''
					? (props.fields.backgroundimage.value?.alt as string)
					: 'Image'
			}
		/>
		<div className="jss-inner-container content">
			<Text tag="h2" field={props.fields.heading} />
			<Text tag="p" field={props.fields.content} />
			{props.fields.link && (
				<a href={props.fields.link.value.href}>
					<div className="icon-wrapper">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="21.121"
							height="22.5"
							viewBox="0 0 21.121 20.5"
							className="icon icon-arrow-up"
						>
							<g
								id="Icon_Arrow_2_Right"
								data-name="Icon Arrow 2 Right"
								transform="translate(1.061 19.75) rotate(-90)"
							>
								<path
									id="Path_497"
									data-name="Path 497"
									d="M4613.654,590.652l9.5,9.5,9.5-9.5"
									transform="translate(-581.152 4632.654) rotate(-90)"
								/>
								<path
									id="Path_498"
									data-name="Path 498"
									d="M4625.855,576.15v19"
									transform="translate(-576.15 4635.355) rotate(-90)"
								/>
							</g>
						</svg>
					</div>
				</a>
			)}
		</div>
	</div>
);

export default JoinUsPromo;
