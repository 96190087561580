import React from 'react';

type ImageProps = {
	imageUrl: string;
	aditionalClass?: string;
	alt: string;
};

const Image = ({ imageUrl, aditionalClass, alt }: ImageProps): JSX.Element => (
	<picture className={aditionalClass ? aditionalClass : ''}>
		<source className="jss-image" media="(max-width: 480px)" srcSet={imageUrl + `?t=w480`} />
		<source className="jss-image" media="(max-width: 800px)" srcSet={imageUrl + `?t=w800`} />
		<source className="jss-image" media="(max-width: 2000px)" srcSet={imageUrl + `?t=w3000`} />
		<img className="jss-image" src={imageUrl + `?t=w800`} alt={alt} />
	</picture>
);

export default Image;
