import { Text, Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type ContentBlockProps = {
	title: Field<string>;
	description?: Field<string>;
};

const ContentBlock = ({ title, description }: ContentBlockProps): JSX.Element => (
	<div className="overhauled">
		<div className="content-block">
			{title ? <Text tag="h2" className="h2" field={title} /> : null}
			{description ? <RichText tag="div" className="osf-body" field={description} /> : null}
		</div>
	</div>
);

export default ContentBlock;
