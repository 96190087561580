import Modal from '../../assets/shared/Modal';
import React from 'react';
import { Field, LinkField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Link, RichText, Text, Image } from '@sitecore-jss/sitecore-jss-react';

type CriticalMessageProps = {
	fields: {
		MessageDesc: Field<string>;
		MessageSvg: ImageField;
		PrimaryLink: LinkField;
		MessageTitle: Field<string>;
		SecondaryButton: LinkField;
	};
};

const CriticalMessage = (props: CriticalMessageProps): JSX.Element => {
	const { fields } = props;
	return (
		<>
			<Modal
				customClass="innovation-modal in-page-message"
				hideDefaultCloseBtn
				modalOnly={false}
				openOnLoad={false}
				openBtnLabel="Open Critical Message"
				portalId="message-modal-root"
				shouldCloseOnOverlayClick
			>
				<div className="icon-container">
					<span className="icon icon-critical">
						<Image field={fields?.MessageSvg} />
					</span>
				</div>
				<Text className="in-page-message-heading" tag="h3" field={fields?.MessageTitle || ''} />
				<RichText className="in-page-message-description" field={fields?.MessageDesc} />
				<div className="action-btns-container">
					<Link
						className="action-btn"
						rel="noreferrer"
						field={fields?.PrimaryLink}
						onClick={(e: { stopPropagation: () => void }) => e?.stopPropagation()}
					/>
					<Link
						className="action-btn action-btn-secondary custom-modal-close-btn"
						rel="noreferrer"
						field={fields?.SecondaryButton}
						onClick={(e: { stopPropagation: () => void }) => e?.stopPropagation()}
					/>
				</div>
			</Modal>
			<div id="message-modal-root"></div>
		</>
	);
};

export default CriticalMessage;
