export default url => {
	return new Promise(function(resolve, reject) {
		let ready = false,
			tag = document.getElementsByTagName('script')[0],
			script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = url
		script.async = true
		script.onload = script.onreadystatechange = function() {
			if (
				!ready &&
				(!this.readyState ||
					this.readyState === 'complete' ||
					this.readyState === 'loaded')
			) {
				ready = true
				resolve(this)
			}
		}

		script.onerror = script.onabort = reject

		tag.parentNode.insertBefore(script, tag)
	})
}
