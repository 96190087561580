import React from 'react';
import { StyleguideComponentProps } from 'lib/component-props';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

// eslint-disable-next-line @typescript-eslint/ban-types
type GICLayoutProps = StyleguideComponentProps & {};

const GICLayout = (props: GICLayoutProps): JSX.Element => {
	return (
		<div>
			<div className="scrollable-content">
				<div className="content-container">
					<div className="content">
						<div className="col-12">
							<Placeholder name="gic-top-row" rendering={props.rendering} />
						</div>
						<div className="col-12 col-md-6">
							<Placeholder name="gic-middle-row-left-col" rendering={props.rendering} />
						</div>
						<div className="col-12 col-md-6">
							<Placeholder name="gic-middle-row-right-col" rendering={props.rendering} />
						</div>
						<div className="col-12">
							<Placeholder name="gic-middle-row" rendering={props.rendering} />
						</div>
						<div className="col-12">
							<Placeholder name="gic-bottom-row" rendering={props.rendering} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GICLayout;
