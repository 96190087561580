import React, { useEffect, useState, useRef } from 'react';
import { Hint } from 'react-autocomplete-hint';
import GetViewportSize from '../../assets/utils/getViewportSize';
import Icons from 'components/atoms/Icons';
import {
	LOCATIONS_SEARCH,
	PROVIDERS_SEARCH,
	SEARCH_QUERY,
} from '../../apollo/queries/search-result';
import { useQuery } from '@apollo/client';

/* eslint-disable */

const R2SearchBar = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState('');
	const [selectedId, setSelectedId] = useState(0);
	const searchResultsRef = useRef(null);

	const { data: result, loading } = useQuery(SEARCH_QUERY, {
		variables: {
			text: value,
		},
		context: { clientName: 'searchByISC' },
	});

	const { data: locationData, loading: locationLoading } = useQuery(LOCATIONS_SEARCH, {
		variables: {
			text: value,
		},
		context: { clientName: 'searchByItemId' },
	});

	const { data: providerData, loading: providerLoading } = useQuery(PROVIDERS_SEARCH, {
		variables: {
			text: value,
		},
		context: { clientName: 'searchByItemId' },
	});

	const focusable = (obj: HTMLElement, excludeNonFocusableAttr: any) => {
		const nodeList = obj.querySelectorAll(
			'a[href], area[href], input, select, textarea, button, iframe, object, embed, *[tabindex], *[contenteditable]'
		);

		return Array.from(nodeList).filter((elem: any) => {
			return excludeNonFocusableAttr
				? !elem.matches('[tabindex="-1"]') && !elem.matches('[disabled]')
				: elem;
		});
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const focusableItems = searchResultsRef.current
			? focusable(searchResultsRef?.current, '').filter(
					(focusableItem: any) => focusableItem.offsetWidth > 0 && focusableItem.offsetHeight > 0
			  )
			: null;

		setValue(e.target.value);
		setSelectedId(0);

		if (focusableItems) {
			focusableItems.map((focusableItem: any) => {
				focusableItem.classList.remove('active');
				focusableItem.setAttribute('aria-selected', 'false');
			});
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		window.location.href = `/r2/search?query=${value}`;
	};

	const onKeyDown = (e: React.KeyboardEvent) => {
		const focusableItems = searchResultsRef.current
			? focusable(searchResultsRef.current, '').filter(
					(focusableItem: any) => focusableItem.offsetWidth > 0 && focusableItem.offsetHeight > 0
			  )
			: null;

		if (focusableItems) {
			/* Up arrow */
			if (e.keyCode === 38 || e.charCode === 38) {
				e.preventDefault();
				const newSelectedId = selectedId > 0 ? selectedId - 1 : focusableItems.length;
				focusableItems.map((focusableItem: any) => {
					focusableItem.classList.remove('active');
					focusableItem.setAttribute('aria-selected', 'false');
				});
				if (focusableItems[newSelectedId - 1]) {
					focusableItems[newSelectedId - 1].classList.add('active');
					focusableItems[newSelectedId - 1].setAttribute('aria-selected', 'true');
				}
				setSelectedId(newSelectedId);
			}
			/* Down arrow */
			if (e.keyCode === 40 || e.charCode === 40) {
				e.preventDefault();
				const newSelectedId = selectedId < focusableItems.length ? selectedId + 1 : 0;
				focusableItems.map((focusableItem: any) => {
					focusableItem.classList.remove('active');
					focusableItem.setAttribute('aria-selected', 'false');
				});
				if (focusableItems[newSelectedId - 1]) {
					focusableItems[newSelectedId - 1].classList.add('active');
					focusableItems[newSelectedId - 1].setAttribute('aria-selected', 'true');
				}
				setSelectedId(newSelectedId);
			}
		}
	};

	const onBlur = () => {
		const focusableItems = searchResultsRef.current
			? focusable(searchResultsRef.current, '').filter(
					(focusableItem: any) => focusableItem.offsetWidth > 0 && focusableItem.offsetHeight > 0
			  )
			: null;

		setSelectedId(0);

		if (focusableItems) {
			focusableItems?.map((focusableItem: any) => {
				focusableItem.classList.remove('active');
				focusableItem.setAttribute('aria-selected', 'false');
			});
		}
	};

	const resize = () => {
		const viewportSize = GetViewportSize();

		if (viewportSize === 'xs' || viewportSize === 'sm') {
			setOpen(false);
		}
	};

	const modifyValue = (value: string) => value.toLowerCase();

	useEffect(() => {
		window.addEventListener('resize', resize);
	}, []);

	return (
		<>
			<button
				aria-label="Open Search menu"
				aria-expanded={open}
				className="filter-btn search-btn"
				onClick={() => {
					setOpen(true);
				}}
			>
				<span className="search-input">Search</span>
				<span className="icon icon-desktop icon-search">
					<Icons id="search" />
				</span>
			</button>
			{open ? (
				<div className="r2-search-bar">
					<div className="r2-search-overlay"></div>
					<div className="r2-search-bar-container">
						<div className="r2-search-bar-inner">
							<form className="search-form" onSubmit={handleSubmit}>
								<div className="search-input-container">
									<Hint
										allowEnterFill
										allowTabFill
										valueModifier={modifyValue}
										options={String(result?.typeaHead?.suggestedWord || '').split(' ')}
									>
										<input
											className="search-input"
											type="text"
											autoFocus
											value={value}
											onChange={handleInputChange}
											onKeyDown={onKeyDown}
											onBlur={onBlur}
											name="query"
											placeholder={'Search'}
										/>
									</Hint>
									<span className="icon icon-desktop icon-search">
										<Icons id="search" />
									</span>
								</div>

								<button type="submit" className="submit-btn">
									Submit
								</button>
							</form>
							<button
								aria-label="Close Search menu"
								className="close-search-btn"
								onClick={() => {
									document.body.classList.remove('body-fixed');
									setOpen(false);
								}}
							>
								<span className="icon icon-desktop icon-close">
									<Icons id="close" />
								</span>
							</button>
							{value?.length > 2 && (
								<div className="search-bar-results" ref={searchResultsRef}>
									<div className="search-results-inner">
										<div className="mobile-provider-location-links">
											<a
												className="providers-link"
												href={`https://www2.osfhealthcare.org/Providers?query=${value}`}
											>
												Providers ({providerData?.providers?.totalSearchResults || 0})
											</a>
											<a
												className="locations-link"
												href={`https://www2.osfhealthcare.org/Locations?query=${value}`}
											>
												Locations ({locationData?.locations?.totalSearchResults || 0})
											</a>
										</div>
										<div className="general-results">
											{!loading ? (
												<>
													{result?.typeaHead?.resultSets?.length ? (
														<>
															{result?.typeaHead?.resultSets?.map((resultSet: any, i: number) => (
																<div className="search-category-set" key={i}>
																	<h4 className="search-category">{resultSet.resultCategory}</h4>
																	<>
																		{resultSet?.results?.length ? (
																			<>
																				{resultSet?.results?.map((result: any, idx: number) => {
																					const tempEl = document.createElement('div');
																					tempEl.innerHTML = result?.resultUrl?.url;
																					// Access the url attribute of the element
																					const href = tempEl
																						?.querySelector('link')
																						?.getAttribute('url');
																					const target = tempEl
																						?.querySelector('link')
																						?.getAttribute('target');
																					return (
																						<a
																							key={idx}
																							className={`search-bar-result`}
																							{...{
																								href: href || result?.resultUrl?.url,
																								target: target || '',
																							}}
																						>
																							{result.resultTitle}
																						</a>
																					);
																				})}
																			</>
																		) : (
																			<>No results.</>
																		)}
																	</>
																</div>
															))}
														</>
													) : (
														<span className="empty-search-result">No results.</span>
													)}
												</>
											) : (
												<div className="search-category-set">
													<h4 className="search-category">Search Term Category</h4>
													<>Loading...</>
												</div>
											)}
										</div>
										<div className="provider-location-results">
											<div className="search-category-set">
												<h4 className="search-category">Providers:</h4>
												{providerLoading ? (
													<div className="search-category-set">
														<>Loading...</>
													</div>
												) : (
													<div className="featured-list">
														{!providerData?.providers?.results?.length ? (
															<>No results.</>
														) : (
															<div className="list-inner">
																{providerData?.providers?.results
																	?.slice(0, 2)
																	?.map(({ provider }: any) => (
																		<a
																			href={`https://www2.osfhealthcare.org${provider.url}`}
																			key={provider.id}
																			className="featured-item"
																		>
																			<div className="item-image">
																				<img
																					src={
																						provider.imageUrl ||
																						'https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-female-provider-transparent'
																					}
																					alt={provider.id}
																				/>
																			</div>
																			<div
																				className="content-container"
																				itemScope
																				itemType="http://schema.org/Physician"
																			>
																				<h4 className="item-name" itemProp="name">
																					{provider.fullName}
																				</h4>
																				<h5
																					className="item-subtitle"
																					itemProp="medicalSpecialty"
																					itemScope={false}
																					itemType="https://schema.org/medicalSpecialty"
																				>
																					{provider.specialty?.toLocaleString()}
																				</h5>
																			</div>
																		</a>
																	))}
																<a
																	className="view-all-link"
																	href={`https://www2.osfhealthcare.org/Providers?query=${value}`}
																>
																	All Matching Providers (
																	{providerData?.providers?.totalSearchResults || 0})
																</a>
															</div>
														)}
													</div>
												)}
											</div>
											<div className="search-category-set">
												<h4 className="search-category">Locations:</h4>
												{locationLoading ? (
													<div className="search-category-set">
														<>Loading...</>
													</div>
												) : (
													<div className="featured-list">
														{!locationData?.locations?.results?.length ? (
															<>No results.</>
														) : (
															<div className="list-inner">
																{locationData?.locations?.results
																	?.slice(0, 2)
																	?.map(({ location }: any) => (
																		<a
																			href={`https://www2.osfhealthcare.org${location.url}`}
																			key={location?.id}
																			className="featured-item"
																		>
																			<div className="item-image">
																				<img
																					src={
																						location.imageUrl ||
																						'https://osf-p-001.sitecorecontenthub.cloud/api/public/content/placeholder-location-transparent'
																					}
																					alt={location.id}
																				/>
																			</div>
																			<div className="content-container">
																				<div
																					className="address"
																					itemProp="hospitalAffiliation"
																					itemScope={false}
																					itemType="http://schema.org/Hospital"
																				>
																					<h4 className="item-name" itemProp="name">
																						{location.name}
																					</h4>
																					<h5
																						className="item-subtitle"
																						itemProp="address"
																						itemScope
																						itemType="http://schema.org/PostalAddress"
																					>
																						{location.street1 && <span>{location.street1}, </span>}
																						{location.street2 && <span>{location.street2}, </span>}
																						{location.city && <span>{location.city}</span>}
																						{location.zip && <span>{location.zip}</span>}
																					</h5>
																				</div>
																			</div>
																		</a>
																	))}
																<a
																	className="view-all-link"
																	href={`https://www2.osfhealthcare.org/Locations?query=${value}`}
																>
																	All Matching Locations (
																	{locationData?.locations?.totalSearchResults || 0})
																</a>
															</div>
														)}
													</div>
												)}
											</div>
										</div>
									</div>
									<a className="search-bar-view-all" href={`/r2/search?query=${value}`}>
										All Results (
										{result?.typeaHead?.viewAllCount +
											providerData?.providers?.totalSearchResults +
											locationData?.locations?.totalSearchResults}
										)
									</a>
								</div>
							)}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default R2SearchBar;
