import { Field, Link, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type FooterResourcesProps = StyleguideComponentProps & {
	fields: {
		title: Field<string>;
		copyright: Field<string>;
		termsAndConditionsLink: LinkField;
		compliance: LinkField;
		privacyPolicy: LinkField;
		link: LinkField;
		children: Array<ItemValue>;
	};
};

const FooterResources = (props: FooterResourcesProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<>
			<div className="link-list-block">
				{props.fields.title && (
					<Text className="link-list-heading" tag="h3" field={props.fields.title} />
				)}
				{props.fields.children.map((item) => (
					<Link
						className="footer-link"
						aria-label="OSF Link"
						data-target=""
						key={item.id}
						field={item.fields.link}
					/>
				))}
			</div>
			<div className="legal">
				{props.fields.copyright && (
					<Text className="copyright-text" field={props.fields.copyright} />
				)}
				<ul className="link-list">
					<li className="link-list-item">
						{props.fields.termsAndConditionsLink && (
							<Link
								className="footer-link"
								aria-label="Terms and Conditions"
								data-target=""
								field={props.fields.termsAndConditionsLink}
							/>
						)}
					</li>
					<li className="link-list-item">
						{props.fields.compliance && (
							<Link
								className="footer-link"
								aria-label="Terms and Conditions"
								data-target=""
								field={props.fields.compliance}
							/>
						)}
					</li>
					<li className="link-list-item">
						{props.fields.privacyPolicy && (
							<Link
								className="footer-link"
								aria-label="Privacy Policy"
								data-target=""
								field={props.fields.privacyPolicy}
							/>
						)}
					</li>
				</ul>
			</div>
		</>
	);
};

export default FooterResources;
