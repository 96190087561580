import Icons from 'components/atoms/Icons';
type ShapesProps = {
	variant: string;
};

type ShapeGroups = {
	[key: string]: string[];
};

const Shapes = (props: ShapesProps) => {
	const shapeGroups: ShapeGroups = {
		shapes1: ['square', 'triangle', 'circle', 'triangle', 'circle'],
		shapes2: ['circle', 'triangle', 'circle', 'square'],
		shapes3: ['circle', 'square', 'triangle', 'circle'],
		shapes4: ['square', 'circle', 'circle', 'triangle', 'square'],
	};

	return (
		<div className={`shapes-container`}>
			<div className={props.variant}>
				{shapeGroups[props.variant].map((shape) => (
					<Icons id={shape} className={shape} />
				))}
			</div>
		</div>
	);
};

export default Shapes;
