import React from 'react';
import PropTypes from 'prop-types';
import { StyleguideComponentProps } from 'lib/component-props';
import {
	Field,
	Image,
	ImageField,
	LinkField,
	RichText,
	Text,
} from '@sitecore-jss/sitecore-jss-nextjs';

type PictureContentCardProps = StyleguideComponentProps & {
	fields: {
		title?: Field<string>;
		description?: Field<string>;
		link?: LinkField;
		image?: ImageField;
		footerText?: Field<string>;
	};
};

const PictureContentCard = (props: PictureContentCardProps): JSX.Element => {
	return (
		<a className="picture-content-card track-link-click" href={props.fields.link?.value.href}>
			<div className="picture-content-card-img">
				<Image
					field={props.fields.image}
					alt={
						(props.fields.image?.value?.alt as string) != ''
							? (props.fields.image?.value?.alt as string)
							: 'OSF Innovation'
					}
				/>
			</div>
			<div className="picture-content-card-content">
				<div className="card-body">
					<Text tag="h2" className="card-title" field={props.fields.title} />
					<RichText tag="div" className="card-description" field={props.fields.description} />
				</div>
				<div className="card-footer">
					<Text tag="span" className="link-text" field={props.fields.footerText} />

					<svg
						className="icon icon-play"
						id="Icon_Play"
						data-name="Icon Play"
						xmlns="http://www.w3.org/2000/svg"
						width="17"
						height="20"
						viewBox="0 0 17 20"
					>
						<g
							id="Path_965"
							data-name="Path 965"
							transform="translate(17) rotate(90)"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path d="M10,0,20,17H0Z" stroke="none" />
							<path
								d="M 10 2.958459854125977 L 2.622623443603516 15.5 L 17.37737655639648 15.5 L 10 2.958459854125977 M 10 0 L 20 17 L 0 17 L 10 0 Z"
								stroke="none"
								fill="#000"
							/>
						</g>
					</svg>
				</div>
			</div>
		</a>
	);
};

PictureContentCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	url: PropTypes.string,
	imgSrc: PropTypes.string,
	imgAlt: PropTypes.string,
	footerText: PropTypes.string,
};

export default PictureContentCard;
