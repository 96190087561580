import React, { useEffect, useState } from 'react';
import { Field, Text, LinkField, Link, ImageField, Image } from '@sitecore-jss/sitecore-jss-nextjs';
import moment from 'moment-timezone';

type QuickLinkItems = {
	id: string;
	isHide: boolean;
	fields: {
		Link: LinkField;
		svg: {
			value: string;
		};
		Image: ImageField;
	};
};

type CareActionsProps = {
	fields: {
		Title: Field<string>;
		QuickLinks: Array<QuickLinkItems>;
	};
};

const CareActions = (props: CareActionsProps): JSX.Element => {
	const { fields } = props;
	const [isHide, setIsHide] = useState(false);

	const getCSTtime = () => {
		const date = new Date();
		const CSTtime = moment
			.utc(date, 'MM-DD-YYYY HH:mm')
			.tz('America/Chicago')
			.format('MM-DD-YYYY hh:mm A');

		const hours = new Date(CSTtime).getHours();
		const minutes = new Date(CSTtime).getMinutes();
		setIsHide(hours >= 7 && hours <= 22);
		return { CSTtime, hours, minutes };
	};

	useEffect(() => {
		getCSTtime();
	}, []);

	const handleGetCareTabs = (tabsData: Array<QuickLinkItems>) => {
		if (!tabsData) return [];
		const handleFilterData = tabsData?.filter(({ id, ...data }) => {
			if (id === '22dfbf96-127c-4640-b3e2-d64d5ecd6580') {
				return isHide ? { ...data } : null;
			} else return { ...data };
		});
		return handleFilterData;
	};

	return (
		<div className="care-actions">
			<Text className="care-action-heading" tag="h3" field={fields?.Title || ''} />
			{handleGetCareTabs(fields?.QuickLinks)?.map(({ fields }, id) => {
				return (
					<div key={id} className="care-action">
						{fields?.Image && (
							<span className="icon">
								<Image field={fields?.Image} alt="OSF Innovation" />
							</span>
						)}
						<Link
							className="care-action-link"
							rel="noreferrer"
							field={fields?.Link}
							onClick={(e) => e.stopPropagation()}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default CareActions;
