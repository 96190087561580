import React from 'react';
import moment from 'moment';
import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import EventListItem from 'components/molecules/EventListItem';

type ButtonItem = {
	id: string;
	fields: {
		Link: LinkField;
		description: Field<string>;
		heading: Field<string>;
		svg: {
			value: string;
		};
	};
};

type EventsItem = {
	id: string;
	fields: {
		ActionButtonList: Array<ButtonItem>;
		Title: Field<string>;
		breadcrumbtitle: Field<string>;
		eventEndDate: Field<string>;
		eventStartDate: Field<string>;
		Body: Field<string>;
		Link: LinkField;
		image: ImageField;
	};
};

type EventListingProps = {
	fields: {
		Events: Array<EventsItem>;
	};
};

const EventListing = (props: EventListingProps): JSX.Element => {
	const { fields } = props;
	console.log('fields', fields?.Events);
	return (
		<div>
			{fields?.Events?.map(({ id, fields: { eventStartDate, eventEndDate, ...fields } }) => {
				const eventTime = `${moment(eventStartDate?.value).format('h:mm  A')} - ${moment(
					eventEndDate?.value
				).format('h:mm  A')} CST.`;
				return (
					<EventListItem
						key={id}
						{...{
							image: fields?.image,
							title: fields?.Title,
							time: { value: eventTime },
							description: fields?.Body,
							actionButton: fields?.ActionButtonList?.map(({ id, fields }) => {
								return {
									id,
									label: fields?.heading,
									paragraph: fields?.description,
									link: fields?.Link,
									icon: fields?.svg,
								};
							}),
						}}
					/>
				);
			})}
		</div>
	);
};

export default EventListing;
