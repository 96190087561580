// pages/eventlist.js
import Axios from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import ActionButton from './ActionButton';
import { StyleguideComponentProps } from 'lib/component-props';
import ListFilters from './ListFilters';
import Pagination from './Pagination';

type EventsListProps = StyleguideComponentProps & {
	fields: {
		header: Field<string>;
		resultstext: Field<string>;
		itemsperpage: Field<string>;
		noofpages: Field<string>;
		filtersheader: Field<string>;
		eventdetailspage: LinkField;
	};
};

export type Event = {
	EventID: number;
	StartTime: string;
	StartDate: string;
	EndTime: string;
	EndDate: string;
	Description: string;
	Type: string;
	Contact: string;
	ActualCost: string;
	Note1: string;
	Note2: string;
	WebAddress: string;
	ForecastAttendance: string;
};

export type EventType = {
	OrganizationCode: string;
	Code: string;
	Description: string;
	Scope: string;
	BackgroundColor: number;
	TextColor: number;
	Retire: string;
	EnteredOn: string;
	EnteredByCode: string;
};

let searchFieldText = '';

const EventsList = (props: EventsListProps): JSX.Element => {
	const [eventsList, setEventsList] = useState([]);
	const [filteredEventsList, setFilteredEventsList] = useState([]);
	const [availableEventTypes, setAvailableEventTypes] = useState<Array<EventType>>();
	const [selectedEventType, setSelectedEventType] = useState<string>('All');
	const [index, setIndex] = useState(0);

	useEffect(() => {
		getData();
	}, []);

	const splitResultsIntoPages = (list: Array<Event>, chunkSize: number) => {
		if (list.length <= chunkSize) {
			return [list];
		}

		const newARR: Array<Array<Event>> = [];
		for (let i = 0; i < list.length; i += chunkSize) {
			newARR.push(list.slice(i, i + chunkSize));
		}
		return newARR;
	};

	const handleSearchEvent = (searchField: ChangeEvent<HTMLInputElement>) => {
		if (searchField.target.value === '') {
			setFilteredEventsList(eventsList);
		}
		setFilteredEventsList(
			eventsList.filter((el: Event) =>
				el.Description.toLowerCase().includes(searchField.target.value.toLowerCase())
			)
		);
		searchFieldText = searchField.target.value.toLowerCase();
	};

	const getData = async () => {
		const token = await Axios.get(process.env.UNGERBOECK_API_URL + '/sdk_initialize', {
			headers: {
				Authorization: 'Basic ' + process.env.UNGERBOECK_API_TOKEN,
			},
		});

		const dt = new Date();
		const month = dt.getMonth() + 1;
		const datenum = dt.getDate();

		const eventFilterDate =
			"'" +
			dt.getFullYear() +
			'-' +
			((month < 10 ? '0' : '') + month) +
			'-' +
			((datenum < 10 ? '0' : '') + datenum) +
			"'";

		console.log(eventFilterDate);

		const events_api =
			process.env.UNGERBOECK_API_URL +
			"/Events/10?search=Public eq 'Y' and EndDate ge DateTime" +
			eventFilterDate +
			'$orderby=StartDate';

		const res = await Axios.get(events_api, {
			headers: {
				Token: token.data,
			},
		});
		setEventsList(res.data);
		setFilteredEventsList(res.data);

		const reres = await Axios.get(process.env.UNGERBOECK_API_URL + '/EventJobTypes/10?search=all', {
			headers: {
				Token: token.data,
			},
		});

		const eventsTypeArr = res.data.map((el: Event) => el.Type);
		const eventTypesFromEventsList: Array<string> = Array.from(new Set(eventsTypeArr)); // removing duplicated values with new Set(arr)
		if (eventTypesFromEventsList) {
			const filteredEventTypes = eventTypesFromEventsList.map((etel: string) => {
				return reres.data.filter((et: EventType) => et.Code === etel);
			});

			setAvailableEventTypes(filteredEventTypes.flat());
		}
	};

	const filterEventsListSubset: Array<Event> = filteredEventsList.filter((ev: Event) => {
		if (selectedEventType === 'All') {
			return ev;
		} else {
			return selectedEventType === ev.Type;
		}
	});

	let splitListValues: Array<Array<Event>> = [[], []];
	if (filterEventsListSubset && filterEventsListSubset.length > 0) {
		splitListValues = splitResultsIntoPages(
			filterEventsListSubset,
			props.fields.itemsperpage.value as unknown as number
		);
	}

	if (
		props.fields == undefined ||
		!props.fields.header ||
		!props.fields.filtersheader ||
		!props.fields.resultstext
	) {
		<div>Data source is not defined</div>;
	}

	return (
		<div className="events-list jss-inner-container">
			<h5 className="page-title">EVENTS</h5>
			<ListFilters
				eventTypes={availableEventTypes}
				setSelectedEventType={setSelectedEventType}
				selectedEventType={selectedEventType}
				handleSearchEvent={handleSearchEvent}
			/>
			{eventsList.length != 0 && filterEventsListSubset.length != 0 && (
				<div className="events-list-page">
					<h2 className="heading-1 section-title color-black">
						Showing{' "'}
						{availableEventTypes?.filter((e) => e.Code === selectedEventType)[0]?.Description
							? availableEventTypes?.filter((e) => e.Code === selectedEventType)[0]?.Description
							: 'All'}
						{'"'}
						{searchFieldText && ' and "' + searchFieldText + '"'}
					</h2>
					<div>
						<h3 className="heading-1 section-title color-black">
							{props.fields.resultstext?.value} {filterEventsListSubset.length}
						</h3>
					</div>
				</div>
			)}

			{eventsList.length === 0 && (
				<div className="osf-loading">
					<div className="loader loader--style2" title="1">
						<svg
							version="1.1"
							id="loader-1"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="40px"
							height="40px"
							viewBox="0 0 50 50"
						>
							<path
								fill="#333230"
								d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
							>
								<animateTransform
									attributeType="xml"
									attributeName="transform"
									type="rotate"
									from="0 25 25"
									to="360 25 25"
									dur="0.6s"
									repeatCount="indefinite"
								/>
							</path>
						</svg>
					</div>
					<p>Loading Events</p>
				</div>
			)}

			{filterEventsListSubset.length === 0 && eventsList.length > 0 && (
				<div className="event-details">
					<h4 className="heading-1">We can't find any matching events.</h4>
					<h5 className="heading-2">Here are a few tips that might help:</h5>
					<ul className="osf-no-results">
						<li>Check your spelling.</li>
						<li>
							Be less specific - try removing a single criteria or choosing a slightly different
							one.
						</li>
						<li>If specifying a type, choose from event type filters.</li>
						<li>
							Want to start over? <button className="clear-all-btn">Reset your filters.</button>
						</li>
					</ul>
				</div>
			)}

			<div>
				{splitListValues &&
					splitListValues[index]?.map((event: Event) => (
						<div key={event.EventID} className="event-listing">
							<div className="event-img">
								<img
									src={event.Note1 ? event.Note1 : ('https://via.placeholder.com/600' as string)}
									alt="Event Image"
								/>
							</div>
							<div className="event-content">
								<span className="event-date">
									{new Date(event.StartDate).toLocaleDateString('en-US', {
										year: 'numeric',
										month: 'long',
										day: 'numeric',
									})}
								</span>
								<h1>{event.Description}</h1>
								<p>{event.Note2 ? event.Note2 : ''}</p>

								<div className="event-details">
									<span className="event-time">
										{new Date(event.StartTime).toLocaleTimeString('en-US', {
											hour: '2-digit',
											minute: '2-digit',
										})}{' '}
										-{' '}
										{new Date(event.EndTime).toLocaleTimeString('en-US', {
											hour: '2-digit',
											minute: '2-digit',
										})}
									</span>
									<span className="event-type">
										{availableEventTypes?.filter((e) => e.Code === event.Type)[0]?.Description}
									</span>
								</div>
							</div>
							<div className="learn-or-register">
								{props.fields?.eventdetailspage?.value.href && (
									<ActionButton
										label="Learn More"
										link={`events/${event.Description.replace(/ /g, '-')}-${event.EventID}`}
									/>
								)}
								{event.WebAddress && (
									<ActionButton
										label="Register Now"
										capacity={event.ForecastAttendance}
										link={event.WebAddress}
										isLinkExternal="yes"
									/>
								)}
							</div>
						</div>
					))}

				{filterEventsListSubset.length > 0 && splitListValues && splitListValues.length > 1 && (
					<Pagination
						numberOfVisiblePages={props.fields.noofpages.value as unknown as number}
						itemsPerPage={props.fields.itemsperpage.value as unknown as number}
						numberOfItems={filterEventsListSubset.length}
						index={index}
						setIndex={setIndex}
					/>
				)}
			</div>
		</div>
	);
};

export default EventsList;
