import { Text, Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type JumpSimulationBannerTwoProps = StyleguideComponentProps & {
	fields: {
		content: Field<string>;
		image: ImageField;
	};
};

const JumpSimulationBannerTwo = (props: JumpSimulationBannerTwoProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}
	return (
		<div className="simulation-banner-two">
			<div className="centered-column">
				<div className="centered-column-inner">
					<div className="centered-column-content">
						<div className="banner-content">
							<div className="image-wrapper">
								{props.fields.image && (
									<img
										src={props.fields.image.value?.src}
										alt={
											(props.fields.image.value?.alt as string) != ''
												? (props.fields.image.value?.alt as string)
												: 'Image'
										}
									/>
								)}
							</div>
							<div className="content">
								{props.fields.content && <Text className="text" field={props.fields.content} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JumpSimulationBannerTwo;
