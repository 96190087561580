import { Text, RichText, Field, LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type HeroParagraphProps = StyleguideComponentProps & {
	fields: {
		sectionTitle: Field<string>;
		title: Field<string>;
		description?: Field<string>;
		link: LinkField;
	};
};

const HeroParagraph = (props: HeroParagraphProps): JSX.Element => {
	if (!props || !props.fields) {
		return <div></div>;
	}

	return (
		<div className="overhauled">
			<div className="jss-inner-container">
				<div className="outer-vertical-spacing hero-paragraph">
					{props.fields.sectionTitle && (
						<Text tag="h4" className="section-title" field={props.fields.sectionTitle} />
					)}
					{props.fields.title && (
						<Text tag="h1" className="hero-content-title" field={props.fields.title} />
					)}
					{props.fields.description && (
						<RichText className="hero-content-paragraph" field={props.fields.description} />
					)}
					{props.fields.link?.value?.href && (
						<Link className="hero-content-paragraph" field={props.fields.link} />
					)}
				</div>
			</div>
		</div>
	);
};

export default HeroParagraph;
