import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Icons from 'components/atoms/Icons';
import FormTextField from 'components/molecules/FormTextField';
import React, { FormEvent, useState } from 'react';

type BlogSubscribeProps = {
	fields: {
		SubscriptionTitle: Field<string>;
		EmailPlaceholderId: Field<string>;
		SubscribeSVG: {
			value: string;
		};
		ButtonText: {
			value: { text: string };
		};
		EmailPlaceholder: {
			value: string;
		};
	};
};

const BlogSubscribe = (props: BlogSubscribeProps): JSX.Element => {
	const { fields } = props;
	const [formData, setFormData] = useState({ email: '' });

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	};

	return (
		<div className="blog-subscribe">
			<form onSubmit={handleSubmit}>
				<div className="form-content">
					<Text className="blog-subscribe-heading" tag="h4" field={fields?.SubscriptionTitle} />
					<FormTextField
						{...{
							name: 'email',
							value: formData.email,
							handleChange,
							type: 'email',
							className: 'email-input',
							placeholder: fields?.EmailPlaceholder?.value || 'Label',
						}}
					/>
				</div>
				<button className="subscribe-btn" type="submit">
					{fields?.ButtonText?.value?.text}
					<span className="icon icon-arrow-up">
						<Icons isDefault id={fields?.SubscribeSVG?.value} />
					</span>
				</button>
			</form>
		</div>
	);
};

export default BlogSubscribe;
