import loadScript from "./loadScript"


class Video {
	constructor() {
		let el = document.querySelector('[data-module="Video"]'),
			objects = {
				videoThumbnail: el.querySelector('.video-thumbnail'),
				videoModalBackdrop: el.querySelector('.video-modal-backdrop'),
				videoIframe: el.querySelector('iframe'),
			},
			player

		this.initYTPlayer = () => {
			player = new window.YT.Player(objects.videoIframe, {
				events: {
					onReady: this.onPlayerReady,
					onStateChange: this.onPlayerStateChange,
				},
			})
		}

		this.onPlayerReady = e => {
			objects.videoThumbnail.addEventListener(
				'click',
				this.thumbnailClick
			)
		}

		this.onPlayerStateChange = e => {
			e.data === 0 && el.classList.remove('video-active')
		}

		this.thumbnailClick = () => {
			el.classList.add('video-active')
			player.playVideo()
		}

		this.firstRun = () => {
			/* keep */
			console.log('---> Video Module Init')

			loadScript('https://www.youtube.com/iframe_api').then(() => {
				window.YT.ready(this.initYTPlayer)
			})
		}
	}

	name() {
		return 'Video'
	}

	init() {
		this.firstRun()
	}
}
export default Video
