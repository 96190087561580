import React from 'react';
import ActionButton from '../r2/ActionButton';
import { Field, Image, ImageField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

type ActionButtonListProps = {
	id: string;
	fields: {
		heading: Field<string>;
		svg: {
			value: string;
		};
		Link: LinkField;
	};
};

type EventListProps = {
	id: string;
	fields: {
		image: ImageField;
		Title: Field<string>;
		Summary: Field<string>;
		Body: Field<string>;
		ActionButtonList: Array<ActionButtonListProps>;
	};
};

type EventListingVariationsProps = {
	fields: {
		Events: Array<EventListProps>;
	};
};

const EventListingVariations = (props: EventListingVariationsProps): JSX.Element => {
	const { fields } = props;
	return (
		<>
			{fields?.Events &&
				fields?.Events.length > 0 &&
				fields?.Events?.map(({ id, fields }) => (
					<div key={id} className="event-listing-variations">
						{fields?.image && (
							<div className="event-img">
								<Image alt="event_image" field={fields?.image || ''} />
							</div>
						)}
						<div className="event-content">
							<div className="event-details">
								<Text field={fields?.Title} tag="h1" />
							</div>
							<div className="event-notes">
								<Text field={fields?.Summary} tag="span" />
								<Text field={fields?.Body} tag="p" />
							</div>

							<div className="learn-or-register">
								{fields?.ActionButtonList &&
									fields?.ActionButtonList?.length > 0 &&
									fields?.ActionButtonList?.map(({ id, fields }) => (
										<ActionButton
											key={id}
											icon="arrow-go"
											label={fields?.heading?.value}
											link={fields?.Link?.value?.href || '#'}
										/>
									))}
							</div>
						</div>
					</div>
				))}
		</>
	);
};

export default EventListingVariations;
