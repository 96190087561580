import React from 'react';
import { Field, RichText, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';

type GeneralContentProps = StyleguideComponentProps & {
	fields: {
		sectionTitle: Field<string>;
		title: Field<string>;
		TitleH2: Field<boolean>;
		description: Field<string>;
		content: Field<string>;
		'background-image-fill': Field<boolean>;
		'background-image': ImageField;
		'background-full-page-width': Field<boolean>;
		'background-color': {
			id: string;
			url: string;
			name: string;
			displayName: string;
			fields: {
				Key: Field<string>;
				Phrase: Field<string>;
			};
		};
	};
};

function onlySpaces(str: string) {
	return str.trim().length === 0;
}

const GeneralContent = (props: GeneralContentProps): JSX.Element => {
	const {
		'background-image': backgroundImage = { value: { src: '' } },
		'background-color': backgroundColor = { fields: { Phrase: { value: '' } } },
		'background-image-fill': backgroundImageFill = { value: false },
	} = props.fields || {};

	const backgroundStyle: React.CSSProperties = {};

	if (backgroundImageFill?.value && backgroundImage?.value?.src) {
		backgroundStyle.backgroundImage = `url(${backgroundImage?.value?.src})`;
		backgroundStyle.backgroundSize = 'cover';
		backgroundStyle.backgroundPosition = 'center';
		backgroundStyle.padding = '2rem 0';
		backgroundStyle.margin = '2rem 0';
	} else if (backgroundColor && backgroundColor?.fields?.Phrase?.value) {
		backgroundStyle.backgroundColor = backgroundColor?.fields?.Phrase?.value;
		backgroundStyle.padding = '3rem 0';
		backgroundStyle.margin = '2rem 0';
	}

	return (
		<div className="overhauled">
			<div className="general-content outer-vertical-spacing">
				<div className="jss-inner-container">
					{props.fields?.sectionTitle && !onlySpaces(props.fields.sectionTitle?.value) && (
						<p className="section-title content-block">{props.fields?.sectionTitle?.value}</p>
					)}
					{props.fields?.title?.value &&
						(props.fields?.TitleH2?.value == true ? (
							<h2 className="title">{props.fields?.title?.value}</h2>
						) : (
							<h1 className="title">{props.fields?.title?.value}</h1>
						))}
					{props.fields?.description && !onlySpaces(props.fields.description?.value) && (
						<RichText className="osf-intro-copy" field={props.fields?.description} />
					)}
					{props.fields?.content && !onlySpaces(props.fields.content?.value) && (
						<RichText
							className="custom-richtext osf-body"
							tag="div"
							field={{ value: props.fields?.content?.value }}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default GeneralContent;
