import { Text, Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import { ItemValue } from 'components/types/ListItem';

type ImageCallOutProps = StyleguideComponentProps & {
	fields: {
		image: ImageField;
		heading: Field<string>;
		children: Array<ItemValue>;
	};
};

const ImageCallOut = (props: ImageCallOutProps): JSX.Element => {
	if (!props || !props.fields || !props.fields.children) {
		return <div></div>;
	}
	const { children } = props.fields;
	return (
		<div className="overhauled">
			<div className="jss-inner-container">
				<div className="image-call-out">
					{children &&
						children.map((item) => (
							<>
								<div className="content">
									<img
										src={item.fields.image?.value?.src as string}
										alt={
											(item.fields.image.value?.alt as string) != ''
												? (item.fields.image.value?.alt as string)
												: 'OSF'
										}
									/>
									{item.fields.heading && <Text tag="p" field={item.fields.heading} />}
								</div>
							</>
						))}
				</div>
			</div>
		</div>
	);
};

export default ImageCallOut;
