import React from 'react';
import Icons from './Icons';

type ActionLinkProps = {
	id: string;
	icon: string;
	label?: string;
	paragraph?: string;
	link?: string;
	additionalClasses?: string;
	iconClassName?: string;
	isLinkExternal?: boolean;
};

const ActionLink = (props: ActionLinkProps): JSX.Element => {
	const { link, icon, isLinkExternal, additionalClasses, label, paragraph, iconClassName } = props;
	return (
		<a
			href={link}
			target={isLinkExternal ? '_blank' : '_self'}
			rel="noreferrer"
			className={`action-btn ${additionalClasses} ${
				iconClassName === 'icon-calendar' ? 'schedule-link' : ''
			}`}
		>
			<div>
				<span className="action-text">{label}</span>
				{paragraph && <p>{paragraph}</p>}
			</div>
			<span className={`icon ${iconClassName} section-icon`} aria-hidden="true">
				<Icons isDefault id={icon} />
			</span>
		</a>
	);
};

export default ActionLink;
