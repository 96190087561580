import React from 'react';
import Icons from './Icons';
import { classNames } from 'assets/utils/helper';
import { Image, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

type ImageProps = {
	id: string;
	type?: string;
	fields: {
		image: ImageField;
	};
};

const ImageGrid = ({ id, type, fields }: ImageProps): JSX.Element => {
	return (
		<div
			key={id}
			className={classNames(
				'image-container',
				type === 'medium' ? 'image-container-medium' : 'image-container-large'
			)}
		>
			{!fields?.image?.value?.src ? (
				<Icons id="image_grid" />
			) : (
				<Image className="ecard-image" field={fields?.image || ''} />
			)}
		</div>
	);
};

export default ImageGrid;
