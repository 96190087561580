import React from 'react';

type FormInputProps = {
	className: string;
	label?: string;
	name: string;
	value: string;
	isRequired?: boolean;
	errMessage?: string;
	// eslint-disable-next-line
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormInput = ({
	label,
	name,
	value,
	handleChange,
	errMessage,
	className,
	isRequired,
	...props
}: FormInputProps): JSX.Element => {
	return (
		<>
			<label className="form-label" htmlFor="field-textarea">
				{isRequired ? `${label} *` : label || ''}
			</label>
			<div className={`form-input-wrapper ${className}`}>
				<div className="form-content">
					<input
						id="field-text"
						className="form-input"
						{...props}
						{...{ name, value }}
						onChange={handleChange}
					></input>
					{errMessage && <span>{errMessage}</span>}
				</div>
			</div>
		</>
	);
};

export default FormInput;
