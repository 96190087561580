import React, { ChangeEvent } from 'react';
import { EventType } from './EventsList';

type ListFiltersProps = {
	eventTypes: Array<EventType> | undefined;
	setSelectedEventType: React.Dispatch<React.SetStateAction<string>>;
	selectedEventType: string;
	// eslint-disable-next-line no-unused-vars
	handleSearchEvent: (searchField: ChangeEvent<HTMLInputElement>) => void;
};

const ListFilters = ({
	eventTypes,
	setSelectedEventType,
	selectedEventType,
	handleSearchEvent,
}: ListFiltersProps): JSX.Element => {
	if (!eventTypes || !selectedEventType) {
		return <></>;
	}
	const handleSelectedEventType = (event: ChangeEvent<HTMLSelectElement>): void => {
		setSelectedEventType(event.currentTarget.value);
	};

	return (
		<div className="list-filters">
			<div className="list-search">
				<span>Search</span>
				<input
					placeholder="Event Name"
					type="text"
					onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchEvent(e)}
				/>

				<svg
					id="XS_Icon_Search"
					data-name="XS Icon Search"
					xmlns="http://www.w3.org/2000/svg"
					width="19.537"
					height="19.537"
					viewBox="0 0 19.537 19.537"
					className="icon icon-search"
				>
					<defs>
						<clipPath id="clip-path">
							<rect
								id="Rectangle_2377"
								data-name="Rectangle 2377"
								width="19.537"
								height="19.537"
								transform="translate(0 0)"
								fill="none"
							/>
						</clipPath>
					</defs>
					<g id="Group_11726" data-name="Group 11726" transform="translate(0 0)">
						<circle
							id="Ellipse_316"
							data-name="Ellipse 316"
							cx="7.467"
							cy="7.467"
							r="7.467"
							transform="translate(0.75 0.75)"
							fill="none"
						/>
						<line
							id="Line_78"
							data-name="Line 78"
							x2="5.29"
							y2="5.29"
							transform="translate(13.497 13.497)"
							fill="none"
						/>
					</g>
				</svg>
			</div>
			<div className="list-select">
				<select value={selectedEventType} onChange={handleSelectedEventType}>
					<option value="All">All Event Types</option>
					{eventTypes.map((eventType) => (
						<option value={eventType.Code} key={eventType.Code}>
							{eventType.Description}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default ListFilters;
